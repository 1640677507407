.rotations-header__1idyy {
  position: relative;
  height: 57px;
  background-color: #e6eaef;
  overflow: visible;
  z-index: 1;
}
.rotations-buttons__10EXx {
  padding: 14px 10px 0;
}
.rotations-add_message_button__1GfBZ {
  margin-bottom: 10px;
}
.rotations-delete_message_button__1ACE9 {
  height: 32px;
  float: right;
}
