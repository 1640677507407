.modal-container__2f0h3 {
  position: relative;
  min-height: 200px;
}
.modal-title__1hdEH {
  height: 49px;
  font-weight: 500;
  font-size: 16px;
  color: #47475b;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 12px;
  border-bottom: solid 2px #dde5ef;
}
.modal-cross__24fpP {
  position: absolute;
  right: 13px;
  top: 16px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8' standalone='no'?%3E %3Csvg width='15px' height='14px' viewBox='0 0 15 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: Sketch 39.1 (31720) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3EClose*%3C/title%3E %3Cdesc%3ECreated with Sketch.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='Add-reminder' transform='translate(-1070.000000, -162.000000)' stroke='%2353A8FF'%3E %3Cg id='Close*' transform='translate(1070.000000, 162.000000)'%3E %3Cpath d='M14,0 L0.720987238,13.2790128' id='Path-11'%3E%3C/path%3E %3Cpath d='M14,0 L0.720987238,13.2790128' id='Path-11' transform='translate(7.360494, 6.639506) scale(-1, 1) translate(-7.360494, -6.639506) '%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E") no-repeat center;
}
.modal-content__1S9QL {
  padding: 12px 13px 0px 12px;
}
.modal-loader__2zJPR {
  padding: 50px;
  height: auto;
  -webkit-transform: none;
          transform: none;
}
