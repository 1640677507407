.assigner-container__2pUGg {
  width: 280px;
  z-index: 11;
  max-height: calc(100vh - 105px);
  background-color: #fff;
}
.assigner-data_container__1XTKc {
  height: 100%;
}
.assigner-search__1sH7s {
  border-width: 0 0 1px 0;
  border-color: #e7ebf0;
  border-style: solid;
}
.assigner-agents_list__3J-Ea {
  overflow: auto;
}
