.customer-info-container__1hkuK {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  border-left: 1px solid #e5e9ed;
  padding: 15px;
}
.customer-info-title__XiVG2 {
  font-weight: bold;
  font-size: 14px;
  color: #57697b;
  margin-bottom: 10px;
}
.customer-info-title_balance__s6wG- {
  color: #47475b;
  font-size: 18px;
}
.customer-info-title_inline__1m6v1 {
  font-weight: bold;
  font-size: 14px;
  color: #57697b;
  display: inline-block;
}
.customer-info-section__3_K12 {
  margin-bottom: 20px;
}
.customer-info-link__2Mu4c {
  color: #53a8ff;
  cursor: pointer;
  text-decoration: underline;
}
.customer-info-tabs__3FWM1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}
.customer-info-tabs__note__sdScy {
  position: absolute;
  border: 1px solid #53a8ff;
  padding: 5px;
  color: #53a8ff;
  background-color: #fff;
  width: 200px;
  top: 65px;
  left: 50px;
  line-height: 20px;
  display: none;
}
.customer-info-tabs__note_visible__333TF {
  display: block;
}
.customer-info-tab__1OotC {
  height: 38px;
  color: #53a8ff;
  line-height: 38px;
  text-align: center;
  border-radius: 2px;
  width: 50%;
  text-decoration: none;
  border: 1px solid #e5f2ff;
  cursor: pointer;
}
.customer-info-tab_active__IKQXk {
  color: #57697b;
  background-color: #e5f2ff;
}
.customer-info-tab_disabled__Rr_KX {
  color: #d4d4d4;
  border-color: #d4d4d4;
}
.customer-info-hide_button__2hnEo {
  position: absolute;
  left: 0;
  width: 20px;
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}
.customer-info-triangle__Gwrtf {
  margin: auto;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 5px solid #53a8ff;
}
.customer-info-profile_container__2RCSy {
  margin-bottom: 20px;
}
.customer-info-button__13wPb {
  width: 120px;
  height: 35px;
  border-radius: 3px;
  background-color: #53a8ff;
  color: #fff;
  font-weight: bold;
  border: none;
}
.customer-info-button__13wPb:focus,
.customer-info-button__13wPb:active {
  outline: none;
}
.customer-info-profile_loading__GIS0l {
  color: #d4d4d4;
  border: 1px solid #d4d4d4;
  background-color: #fff;
}
.customer-info-wallet_icon__1Z7kp {
  display: inline-block;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='15' viewBox='0 0 20 15'%3E %3Cpath fill='%23F87B6E' fill-rule='evenodd' d='M19.219 4.013a.46.46 0 0 1-.462.46h-8.176a.843.843 0 0 0-.843.844v4.366c0 .466.378.844.843.844h8.177a.46.46 0 0 1 .46.46v2.226c0 .987-.8 1.787-1.786 1.787H1.788C.8 15 0 14.2 0 13.213V1.788C0 .8.8 0 1.787 0H17.43c.987 0 1.788.8 1.788 1.788v2.224zm-7.895 1.286h7.119a.59.59 0 0 1 .59.59v3.222a.59.59 0 0 1-.59.591h-7.119a.59.59 0 0 1-.59-.59V5.888a.59.59 0 0 1 .59-.59zm1.426 3.115a.914.914 0 1 0 0-1.828.914.914 0 0 0 0 1.828z'/%3E %3C/svg%3E") no-repeat center;
  width: 20px;
  height: 15px;
  margin-right: 8px;
  background-size: contain;
  vertical-align: baseline;
}
.customer-info-balance_label__11JER {
  color: #f87b6e;
  padding-right: 10px;
  font-size: 19px;
  line-height: 16px;
  font-weight: normal;
}
