.index-no_data_container__hhgWB {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.index-ranking_report_page__6PgZ- {
  padding: 20px 19px 0 19px;
  height: 100vh;
  overflow: auto;
}
