.base-agent-statistic-datepicker_changed__13KwE div {
  color: #53a8ff;
}
.base-agent-statistic-content__strikes-popup__2rKuQ {
  display: none;
  position: absolute;
  width: 172px;
  border-radius: 3px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 4px 0 rgba(0,0,0,0.07);
          box-shadow: 0 0 4px 0 rgba(0,0,0,0.07);
  border: solid 1px #d3d3d3;
  font-family: Hind;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #fff;
}
.base-agent-statistic-content__strikes-popup-title__3LSBk {
  background-color: #47475b;
  margin: 0;
  padding: 9px 10px;
}
.base-agent-statistic-content__strikes-popup-title_data__2B_Wi {
  float: right;
}
.base-agent-statistic-content__strikes-popup-body__21lmk {
  padding: 10px 9px;
  font-size: 13px;
  color: #47475b;
}
.base-agent-statistic-content__strikes-popup-row__2F87p {
  margin: 0;
  line-height: 20px;
}
.base-agent-statistic-content__strikes-popup-row_data__3O7oU {
  float: right;
}
.base-agent-statistic-head__2dslZ {
  display: inline-block;
}
.base-agent-statistic-head__element__1RpJj {
  padding: 5px;
  border: 1px solid #e6eaef;
  text-align: center;
  background-color: #f2f5f8;
  width: 150px;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #47475b;
  cursor: pointer;
}
.base-agent-statistic-body__row__EU5xz {
  text-align: center;
  margin: 5px 0;
}
.base-agent-statistic-body__row-element__EeCHV {
  padding: 5px 0;
  border: 1px solid #e6eaef;
  color: #636363;
  font-size: 14px;
}
.base-agent-statistic-body__row-element__EeCHV:hover {
  background-color: #e8f3f1;
}
.base-agent-statistic-body__pagination__1Dxgw {
  text-align: center;
}
.base-agent-statistic-message__2w6X1 {
  width: 100%;
  font-family: Hind;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  line-height: 30px;
  color: #dde5ef;
}
.base-agent-statistic-error__3nJ5b {
  color: #f87b6e;
}
.base-agent-statistic-color-approved__2sm-T {
  color: #76d466;
}
.base-agent-statistic-color-striked__3QgdF {
  color: #f87b6e;
}
.base-agent-statistic-color-blue__3PIWN {
  color: #53a8ff;
}
.base-agent-statistic-link__3J_m4 {
  color: #53a8ff;
  text-decoration: none;
  cursor: pointer;
  display: block;
}
.base-agent-statistic-select__3w556 {
  position: relative;
  padding: 0 5px;
}
.base-agent-statistic-select__3w556:hover {
  background-color: #e8f3f1;
}
.base-agent-statistic-select__options__2H2zY {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  top: 18px;
  width: 100%;
}
.base-agent-statistic-arrow__wYFH- {
  display: inline-block;
  float: right;
  width: 6px;
  height: 6px;
  margin-top: 4px;
}
.base-agent-statistic-arrow__up__2_Azm {
  width: 0;
  height: 0;
  border-width: 0 3px 6px 3px;
  border-style: solid;
  border-color: transparent transparent #47475b transparent;
}
.base-agent-statistic-arrow__down__3Rz8a {
  width: 0;
  height: 0;
  border-width: 6px 3px 0 3px;
  border-style: solid;
  border-color: #47475b transparent transparent transparent;
}
