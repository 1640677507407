.inline-timezone-input-container__2oDrL {
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 30px;
}
.inline-timezone-input-container__2oDrL .Select .Select-control {
  height: 30px;
}
.inline-timezone-input-container__2oDrL .Select .Select-placeholder,
.inline-timezone-input-container__2oDrL .Select :not(.Select--multi) > .Select-control .Select-value {
  height: 30px;
}
.inline-timezone-input-container__2oDrL .Select .Select-input {
  height: 30px;
}
.inline-timezone-input-container__2oDrL .is-focused:not(.is-open) > .Select-control {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.inline-timezone-input-container__2oDrL .Select-placeholder,
.inline-timezone-input-container__2oDrL :not(.Select--multi) > .Select-control .Select-value {
  line-height: 30px;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}
.inline-timezone-input-container__2oDrL .Select-input {
  padding-left: 0;
  padding-right: 0;
}
.inline-timezone-input-container__2oDrL .Select-input > input {
  height: 30px;
}
.inline-timezone-input-container__2oDrL .Select-option {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  padding: 0 8px;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 30px;
  line-height: 30px;
  text-overflow: ellipsis;
}
.inline-timezone-input-container__2oDrL .Select-option.is-focused {
  background: #e5f2ff;
}
.inline-timezone-input-container__2oDrL .Select-menu {
  padding: 10px 10px 13px 10px;
}
.inline-timezone-input-container__2oDrL .Select-clear {
  padding-top: 2px;
}
.inline-timezone-input-input__3pJM2 {
  border: none;
  background: #fff0be;
  width: 150px;
}
.inline-timezone-input-svg__22XvR svg {
  width: 12px;
  height: 12px;
  vertical-align: middle;
  margin-right: 3px;
}
