.index-banner__2RY77 {
  position: fixed;
  width: 100%;
  height: 100px;
  background: #fff;
  border: 1px solid #aab6c8;
  -webkit-box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
          box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
  border-radius: 4px;
  padding: 20px;
  width: 713px;
  height: 212px;
  left: 50%;
  bottom: 250px;
  z-index: 5;
  font-size: 18px;
  margin-left: -225px;
}
.index-icon_attention__XtkqB {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNjY2NjcyIDEzSDE1LjMzMzNMOC4wMDAwMSAwLjMzMzMyOEwwLjY2NjY3MiAxM1pNOC42NjY2NyAxMUg3LjMzMzM0VjkuNjY2NjZIOC42NjY2N1YxMVpNOC42NjY2NyA4LjMzMzMzSDcuMzMzMzRWNS42NjY2Nkg4LjY2NjY3VjguMzMzMzNaIiBmaWxsPSIjRUI1NzU3Ii8+Cjwvc3ZnPgo=");
  background-position: center;
  width: 15px;
  height: 13px;
  display: inline-block;
  margin-right: 8px;
}
.index-title__3gG9R {
  font-weight: 700;
  margin-bottom: 16px;
}
.index-title__3gG9R span {
  color: #eb5757;
}
.index-subtitle__20lnB {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
}
ol {
  margin: 0 0 20px 0;
  padding: 0;
  list-style-position: inside;
}
ol li {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}
.index-notes__8tmzn {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #27ae60;
}
.index-btn__31V5E {
  position: absolute;
  display: inline-block;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  color: #fff;
  width: 80px;
  height: 32px;
  background: #219653;
  border: 1px solid #219653;
  border-radius: 4px;
  right: 20px;
  bottom: 20px;
}
.index-close__1rVDB {
  width: 14px;
  height: 14px;
  position: absolute;
  display: inline-block;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzEwMV8yMjA3KSI+CjxwYXRoIGQ9Ik0xNS44MzM0IDUuMzQxNjNMMTQuNjU4NCA0LjE2NjYzTDEwIDguODI0OTZMNS4zNDE2OSA0LjE2NjYzTDQuMTY2NjkgNS4zNDE2M0w4LjgyNTAyIDkuOTk5OTZMNC4xNjY2OSAxNC42NTgzTDUuMzQxNjkgMTUuODMzM0wxMCAxMS4xNzVMMTQuNjU4NCAxNS44MzMzTDE1LjgzMzQgMTQuNjU4M0wxMS4xNzUgOS45OTk5NkwxNS44MzM0IDUuMzQxNjNaIiBmaWxsPSIjMkMyRDQwIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMTAxXzIyMDciPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==");
  background-position: center;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
