.modal-window-container__2ve5p {
  position: fixed;
  background-color: rgba(0,0,0,0.7);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.modal-window-data__3UFn3 {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: 15px;
  max-height: 100%;
  max-width: 100%;
}
