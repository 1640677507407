.operator-filter-container__3j-36 {
  position: relative;
  padding: 12px 16px 11px;
  background-color: #fff;
  border-bottom: 1px solid #e6eaef;
  min-width: 900px;
}
.operator-filter-textbox__182Ul {
  display: inline-block;
  width: 400px;
  border: 1px solid #e6eaef;
  border-radius: 3px;
  margin-right: 10px;
}
.operator-filter-textbox_input__1sISM {
  color: #47475b;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  height: 30px !important;
}
.operator-filter-select__fp79I {
  display: inline-block;
  border: solid 1px #e6eaef;
  padding: 8px;
  margin-right: 10px;
  width: 117px;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #a9b5c9;
  border-radius: 3px;
}
.operator-filter-select__options__1V6-Q {
  width: 117px;
  margin-left: -9px;
  margin-top: 4px;
}
.operator-filter-calendar__g5Mwg {
  margin-right: 10px;
  display: inline-block;
}
.operator-filter-export__2CpWu {
  display: inline-block;
  float: right;
  width: 96px;
  height: 30px;
  border-radius: 3px;
  background-color: #fff;
  border: solid 1px #56aafc;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: #53a8ff;
  text-decoration: none;
  cursor: pointer;
  padding-top: 7px;
  padding-left: 20px;
  background: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='17px' height='16px' viewBox='0 0 17 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3E1D12285D-CABD-4EF3-AC24-36A8C1C21C25%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E %3Cg id='Operator_edit_email' transform='translate(-1169.000000, -299.000000)' stroke='%2356AAFC' stroke-width='0.75'%3E %3Cg id='Page-1' transform='translate(1170.000000, 300.000000)'%3E %3Cpolyline id='Stroke-1' points='10.9281094 6.19338667 10.9281094 3.80678667 7.20154688 0.0967866667 0.234515625 0.0967866667 0.234515625 13.7234533 10.9281094 13.7234533 10.9281094 11.4791867'%3E%3C/polyline%3E %3Cpolygon id='Stroke-3' points='7.20159375 3.80664667 7.20159375 0.0966466667 10.9281563 3.80664667'%3E%3C/polygon%3E %3Cpath d='M7.89473684,8.55555556 L15,8.55555556' id='Stroke-5'%3E%3C/path%3E %3Cpolyline id='Stroke-7' points='13.4210526 7 15 8.6081452 13.5239745 10.1111111'%3E%3C/polyline%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E") 15px 6px no-repeat;
}
