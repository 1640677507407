.scheduled-message-item-hidden_controls__qO0x8 {
  float: right;
  visibility: hidden;
  font-size: 14px;
  font-weight: 600;
  color: #a9b5c9;
  cursor: default;
}
.scheduled-message-item-container__2wlmh {
  position: relative;
  word-break: break-all;
}
.scheduled-message-item-container__2wlmh:hover .scheduled-message-item-hidden_controls__qO0x8 {
  visibility: visible;
}
.scheduled-message-item-disabled_overlay__1KNMG {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,0.4);
}
.scheduled-message-item-header__3QbaA {
  margin-bottom: 6px;
}
.scheduled-message-item-header__3QbaA::after {
  content: '';
  display: table;
  clear: both;
}
.scheduled-message-item-main_title__328Fs {
  margin: 0;
  float: left;
  font-size: 15px;
  font-weight: 600;
  color: #47475b;
}
.scheduled-message-item-main_title__328Fs::before {
  display: none;
}
.scheduled-message-item-subtitle__2zWj6 {
  float: left;
  font-size: 15px;
  font-weight: 500;
  color: #a3a3ad;
  margin-left: 6px;
}
.scheduled-message-item-delete__36M2j,
.scheduled-message-item-edit__16O5G {
  cursor: pointer;
}
.scheduled-message-item-delete__36M2j:hover,
.scheduled-message-item-edit__16O5G:hover {
  text-decoration: underline;
}
.scheduled-message-item-delete__36M2j {
  color: #fc6453;
  margin-right: 5px;
}
.scheduled-message-item-edit__16O5G {
  color: #53a8ff;
  margin-left: 5px;
}
.scheduled-message-item-message__6vs4W {
  margin: 0;
  font-size: 14px;
  color: #47475b;
}
.scheduled-message-item-edit_message__37_eS {
  display: block;
  width: 100%;
  height: 75px;
  padding: 15px;
  border-radius: 5px;
  border: solid 1px #a9b5c9;
  font-size: 14px;
  color: #47475b;
  resize: none;
  margin-bottom: 15px;
}
.scheduled-message-item-edit_message__37_eS:focus,
.scheduled-message-item-edit_message__37_eS:active {
  outline: none;
}
.scheduled-message-item-datepicker_container__2A0y-,
.scheduled-message-item-timepicker_container__36t0U {
  display: inline-block;
  width: 140px;
}
.scheduled-message-item-datepicker_container__2A0y- {
  margin-right: 20px;
}
.scheduled-message-item-datepicker__24g5v {
  border-radius: 4px;
  border: solid 1px #aeafbb;
}
.scheduled-message-item-submit__Qffat,
.scheduled-message-item-cancel__VofQd {
  float: right;
}
.scheduled-message-item-submit__Qffat {
  margin-left: 6px;
}
