.base-form-button-base__3AiL9 {
  border: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 2px;
}
.base-form-button-base__3AiL9:focus {
  outline: none;
}
.base-form-button-default__3gRKx,
.base-form-submit__39sgn {
  padding: 0 14px;
  height: 32px;
  line-height: 33px;
}
.base-form-button-default__3gRKx.base-form-only_icon__241_E,
.base-form-submit__39sgn.base-form-only_icon__241_E {
  font-size: 18px;
  width: 36px;
}
.base-form-button-default__3gRKx .base-form-badge__3eJ_x,
.base-form-submit__39sgn .base-form-badge__3eJ_x {
  height: 16px;
  line-height: 16px;
}
.base-form-button-small__3HHXm,
.base-form-nested_add__1eSg7 {
  padding: 0 13px;
  text-transform: uppercase;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.base-form-button-small__3HHXm.base-form-only_icon__241_E,
.base-form-nested_add__1eSg7.base-form-only_icon__241_E {
  padding-top: 9px;
  font-size: 13px;
  width: 28px;
}
.base-form-button-extra-small__3dBeX {
  padding: 0 6px;
  text-transform: uppercase;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
}
.base-form-button-primary__3fMCg,
.base-form-submit__39sgn {
  background-color: #188fea;
  color: #fff;
}
.base-form-button-primary__3fMCg:hover,
.base-form-submit__39sgn:hover {
  background-color: #0a71bf;
}
.base-form-button-light__2vSW9 {
  background-color: #fff;
  color: #0a71bf;
}
.base-form-button-transparent__3v6Ik {
  background-color: none;
  color: #fff;
  border: 1px solid #fff;
}
.base-form-button-transparent__3v6Ik:hover {
  background-color: #fff;
  color: #0a71bf;
}
.base-form-button-danger__3MJ0Z {
  background-color: #d85151;
  color: #fff;
}
.base-form-button-danger__3MJ0Z:hover {
  background-color: #c13030;
}
.base-form-button-full__3z_BF {
  width: 100%;
  text-align: center;
}
.base-form-button-secondary__1xIu4 {
  background-color: #aaa;
  color: #fff;
  font-weight: bold;
}
.base-form-button-secondary__1xIu4:hover {
  background-color: #0a71bf;
}
.base-form-button-tertiary__2Uq9b {
  color: #aaa;
  font-weight: bold;
}
.base-form-button-tertiary__2Uq9b:hover {
  color: #0a71bf;
}
.base-form-button-dark__3M3SW,
.base-form-nested_add__1eSg7 {
  background-color: #0a71bf;
  color: #fff;
}
.base-form-button-dark__3M3SW:hover,
.base-form-nested_add__1eSg7:hover {
  background-color: #005fa7;
}
.base-form-button-only-icon__1MzYC {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.base-form-button-icon-big__1ABEI {
  font-size: 18px;
  width: 38px;
}
.base-form-button-icon__3ZQ8g {
  margin-right: 5px;
}
.base-form-button-bold__EtewX {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.base-form-button-float-right__U6mAr {
  float: right;
}
.base-form-button-with-hr__2QznQ {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 0;
}
.base-form-button-with-hr__2QznQ:after {
  display: block;
  position: relative;
  bottom: 15px;
  content: '';
  border-bottom: 1px solid #c1cdd6;
  z-index: -1;
}
.base-form-button-processing__2MrAg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
}
.base-form-button-invisible__1UKco {
  visibility: hidden;
}
.base-form-button-tooltip__31S93 {
  top: 38px;
  left: 50%;
  margin-left: -75px;
  color: #fff;
  width: 150px;
  font-size: 13px;
  text-align: center;
  text-transform: initial;
  position: absolute;
  padding: 5px;
  z-index: 2;
  background-color: #3e434d;
  height: 26px;
  line-height: 16px;
  opacity: 0.8;
  border-radius: 2px;
}
.base-form-button-tooltip__31S93:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-bottom-color: #3e434d;
  border-width: 5px;
  margin-left: -5px;
}
.base-form-button-badge__39WPj {
  padding: 0px 7px;
  min-width: 10px;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #f86e5f;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 40px;
}
.base-form-input-default__FnJHH,
.base-form-input__vDz58 {
  height: 40px;
  border: 1px solid #e2e6ed;
  padding: 10px 14px;
  white-space: nowrap;
}
.base-form-input-default__FnJHH:focus,
.base-form-input__vDz58:focus {
  outline: none;
  border-color: #0a71bf;
}
.base-form-input-full__1PqQq,
.base-form-input__vDz58,
.base-form-textarea__W5eC2 {
  width: 100%;
}
.base-form-input-no-border__3Z-h6,
.base-form-input__vDz58,
.base-form-textarea__W5eC2 {
  border: none;
}
.base-form-input-rounded__24OC3,
.base-form-input__vDz58,
.base-form-textarea__W5eC2 {
  border-radius: 2px;
}
.base-form-input-with-address__37hWU {
  width: 100%;
  white-space: nowrap;
}
.base-form-input-address__GEb2_ {
  padding-right: 111px;
}
.base-form-input-address-name__14QKP {
  position: relative;
  color: #333;
  margin-left: -97px;
  padding: 10px 14px 10px 14px;
  width: 80px;
  border-left: 1px solid #c1cdd6;
}
.base-form-input-field__1kwEw {
  white-space: nowrap;
  margin-bottom: 20px;
}
.base-form-input-fielded__2lyiE {
  padding-right: calc(25% + 28px);
}
.base-form-input-textarea__2Fnz2 {
  white-space: normal;
}
.base-form-input-button__g0s-M {
  position: relative;
  display: inline-block;
  background-color: #9eaab9;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  height: 24px;
  line-height: 24px;
  width: 25%;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
  margin-left: -14px;
}
.base-form-input-base__3H1Qt {
  width: 300px;
  display: inline-block;
  vertical-align: top;
  border: 1px solid #e5e9ed;
  border-radius: 3px;
}
.base-form-scroll_container__3VXuE {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}
.base-form-container__1luhv {
  display: block;
  max-width: 600px;
  padding-left: 10px;
  margin-top: 10px;
}
.base-form-title__LvpQM {
  font-size: 16px;
  font-weight: bold;
  color: #8b97a7;
  text-transform: uppercase;
}
.base-form-first_title__mGCnw {
  margin-top: 0;
}
.base-form-first_title__mGCnw::before {
  display: none;
}
.base-form-group__2w6lV {
  margin-bottom: 20px;
}
.base-form-nested_group__1ifUB {
  position: relative;
}
.base-form-nested_group__1ifUB:after {
  content: '';
  display: table;
  clear: both;
}
.base-form-nested_delete__HXQxn {
  position: absolute;
  top: 10px;
  right: -25px;
  background: #8b97a7;
  color: #fff;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 11px;
}
.base-form-nested_delete_icon__eyVzW {
  line-height: 20px !important;
}
.base-form-label__21BiS {
  padding-bottom: 5px;
  font-weight: bold;
  display: block;
}
.base-form-label__21BiS.base-form-label_checkbox__2GsLf {
  display: inline;
  margin-left: 10px;
}
.base-form-controls__i_yJ3 {
  display: block;
}
.base-form-textarea__W5eC2 {
  resize: none;
}
.base-form-delete__151SC {
  margin-left: 10px;
}
.base-form-label_textarea__2TlgM {
  vertical-align: super;
}
.base-form-hr__1I2LC {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 2px solid #bdc6d2;
}
.base-form-errors__1CIp6 {
  color: #d85151;
  margin-bottom: 10px;
}
/*
.button_processing
  @extend .button-processing
.button_invisible
  @extend .button-invisible
.button_failure
  @extend .button-default .button-danger
*/
.base-form-hints__1DK6p {
  margin: 0 0 20px 0;
}
.base-form-hint__HjIkc {
  margin: 0 0 5px 0;
}
.base-form-credential_error__3UhZQ {
  color: #f00;
  margin-bottom: 5px;
}
