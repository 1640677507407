.customers-container__1DWg4 {
  min-width: 388px;
}
.customers-header__uuq-8 {
  display: block;
  position: relative;
}
.customers-searchline__uxKKF {
  background: #e6eaef;
  padding: 8px;
}
.customers-search_result_amount__2b1r1 {
  position: absolute;
  top: 23px;
  right: 40px;
  color: #919aa2;
}
.customers-buttons__QkiC6 {
  vertical-align: middle;
  text-align: center;
  height: 45px;
  line-height: 45px;
  border-bottom: solid 2px #dde5ef;
}
.customers-button_icon__1SNfM {
  display: inline-block;
  width: 19px;
  height: 19px;
  background-color: #53a8ff;
  color: #fff;
  border-radius: 10px;
  margin-right: 8px;
  vertical-align: sub;
}
.customers-plus__2r4I_ {
  background: #fff;
  height: 9px;
  position: relative;
  width: 1px;
  top: 5px;
  left: 9px;
}
.customers-plus__2r4I_:after {
  content: '';
  background: #fff;
  height: 1px;
  left: -4px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.customers-button_label__3tAZS {
  color: #53a8ff;
  display: inline-block;
  text-decoration: none;
}
.customers-button_add__aBpCb {
  vertical-align: middle;
}
