.menu-container__OPbi4 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.2s ease transform;
  transition: 0.2s ease transform;
  background-color: #47475b;
  z-index: 1;
}
.menu-separator__eVqCW {
  border-top: 1px solid #33658a;
}
.menu-link__1ICS9 {
  display: block;
  text-decoration: none;
  position: relative;
  height: 60px;
  text-align: center;
}
.menu-link__1ICS9:hover:not(.menu-selected__22jjk) svg * {
  fill: #8b8e9e;
  stroke-opacity: 0.5;
}
.menu-selected__22jjk .menu-svg__3xnUk {
  background-color: #2c2c40;
}
.menu-disabled__12IoV svg * {
  fill: #2a2a38;
}
.menu-with_icon__2jQo1 {
  background-repeat: no-repeat;
  background-position: center;
}
.menu-with_icon__2jQo1:before {
  content: '';
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  left: 22px;
  top: 18px;
  color: #3f8cc6;
}
.menu-closed__3B8dd {
}
.menu-closed__3B8dd:before {
  content: '\f00d';
}
.menu-all__1RTvk {
}
.menu-all__1RTvk:before {
  content: '\f003';
}
.menu-confidence__FIdPR {
}
.menu-confidence__FIdPR:before {
  content: '\f07e';
}
.menu-answers__TwWsE {
}
.menu-answers__TwWsE:before {
  content: '\f1c0';
}
.menu-companies__3769T {
}
.menu-companies__3769T:before {
  content: '\f1ad';
}
.menu-transfer__12IqC {
}
.menu-transfer__12IqC:before {
  content: '\f0ec';
}
.menu-logout__23O0h {
}
.menu-logout__23O0h:before {
  content: '\f08b';
}
@media (max-width: 1024px) {
  .menu-container__OPbi4 {
    -webkit-transform: translateX(-245px);
            transform: translateX(-245px);
  }
  .menu-container_opened__2Nozq {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  .menu-header__2f9qt {
    display: block;
  }
}
.menu-badge__3uIh4 {
  padding: 2px 7px;
  min-width: 10px;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #f86e5f;
  color: #fff;
  font-weight: bold;
  line-height: 1em;
  position: absolute;
  top: 6px;
  left: 34px;
}
.menu-svg__3xnUk {
  width: 50px;
  height: 50px;
  display: block;
  margin: auto;
  border-radius: 3px;
}
.menu-svg__3xnUk svg {
  height: 100%;
  width: 24px;
}
