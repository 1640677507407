.reminders-button-base__3w90M {
  border: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 2px;
}
.reminders-button-base__3w90M:focus {
  outline: none;
}
.reminders-button-default__o1Z-e,
.reminders-add_reminder__p2J8c,
.reminders-save__132D- {
  padding: 0 14px;
  height: 32px;
  line-height: 33px;
}
.reminders-button-default__o1Z-e.reminders-only_icon__27RXL,
.reminders-add_reminder__p2J8c.reminders-only_icon__27RXL,
.reminders-save__132D-.reminders-only_icon__27RXL {
  font-size: 18px;
  width: 36px;
}
.reminders-button-default__o1Z-e .reminders-badge__3Y02E,
.reminders-add_reminder__p2J8c .reminders-badge__3Y02E,
.reminders-save__132D- .reminders-badge__3Y02E {
  height: 16px;
  line-height: 16px;
}
.reminders-button-small__1eXvx,
.reminders-add_reminder__p2J8c {
  padding: 0 13px;
  text-transform: uppercase;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.reminders-button-small__1eXvx.reminders-only_icon__27RXL,
.reminders-add_reminder__p2J8c.reminders-only_icon__27RXL {
  padding-top: 9px;
  font-size: 13px;
  width: 28px;
}
.reminders-button-extra-small__1LsnU {
  padding: 0 6px;
  text-transform: uppercase;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
}
.reminders-button-primary__1Kkeh,
.reminders-add_reminder__p2J8c,
.reminders-save__132D- {
  background-color: #188fea;
  color: #fff;
}
.reminders-button-primary__1Kkeh:hover,
.reminders-add_reminder__p2J8c:hover,
.reminders-save__132D-:hover {
  background-color: #0a71bf;
}
.reminders-button-light__2Hztk {
  background-color: #fff;
  color: #0a71bf;
}
.reminders-button-transparent__3iMKH {
  background-color: none;
  color: #fff;
  border: 1px solid #fff;
}
.reminders-button-transparent__3iMKH:hover {
  background-color: #fff;
  color: #0a71bf;
}
.reminders-button-danger__1NrQu {
  background-color: #d85151;
  color: #fff;
}
.reminders-button-danger__1NrQu:hover {
  background-color: #c13030;
}
.reminders-button-full__2F1IL {
  width: 100%;
  text-align: center;
}
.reminders-button-secondary__XEbQL {
  background-color: #aaa;
  color: #fff;
  font-weight: bold;
}
.reminders-button-secondary__XEbQL:hover {
  background-color: #0a71bf;
}
.reminders-button-tertiary__3yw6o {
  color: #aaa;
  font-weight: bold;
}
.reminders-button-tertiary__3yw6o:hover {
  color: #0a71bf;
}
.reminders-button-dark__saRpk {
  background-color: #0a71bf;
  color: #fff;
}
.reminders-button-dark__saRpk:hover {
  background-color: #005fa7;
}
.reminders-button-only-icon__-yVWU {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.reminders-button-icon-big__oyVLx {
  font-size: 18px;
  width: 38px;
}
.reminders-button-icon__3IM2l {
  margin-right: 5px;
}
.reminders-button-bold__BbLF- {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.reminders-button-float-right__2GsqD {
  float: right;
}
.reminders-button-with-hr__vC9nO {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 0;
}
.reminders-button-with-hr__vC9nO:after {
  display: block;
  position: relative;
  bottom: 15px;
  content: '';
  border-bottom: 1px solid #c1cdd6;
  z-index: -1;
}
.reminders-button-processing__QoUTm {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
}
.reminders-button-invisible__3jmeg {
  visibility: hidden;
}
.reminders-button-tooltip__3p4xj {
  top: 38px;
  left: 50%;
  margin-left: -75px;
  color: #fff;
  width: 150px;
  font-size: 13px;
  text-align: center;
  text-transform: initial;
  position: absolute;
  padding: 5px;
  z-index: 2;
  background-color: #3e434d;
  height: 26px;
  line-height: 16px;
  opacity: 0.8;
  border-radius: 2px;
}
.reminders-button-tooltip__3p4xj:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-bottom-color: #3e434d;
  border-width: 5px;
  margin-left: -5px;
}
.reminders-button-badge__HYd1P {
  padding: 0px 7px;
  min-width: 10px;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #f86e5f;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 40px;
}
.reminders-new_reminder__1boZm {
  margin-bottom: 30px;
}
.reminders-container__2FmEE {
  position: relative;
}
.reminders-textarea__2MX_C {
  width: 100%;
  min-height: 82px;
  border: solid 1px rgba(173,175,187,0.57);
  border-radius: 2px;
  padding: 10px 10px;
  margin-bottom: 20px;
  width: 593px;
  resize: vertical;
}
.reminders-datepicker_container__1Pfb1,
.reminders-timepicker_container__2IY6y {
  display: inline-block;
  width: 140px;
}
.reminders-datepicker_container__1Pfb1 {
  margin-right: 20px;
}
.reminders-datepicker__3F-1x {
  border-radius: 4px;
  border: solid 1px #aeafbb;
}
.reminders-add_reminder__p2J8c {
  margin: auto;
  margin-top: 15px;
  background-color: #53a8ff;
  text-transform: capitalize;
  font-weight: 600;
}
.reminders-add_reminder_wrapper__2NJIH {
  width: 100%;
  text-align: center;
}
.reminders-loader_container__2WSlY {
  height: 120px;
}
.reminders-error__1nJcP {
  color: #f00;
}
.reminders-actions__2C_-- {
  text-align: right;
  margin-bottom: 15px;
}
.reminders-save__132D- {
  display: inline-block;
}
.reminders-preset_title__23KLp {
  display: inline-block;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: #aeafbb;
}
.reminders-preset_option__3l_Pk {
  width: 40px;
  height: 32px;
  border-radius: 3px;
  background-color: #fff;
  border: solid 1px #aeafbb;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: #aeafbb;
  margin-right: 10px;
  vertical-align: middle;
}
.reminders-preset_option__3l_Pk:focus {
  outline: none;
}
.reminders-preset_option__3l_Pk:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
.reminders-preset_options__1RGfw {
  display: inline-block;
  margin-left: 10px;
}
