.index-edit_footer__1tg9M {
  padding: 10px;
}
.index-edit_footer--loading__1DBHf {
  opacity: 0.5;
  pointer-events: none;
}
.index-edit_footer__1tg9M .index-edit_footer_add_new_report__viXp0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0 9px;
  cursor: pointer;
}
.index-edit_footer__1tg9M .index-edit_footer_add_new_report__icon__3Ykry {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0001 1.66666C5.40008 1.66666 1.66675 5.39999 1.66675 9.99999C1.66675 14.6 5.40008 18.3333 10.0001 18.3333C14.6001 18.3333 18.3334 14.6 18.3334 9.99999C18.3334 5.39999 14.6001 1.66666 10.0001 1.66666ZM14.1667 10.8333H10.8334V14.1667H9.16675V10.8333H5.83342V9.16666H9.16675V5.83332H10.8334V9.16666H14.1667V10.8333Z' fill='%2358AAFC'/%3E%3C/svg%3E%0A");
  width: 20px;
  height: 20px;
  display: block;
}
.index-edit_footer__1tg9M .index-edit_footer_add_new_report__text__3Icy7 {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #58aafc;
}
.index-edit_footer__1tg9M .index-edit_footer_btn_block__2ZD4T {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  gap: 0 16px;
  margin-top: 12px;
}
.index-edit_footer__1tg9M .index-edit_footer_btn_block__2ZD4T button {
  width: auto;
  height: 32px;
}
