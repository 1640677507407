.customer-merge-modal-container__ChEqW {
  border-radius: 4px;
  overflow: hidden;
  max-height: 70%;
  width: 400px;
}
.customer-merge-modal-customers__GkK90 {
  background-color: #fff;
}
.customer-merge-modal-customers_container__22vek {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 300px;
  overflow: auto;
}
.customer-merge-modal-customers_item__cT3Nh:hover {
  background-color: #f2f5f8;
}
.customer-merge-modal-title__v3gII {
  font-weight: 700;
  font-size: 14px;
  background-color: #f2f5f8;
  color: #47475b;
  padding: 15px;
  margin: 0;
}
.customer-merge-modal-search__2A1Ci {
  border: 1px solid #d1d2d8;
  border-radius: 2px;
  margin: 15px;
}
.customer-merge-modal-customer_data__3br-3 {
  position: relative;
  margin: 0 15px;
  cursor: pointer;
  padding: 15px 0 0 35px;
}
.customer-merge-modal-customer_name__3C2G5 {
  display: inline-block;
  color: #53a8ff;
  font-weigh: 700;
  font-size: 16px;
  margin: 0;
  margin-bottom: 9px;
}
.customer-merge-modal-customer_name__3C2G5::before {
  display: none;
}
.customer-merge-modal-customer_info__1qnBd {
  border-bottom: 2px dashed #d1d2d8;
  padding-bottom: 8px;
}
.customer-merge-modal-checkbox__3sXZN {
  position: absolute;
  top: 15px;
  left: 0;
}
.customer-merge-modal-controls__3jkJP {
  background-color: #fff;
  text-align: right;
  padding: 10px 15px;
}
.customer-merge-modal-cancel_button__fJGWO {
  margin-right: 10px;
}
.customer-merge-modal-more_container__3aFPP {
  position: relative;
  height: 50px;
}
.customer-merge-modal-more_button__2IVQg {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  color: #003e6d;
  cursor: pointer;
  background: #fff;
  text-transform: uppercase;
}
.customer-merge-modal-more_button__2IVQg:hover {
  background-color: #f2f5f8;
}
.customer-merge-modal-more_button__2IVQg:active,
.customer-merge-modal-more_button__2IVQg:focus {
  outline: none;
}
.customer-merge-modal-error__3YnGt {
  margin: 15px;
  text-align: center;
  font-size: 14px;
  color: #f88276;
}
