.group-settings-container__3xte2 {
  position: relative;
  font-family: Hind;
  margin: 10px;
  padding: 10px;
  background-color: #fff;
  height: calc(100% - 20px);
  max-width: 705px;
  overflow: auto;
  color: #47475b;
}
.group-settings-header__2StB6 {
  overflow: auto;
  margin-bottom: 10px;
}
.group-settings-title__2UcxK {
  font-weight: bold;
  font-size: 20px;
}
.group-settings-float_right__3HYQN {
  float: right;
}
.group-settings-data_section__3Goje {
  display: inline-block;
  margin-right: 80px;
  width: 300px;
  vertical-align: top;
}
.group-settings-no_margin__10YM8 {
  margin: 0;
}
.group-settings-search__1zc1M {
  border: 1px solid #bec4cb;
  border-radius: 3px;
  margin-bottom: 15px;
}
.group-settings-filter_switch__FhfX8 {
  margin-top: 10px;
}
.group-settings-filter_switch_label__2SEGy {
  display: inline-block;
  vertical-align: top;
  padding-top: 9px;
  margin-right: 10px;
}
.group-settings-group_type_select_label__3HYT6 {
  margin-right: 15px;
  font-weight: 500;
}
.group-settings-group_type_select__1UGYj {
  display: inline-block;
  border-bottom: 1px solid #ccc;
  height: 20px;
  width: 140px;
}
