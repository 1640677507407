* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
.clr {
  clear: both;
}
.iScrollVerticalScrollbar .iScrollIndicator {
  background: #0a71bf !important;
  border-radius: 0px !important;
  border: none !important;
  right: 0px !important;
}
::-webkit-scrollbar-thumb {
  background: #a6aaaf;
  border-radius: 50px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
::-webkit-scrollbar-track {
  background: #e6eaef;
  border: 0px none #fff;
}
:global .Select-option {
  overflow: hidden;
}
@font-face {
  font-family: 'Hind';
  src: url(/7b7a3d50615a926fd09f8e007f89a144.woff2) format('woff2'), url(/2b819f817be737b07724b2bcbdc72db6.woff) format('woff');
  font-weight: 300;
}
@font-face {
  font-family: 'Hind';
  src: url(/c005be1a960e71c81f959654d676d9c7.woff2) format('woff2'), url(/b8405397e4ce28cd31d8630c9f3aa375.woff) format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'Hind';
  src: url(/91f874d4d528b5904f5c49dbf4da0705.woff2) format('woff2'), url(/488f7d9049bc0f45ed8651c24d0edabc.woff) format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'OpenSans';
  src: url(/50145685042b4df07a1fd19957275b81.ttf) format('ttf');
  font-weight: 800;
}
@font-face {
  font-family: 'OpenSans';
  src: url(/629a55a7e793da068dc580d184cc0e31.ttf) format('ttf');
  font-weight: 400;
}
svg:not(:root) {
  pointer-events: none;
}
.logMessage .statusChanged {
  font-weight: bold;
}
.logMessage .statusChanged.opened {
  color: #6672d4;
}
.logMessage .statusChanged.dormant {
  color: #d1d466;
}
.logMessage .statusChanged.new_message {
  color: #76d466;
}
.logMessage .statusChanged.closed {
  color: #f86e5f;
}
.logMessage .username {
  color: #53a8ff;
  font-weight: bold;
}
.logMessage .tagname {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border: 1px solid #b7c1d3;
  border-radius: 4px;
  display: inline-block;
  color: #b7c1d3;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin: -1px 5px 0;
  cursor: pointer;
}
.logMessage .tagname:before {
  content: '#';
}
.logMessage .tagname.active {
  border: 1px solid #fdd762;
  background-color: #fdd762;
  color: #2c2d40;
}
.logMessage .tagname:hover {
  text-decoration: none;
}
.ranked .tagname {
  cursor: auto;
  border: 1px solid #e68752;
  background-color: #e68752;
  color: #fff;
}
@-webkit-keyframes fade-and-hide {
  0% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}
@keyframes fade-and-hide {
  0% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}
