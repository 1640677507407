.agent-info-container__1nYQX {
  margin-bottom: 24px;
  min-width: 900px;
}
.agent-info-image-container__1MeWg {
  display: inline-block;
  width: 161px;
  height: 180px;
  margin-right: 15px;
  overflow: hidden;
}
.agent-info-user-image__9oQbW {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background-color: #dde5ef;
}
.agent-info-user-image__hover__Bb8wX {
  width: 161.2px;
  height: 34.4px;
  opacity: 0.64;
  background-color: #000;
  font-family: Hind;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}
.agent-info-user-image__hover__Bb8wX i {
  display: inline-block;
  width: 19.1px;
  height: 13.9px;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='18px' height='15px' viewBox='0 0 18 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3E66132882-5A4D-4E29-B4FA-B3B254E7FAEE%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='Operator_shift' transform='translate(-224.000000, -243.000000)' fill='%23FFFFFF'%3E %3Cg id='Group-17' transform='translate(100.000000, 233.000000)'%3E %3Cg id='Group-12' transform='translate(20.000000, 6.000000)'%3E %3Cg id='Page-1' transform='translate(104.000000, 4.000000)'%3E %3Cpath d='M9.3782,12.0037537 C7.42646061,12.0037537 5.84407627,10.4025314 5.84407627,8.42755703 C5.84407627,6.45258265 7.42646061,4.85236241 9.3782,4.85236241 C11.3299394,4.85236241 12.9123237,6.45258265 12.9123237,8.42755703 C12.9123237,10.4025314 11.3299394,12.0037537 9.3782,12.0037537 M16.9844985,2.48361608 L13.8973174,2.48361608 L13.3655211,1.04744542 C13.2435073,0.717813392 12.9315662,0.5 12.5839417,0.5 L9.3782,0.5 L6.17245826,0.5 C5.82483384,0.5 5.51289272,0.717813392 5.39087886,1.04744542 L4.85908256,2.48361608 L1.77190147,2.48361608 C1.26082451,2.48361608 0.845286709,2.90293598 0.845286709,3.42009719 L0.845286709,13.4348541 C0.845286709,13.9531801 1.26082451,14.3725 1.77190147,14.3725 L16.9844985,14.3725 C17.4955755,14.3725 17.9111133,13.9531801 17.9111133,13.4348541 L17.9111133,3.42009719 C17.9111133,2.90293598 17.4955755,2.48361608 16.9844985,2.48361608' id='Fill-1'%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  vertical-align: bottom;
  margin-left: 15px;
}
.agent-info-user-image_editable__bQXcA {
  cursor: pointer;
}
.agent-info-info__1EZEn {
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.agent-info-info__status__2PeeV {
  display: inline-block;
  width: 50px;
  border-radius: 2px;
  background-color: #f87b6e;
  font-family: Hind;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  color: #fff;
  text-align: center;
  padding: 5px 0 4px;
  margin-right: 7px;
}
.agent-info-info__status_online__2jUWx {
  background-color: #76d466;
}
.agent-info-info__activity-status__37y6L {
  display: inline-block;
  width: 50px;
  border-radius: 2px;
  background-color: #a9b5c9;
  font-family: Hind;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: #fff;
  padding: 5px 0 4px;
  margin-right: 7px;
}
.agent-info-info__activity-status_active__3Llaw {
  background-color: #6c6c7c;
}
.agent-info-info__creation__3FDIa {
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #919aa2;
  margin: 3px 0 14px 5px;
}
.agent-info-info__creation_date__ZFoPp {
  color: #48485a;
  margin-right: 10px;
}
.agent-info-info__main__E_KCD {
  margin-bottom: 8px;
  font-size: 20px;
  font-family: Hind;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  color: #53a8ff;
}
.agent-info-info__main-icon__3Vceh {
  display: none;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3EC9A55290-F039-4146-93DA-718D0AFF8835%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='Operator_edit_hover' transform='translate(-467.000000, -123.000000)' stroke='%2353A8FF'%3E %3Cg id='Page-1' transform='translate(468.000000, 124.000000)'%3E %3Cpath d='M5.02205217,14.0985043 L0.695095652,15.6769391 C0.488486957,15.7520696 0.288834783,15.5517217 0.363965217,15.3458087 L1.9424,11.0188522 L12.1740522,0.7872 C12.7597913,0.20146087 13.7093565,0.20146087 14.2950957,0.7872 L15.2537043,1.7458087 C15.8394435,2.33154783 15.8394435,3.28111304 15.2537043,3.86685217 L5.02205217,14.0985043 Z' id='Stroke-1'%3E%3C/path%3E %3Cpath d='M10.2670609,2.69405217 L13.346713,5.77370435' id='Stroke-3'%3E%3C/path%3E %3Cpath d='M11.0022261,1.95888696 L14.0818783,5.03853913' id='Stroke-5'%3E%3C/path%3E %3Cpath d='M2.00723478,10.9538783 L5.08688696,14.0335304' id='Stroke-7'%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  background-position: center;
  vertical-align: sub;
}
.agent-info-info__main-label__jFOkV {
  display: inline-block;
  padding: 3px 12px 3px 5px;
  margin: 0 6px 0 0;
}
.agent-info-info__main-label_existing__1ccNG {
  color: #47475b;
}
.agent-info-info__main-label_hover__2ozFz:hover {
  background-color: rgba(83,168,255,0.2);
  cursor: pointer;
}
.agent-info-info__main-label_hover__2ozFz:hover + .agent-info-info__main-icon__3Vceh {
  display: inline-block;
}
.agent-info-info__main_credentials__2X0EX {
  font-size: 15px;
  padding-top: 5px;
}
.agent-info-info__main_credentials-email__VWzjj {
  font-weight: normal;
}
.agent-info-info__role__1Pheg {
  margin-bottom: 18px;
  width: 100%;
  padding-left: 6px;
}
.agent-info-info__role-select__zc_Oz {
  font-family: Hind;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #47475b;
  padding-bottom: 4px;
  margin-right: 18px;
  display: inline-block;
}
.agent-info-info__contacts__1-nmq {
  margin-left: 5px;
  margin-bottom: 12px;
}
.agent-info-info__contacts-phones__lYJ1g,
.agent-info-info__contacts-emails__12A9H {
  display: inline-block;
  vertical-align: top;
}
.agent-info-info__contacts-phones__lYJ1g {
  width: 155px;
}
.agent-info-info__contacts-emails__12A9H {
  min-width: 250px;
}
.agent-info-info__contacts_add__14-M0 {
  display: inline-block;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #53a8ff;
  cursor: pointer;
  clear: both;
  float: left;
  margin: 7px 12px 3px 5px;
}
.agent-info-info__contact__2JfeE {
  clear: both;
  float: left;
}
.agent-info-info__contact-label__3W8gB {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 132px;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  color: #47475b;
  padding: 3px 12px 3px 5px;
  margin-right: 6px;
}
.agent-info-info__contact-label_hover__1wG8R:hover {
  background-color: rgba(83,168,255,0.2);
}
.agent-info-info__contact-label_email__2nbTa {
  max-width: inherit;
}
.agent-info-info__contact-edit-icon__EYbll {
  display: none;
  vertical-align: super;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3EC9A55290-F039-4146-93DA-718D0AFF8835%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='Operator_edit_hover' transform='translate(-467.000000, -123.000000)' stroke='%2353A8FF'%3E %3Cg id='Page-1' transform='translate(468.000000, 124.000000)'%3E %3Cpath d='M5.02205217,14.0985043 L0.695095652,15.6769391 C0.488486957,15.7520696 0.288834783,15.5517217 0.363965217,15.3458087 L1.9424,11.0188522 L12.1740522,0.7872 C12.7597913,0.20146087 13.7093565,0.20146087 14.2950957,0.7872 L15.2537043,1.7458087 C15.8394435,2.33154783 15.8394435,3.28111304 15.2537043,3.86685217 L5.02205217,14.0985043 Z' id='Stroke-1'%3E%3C/path%3E %3Cpath d='M10.2670609,2.69405217 L13.346713,5.77370435' id='Stroke-3'%3E%3C/path%3E %3Cpath d='M11.0022261,1.95888696 L14.0818783,5.03853913' id='Stroke-5'%3E%3C/path%3E %3Cpath d='M2.00723478,10.9538783 L5.08688696,14.0335304' id='Stroke-7'%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  background-position: center;
}
.agent-info-info__contact-label_hover__1wG8R:hover + .agent-info-info__contact-edit-icon__EYbll {
  display: inline-block;
}
.agent-info-info__contact-input__3urMV {
  display: inline-block;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  color: #47475b;
  padding: 2px 11px 2px 4px;
  margin-right: 6px;
  border: 1px solid #e6eaef;
  width: 132px;
}
.agent-info-info__contact-input__3urMV:focus {
  outline: none;
}
.agent-info-info__contact-input__3urMV + .agent-info-info__contact-edit-icon__EYbll {
  display: inline-block;
  vertical-align: text-bottom;
}
.agent-info-info__contact-input_email__2zFeB {
  width: 170px;
}
.agent-info-info__activity__3GpWP {
  margin-top: 5px;
  margin-left: 5px;
}
.agent-info-info__activity--row__1YCSK {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
      align-self: center;
}
.agent-info-info__activity-switcher__3oNcY {
  display: inline-block;
  border-radius: 12px;
  margin: 3px;
  width: 22.2px;
  height: 22.2px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.09);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.09);
}
.agent-info-info__activity-overlay__DbGG5 {
  width: 50.2px;
  height: 28px;
  border-radius: 100px;
  background-color: #a9b5c9;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  margin-right: 5px;
}
.agent-info-info__activity-overlay_active__ShBSt {
  background-color: #76d466;
}
.agent-info-info__activity-overlay_active__ShBSt .agent-info-info__activity-switcher__3oNcY {
  float: right;
}
.agent-info-info__activity__3GpWP .agent-info-info_activity_notification_text__23jbZ {
  max-width: 107px;
}
.agent-info-info__activity__3GpWP .agent-info-row__rwrlX {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.agent-info-controls__3QH1O {
  display: inline-block;
  float: right;
}
.agent-info-controls__button__g1G4q {
  display: inline-block;
  width: 70px;
  border-radius: 2px;
  background-color: #fff;
  border: solid 1px #56aafc;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #53a8ff;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  vertical-align: top;
  margin-left: 10px;
  padding: 8px 0 7px;
}
.agent-info-controls__button__g1G4q:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
.agent-info-controls__button_save__1q7Cg {
  color: #fff;
  background-color: #53a8ff;
}
.agent-info-controls__button_disabled__T_NX6 {
  color: #d4d4d4;
  border-color: #d4d4d4;
  background-color: #fff;
}
.agent-info-controls__button__g1G4q:focus {
  outline: none;
}
