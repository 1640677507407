.loader-container__2Xu8O {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.loader-loader__1Jk25 {
  width: 32px;
  height: 32px;
}
.loader-load_more_container__JoP7F {
  padding-top: 17px;
  padding-bottom: 17px;
  text-align: center;
}
.loader-load_more__1PE_z {
  display: block;
  margin: 6px 6px 6px 6px;
  border: 1px solid #dee3ea;
  color: #003e6d;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  background: #fff;
  text-transform: uppercase;
}
