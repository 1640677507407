.download-all-button-button__27_sa {
  width: 22px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
}
.download-all-button-button__27_sa svg {
  width: 22px;
  height: 20px;
}
.download-all-button-button__27_sa svg path {
  stroke: #53a8ff;
}
.download-all-button-container__2peiK {
  width: 22px;
  height: 20px;
  display: block;
  position: relative;
}
