.landings-header__1x93U {
  position: relative;
  height: 57px;
  background-color: #e6eaef;
  overflow: visible;
  z-index: 1;
}
.landings-buttons__2effE {
  padding: 14px 10px 0;
}
