.selector-selector__1x7p- {
  position: relative;
}
.selector-icon__B65t_ {
  position: absolute;
  padding: 12px 10px;
}
.selector-input__IT7wc {
  outline: none;
  width: 100%;
  padding: 12px 10px 12px 36px;
  border: 1px solid #e2e6ed;
  border-radius: 4px;
  color: #677686;
}
.selector-input__IT7wc.selector-selected__3huPj {
  border-color: #53a8ff;
}
.selector-options_wrapper__1BMrK {
  position: relative;
}
.selector-options__3jHRH {
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  bottom: 0;
  width: 100%;
  border: 1px solid #e2e6ed;
  border-radius: 3px 3px 0 0;
  background-color: #fff;
  padding: 10px 10px 10px 10px;
  margin-bottom: -3px;
  max-height: 200px;
  overflow-y: auto;
}
.selector-options_underinput__2N1U1 {
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  top: 39px;
  width: 100%;
  z-index: 1;
  border: 1px solid #e2e6ed;
  border-radius: 3px 3px 0 0;
  background-color: #fff;
  padding: 10px 10px 10px 10px;
  margin-bottom: -3px;
  max-height: 200px;
  overflow-y: auto;
}
.selector-option__24fqY {
  padding: 10px 8px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selector-option__24fqY:hover {
  background-color: #e5f2ff;
  border-radius: 3px;
}
.selector-values__2O55y {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 10px;
  font-size: 12px;
}
.selector-value__2m76z {
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  border: 1px solid #b7c1d3;
  border-radius: 4px;
  margin: 0 5px 5px 0;
  color: #b7c1d3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #fcfcfd;
}
.selector-close__3jzZb {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 8px;
  overflow: hidden;
}
.selector-close__3jzZb:before,
.selector-close__3jzZb:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 8px;
  top: 50%;
  left: 8px;
  margin-top: -1px;
  background-color: #7b8995;
}
.selector-close__3jzZb:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.selector-close__3jzZb:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.selector-input_wrapper__2lODl {
  position: relative;
}
.selector-input_wrapper__2lODl.selector-selected__3huPj .selector-add__2RCe7 {
  position: absolute;
  top: 11px;
  right: 11px;
  width: 16px;
  height: 16px;
  background-color: #4ea6ff;
  border-radius: 3px;
  z-index: 1;
  cursor: pointer;
}
.selector-input_wrapper__2lODl.selector-selected__3huPj .selector-add__2RCe7:before,
.selector-input_wrapper__2lODl.selector-selected__3huPj .selector-add__2RCe7:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 8px;
  margin-left: -4px;
  top: 50%;
  left: 8px;
  margin-top: -1px;
  background-color: #fff;
}
.selector-input_wrapper__2lODl.selector-selected__3huPj .selector-add__2RCe7:before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
