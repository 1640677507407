.hash-tag-list-item-data__3YmoQ:hover .hash-tag-list-item-arrow__D4DNR {
  border-color: #53a8ff;
}
.hash-tag-list-item-data_not_system__1Jm9T {
  background-color: rgba(178,178,178,0.2);
  color: rgba(71,71,91,0.7);
}
.hash-tag-list-item-data_not_system__1Jm9T:hover .hash-tag-list-item-arrow__D4DNR {
  border-color: #ccc;
}
.hash-tag-list-item-data_duplicate__2y2dW {
  background-color: #f3f8fd;
}
.hash-tag-list-item-data__3YmoQ {
  padding: 12px 15px;
}
.hash-tag-list-item-input_container__2tBDC {
  padding: 10px 15px;
  cursor: auto;
}
.hash-tag-list-item-input_category__17K16 {
  margin-top: 5px;
}
.hash-tag-list-item-input__27yhD {
  width: 375px;
}
.hash-tag-list-item-input__27yhD:focus {
  outline: none;
}
.hash-tag-list-item-group_contains__2tmW- {
  display: inline-block;
  width: 14px;
  padding: 1px 0;
  text-align: center;
  font-size: 12px;
  margin-right: 7px;
  background-color: #afafb8;
  border-radius: 50px;
  color: #fff;
  vertical-align: top;
}
.hash-tag-list-item-checkbox_container__2geUF {
  display: inline-block;
  margin-top: -1px;
  margin-left: 7px;
  float: right;
}
.hash-tag-list-item-arrow__D4DNR {
  display: inline-block;
  position: absolute;
  width: 7.5px;
  height: 7.5px;
  border-color: #ccc;
  border-style: solid;
  border-top-width: 1px;
  border-right-width: 1px;
  border-left-width: 0px;
  border-bottom-width: 0px;
  -webkit-transform: rotate(45deg) translate(0, -50%);
          transform: rotate(45deg) translate(0, -50%);
  right: 20px;
  top: 50%;
}
.hash-tag-list-item-flag_h__HP9T6 {
  display: inline-block;
  position: absolute;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: 55px;
  top: 50%;
  width: 14px;
  height: 13px;
}
.hash-tag-list-item-flag_s__1Ih-D {
  display: inline-block;
  position: absolute;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: 75px;
  top: 50%;
  width: 14px;
  height: 13px;
}
.hash-tag-list-item-flag_c__1sPJo {
  display: inline-block;
  position: absolute;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: 95px;
  top: 50%;
  width: 14px;
  height: 13px;
}
.hash-tag-list-item-flag_d__3B5XR {
  display: inline-block;
  position: absolute;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: 115px;
  top: 50%;
  width: 14px;
  height: 13px;
}
.hash-tag-list-item-edit__3xZ3C {
  display: inline-block;
  position: absolute;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: 55px;
  top: 50%;
  width: 14px;
  height: 13px;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3EC9A55290-F039-4146-93DA-718D0AFF8835%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='Operator_edit_hover' transform='translate(-467.000000, -123.000000)' stroke='%2353A8FF'%3E %3Cg id='Page-1' transform='translate(468.000000, 124.000000)'%3E %3Cpath d='M5.02205217,14.0985043 L0.695095652,15.6769391 C0.488486957,15.7520696 0.288834783,15.5517217 0.363965217,15.3458087 L1.9424,11.0188522 L12.1740522,0.7872 C12.7597913,0.20146087 13.7093565,0.20146087 14.2950957,0.7872 L15.2537043,1.7458087 C15.8394435,2.33154783 15.8394435,3.28111304 15.2537043,3.86685217 L5.02205217,14.0985043 Z' id='Stroke-1'%3E%3C/path%3E %3Cpath d='M10.2670609,2.69405217 L13.346713,5.77370435' id='Stroke-3'%3E%3C/path%3E %3Cpath d='M11.0022261,1.95888696 L14.0818783,5.03853913' id='Stroke-5'%3E%3C/path%3E %3Cpath d='M2.00723478,10.9538783 L5.08688696,14.0335304' id='Stroke-7'%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
}
.hash-tag-list-item-apply__LAMaT,
.hash-tag-list-item-cancel__15PFv {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  float: right;
  cursor: pointer;
  border-radius: 3px;
}
.hash-tag-list-item-apply__LAMaT {
  background-color: #56aafc;
}
.hash-tag-list-item-apply_icon__aWEHF {
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3E220E13FF-1AA8-4390-9878-12EE97A02E45%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='QA-dashboard_bad-mark' transform='translate(-96.000000, -214.000000)'%3E %3Cg id='list' transform='translate(83.000000, 84.000000)'%3E %3Cg id='Group-32'%3E %3Cg id='Group-31'%3E %3Cg transform='translate(0.000000, 119.000000)'%3E %3Cg id='Group-28' transform='translate(13.000000, 11.000000)'%3E %3Crect id='Rectangle-18' fill='%2356AAFC' x='0' y='0' width='18' height='18' rx='2'%3E%3C/rect%3E %3Cpolyline id='Path-4' stroke='%23FFFFFF' stroke-width='2' points='4.71428571 8.33116883 8.06493506 11.6818182 13.9285714 5.81818182'%3E%3C/polyline%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  width: 100%;
  height: 100%;
}
.hash-tag-list-item-cancel__15PFv {
  margin-left: 10px;
  border: 1px solid #56aafc;
}
.hash-tag-list-item-cancel_icon__2n7kI {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid' width='12' height='12' viewBox='0 0 16 16'%3E %3Cdefs%3E %3Cstyle%3E .cls-1 %7B fill: %23f26d5b; fill-rule: evenodd; %7D %3C/style%3E %3C/defs%3E %3Cpath d='M16.003,2.733 L13.267,-0.003 L8.001,5.262 L2.736,-0.003 L-0.000,2.733 L5.265,7.998 L-0.000,13.263 L2.736,15.999 L8.001,10.734 L13.267,15.999 L16.003,13.263 L10.737,7.998 L16.003,2.733 Z' class='cls-1'/%3E %3C/svg%3E");
  width: 10px;
  height: 10px;
}
.hash-tag-list-item-apply_icon__aWEHF,
.hash-tag-list-item-cancel_icon__2n7kI {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-size: cover;
}
.hash-tag-list-item-delete__jLeDz {
  display: inline-block;
  position: absolute;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: 35px;
  top: 50%;
  width: 14px;
  height: 13px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid' width='12' height='12' viewBox='0 0 16 16'%3E %3Cdefs%3E %3Cstyle%3E .cls-1 %7B fill: %23f26d5b; fill-rule: evenodd; %7D %3C/style%3E %3C/defs%3E %3Cpath d='M16.003,2.733 L13.267,-0.003 L8.001,5.262 L2.736,-0.003 L-0.000,2.733 L5.265,7.998 L-0.000,13.263 L2.736,15.999 L8.001,10.734 L13.267,15.999 L16.003,13.263 L10.737,7.998 L16.003,2.733 Z' class='cls-1'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
}
.hash-tag-list-item-edit_tag__1snP9 {
  right: 35px;
}
.hash-tag-list-item-group_flag_h__JVqNQ {
  right: 75px;
}
.hash-tag-list-item-group_flag_s__3JHBr {
  right: 95px;
}
.hash-tag-list-item-group_flag_c___61la {
  right: 115px;
}
.hash-tag-list-item-delete_tag__3HLWq {
  right: 10px;
}
.hash-tag-list-item-tag_name__lRejU,
.hash-tag-list-item-group_name__3IMWE {
  display: inline-block;
  overflow: hidden;
  word-break: break-word;
}
.hash-tag-list-item-tag_name__lRejU {
  max-width: 185px;
}
.hash-tag-list-item-tag_category__3CEsZ {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 4px;
}
.hash-tag-list-item-tag_preinstalled__3AEij {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 4px;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3E220E13FF-1AA8-4390-9878-12EE97A02E45%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='QA-dashboard_bad-mark' transform='translate(-96.000000, -214.000000)'%3E %3Cg id='list' transform='translate(83.000000, 84.000000)'%3E %3Cg id='Group-32'%3E %3Cg id='Group-31'%3E %3Cg transform='translate(0.000000, 119.000000)'%3E %3Cg id='Group-28' transform='translate(13.000000, 11.000000)'%3E %3Crect id='Rectangle-18' fill='%2356AAFC' x='0' y='0' width='18' height='18' rx='2'%3E%3C/rect%3E %3Cpolyline id='Path-4' stroke='%23FFFFFF' stroke-width='2' points='4.71428571 8.33116883 8.06493506 11.6818182 13.9285714 5.81818182'%3E%3C/polyline%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
}
.hash-tag-list-item-checkbox_preinstalled__3IZRv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  margin-top: 8px;
}
.hash-tag-list-item-checkbox_preinstalled_label__2EFQh {
  padding-left: 5px;
}
.hash-tag-list-item-group_name__3IMWE {
  max-width: 375px;
}
.hash-tag-list-item-tag_name_editable__1HAZ4 {
  max-width: 375px;
}
.hash-tag-list-item-group_name_editable__25-Zv {
  max-width: 375px;
}
.hash-tag-list-item-tag_target_loading__2-Acp {
  width: 16px;
  height: 16px;
}
.hash-tag-list-item-dropable__3QjER {
  background-color: #e0ffe7;
}
.hash-tag-list-item-hide__1LcAC {
  opacity: 0;
}
.hash-tag-list-item-search__YOqQY {
  background: #ff0;
}
