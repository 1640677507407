.channels-header__11c6b {
  position: relative;
  height: 57px;
  background-color: #e6eaef;
  overflow: visible;
  z-index: 1;
}
.channels-title__ZWQz7 {
  font-size: 20px;
  color: #fff;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  padding-left: 20px;
}
.channels-buttons__27BKq {
  padding: 14px 10px 0;
}
.channels-button-base__165Ng {
  border: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 2px;
}
.channels-button-base__165Ng:focus {
  outline: none;
}
.channels-button-default__23HE0,
.channels-button_add__17YB3 {
  padding: 0 14px;
  height: 32px;
  line-height: 33px;
}
.channels-button-default__23HE0.channels-only_icon__8CP9j,
.channels-button_add__17YB3.channels-only_icon__8CP9j {
  font-size: 18px;
  width: 36px;
}
.channels-button-default__23HE0 .channels-badge__29l1B,
.channels-button_add__17YB3 .channels-badge__29l1B {
  height: 16px;
  line-height: 16px;
}
.channels-button-small__JekSH {
  padding: 0 13px;
  text-transform: uppercase;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.channels-button-small__JekSH.channels-only_icon__8CP9j {
  padding-top: 9px;
  font-size: 13px;
  width: 28px;
}
.channels-button-extra-small__2uMq9 {
  padding: 0 6px;
  text-transform: uppercase;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
}
.channels-button-primary__3Yw3t,
.channels-button_add__17YB3 {
  background-color: #188fea;
  color: #fff;
}
.channels-button-primary__3Yw3t:hover,
.channels-button_add__17YB3:hover {
  background-color: #0a71bf;
}
.channels-button-light__1JuR_ {
  background-color: #fff;
  color: #0a71bf;
}
.channels-button-transparent__3Uij1 {
  background-color: none;
  color: #fff;
  border: 1px solid #fff;
}
.channels-button-transparent__3Uij1:hover {
  background-color: #fff;
  color: #0a71bf;
}
.channels-button-danger__2Acch {
  background-color: #d85151;
  color: #fff;
}
.channels-button-danger__2Acch:hover {
  background-color: #c13030;
}
.channels-button-full__3vz20 {
  width: 100%;
  text-align: center;
}
.channels-button-secondary__2nR5H {
  background-color: #aaa;
  color: #fff;
  font-weight: bold;
}
.channels-button-secondary__2nR5H:hover {
  background-color: #0a71bf;
}
.channels-button-tertiary__-hCgF {
  color: #aaa;
  font-weight: bold;
}
.channels-button-tertiary__-hCgF:hover {
  color: #0a71bf;
}
.channels-button-dark__1MjRb {
  background-color: #0a71bf;
  color: #fff;
}
.channels-button-dark__1MjRb:hover {
  background-color: #005fa7;
}
.channels-button-only-icon__2TVbw {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.channels-button-icon-big__DWY2B {
  font-size: 18px;
  width: 38px;
}
.channels-button-icon__2v7c- {
  margin-right: 5px;
}
.channels-button-bold__3BJIe {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.channels-button-float-right__NDpkv {
  float: right;
}
.channels-button-with-hr__1xp6B {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 0;
}
.channels-button-with-hr__1xp6B:after {
  display: block;
  position: relative;
  bottom: 15px;
  content: '';
  border-bottom: 1px solid #c1cdd6;
  z-index: -1;
}
.channels-button-processing__21IdY {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
}
.channels-button-invisible__2X1ud {
  visibility: hidden;
}
.channels-button-tooltip__1uyuD {
  top: 38px;
  left: 50%;
  margin-left: -75px;
  color: #fff;
  width: 150px;
  font-size: 13px;
  text-align: center;
  text-transform: initial;
  position: absolute;
  padding: 5px;
  z-index: 2;
  background-color: #3e434d;
  height: 26px;
  line-height: 16px;
  opacity: 0.8;
  border-radius: 2px;
}
.channels-button-tooltip__1uyuD:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-bottom-color: #3e434d;
  border-width: 5px;
  margin-left: -5px;
}
.channels-button-badge__1Y2WO {
  padding: 0px 7px;
  min-width: 10px;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #f86e5f;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 40px;
}
.channels-button_add__17YB3 {
  display: inline-block;
  float: right;
}
