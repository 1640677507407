.customer-accordion-by-text-common_actions__-k0Wd {
  display: inline-block;
  margin-left: 30px;
}
.customer-accordion-by-text-action_button__1m7Ho {
  border: 0;
  background-color: #f6f6f6;
  padding: 5px 20px;
  text-indent: -9999px;
  background-position: center;
  background-size: 23px;
  background-repeat: no-repeat;
  width: 30px;
  margin: 0 8px;
  border-radius: 3px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  display: inline-block;
}
.customer-accordion-by-text-action_button__1m7Ho:hover {
  background-color: #eee;
}
.customer-accordion-by-text-action_button_pause__3r1wK {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='100px' height='100px' viewBox='0 0 100 100' enable-background='new 0 0 100 100' xml:space='preserve' fill='%2347475b'%3E %3Cg%3E %3Cpath d='M40.531,19.959c-0.097,0-0.19,0.016-0.283,0.029v-0.029H29.912h-0.001c-1.194,0-2.163,0.968-2.163,2.163 c0,0.002,0,0.003,0,0.005h-0.001V77.87h0.001c0,0.003-0.001,0.006-0.001,0.009c0,1.194,0.969,2.162,2.163,2.162h0h0H40.53h0 c1.194,0,2.162-0.968,2.162-2.162c0-0.003-0.001-0.006-0.001-0.009h0.001V22.127h0c0-0.002,0.001-0.004,0.001-0.005 C42.693,20.927,41.724,19.959,40.531,19.959z'/%3E %3Cpath d='M72.251,77.87V22.127h0c0-0.002,0.001-0.004,0.001-0.005c0-1.195-0.969-2.163-2.162-2.163c-0.097,0-0.19,0.016-0.283,0.029 v-0.029H59.47H59.47c-1.194,0-2.163,0.968-2.163,2.163c0,0.002,0,0.003,0,0.005h-0.001V77.87h0.001 c0,0.003-0.001,0.006-0.001,0.009c0,1.194,0.969,2.162,2.163,2.162h0h0h10.618h0c1.194,0,2.162-0.968,2.162-2.162 C72.251,77.876,72.25,77.873,72.251,77.87L72.251,77.87z'/%3E %3C/g%3E %3C/svg%3E");
}
.customer-accordion-by-text-action_button_delete__2pi0c {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='100px' height='100px' viewBox='0 0 100 100' enable-background='new 0 0 100 100' xml:space='preserve' fill='%23f00'%3E %3Cg%3E %3Cg%3E %3Cpath d='M75.834,33.388h-51.67c-1.311,0-2.375,1.058-2.375,2.373v49.887c0,1.314,1.064,2.377,2.375,2.377h51.67 c1.314,0,2.375-1.063,2.375-2.377V35.76C78.209,34.446,77.148,33.388,75.834,33.388z'/%3E %3C/g%3E %3Cg%3E %3Cpath d='M79.004,17.352H59.402v-2.999c0-1.314-1.061-2.377-2.373-2.377H42.971c-1.312,0-2.375,1.063-2.375,2.377v2.999H20.996 c-1.312,0-2.375,1.059-2.375,2.373v6.932c0,1.314,1.063,2.373,2.375,2.373h58.008c1.314,0,2.375-1.059,2.375-2.373v-6.932 C81.379,18.41,80.318,17.352,79.004,17.352z'/%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
}
.customer-accordion-by-text-title_text__1NUob {
  max-width: 700px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
}
