.scheduled-item-container__1db3w {
  display: block;
  border-radius: 3px;
  border: solid 1px #e6e6f2;
  margin: 15px;
}
.scheduled-item-header__3HOLP {
  height: 55px;
  border-radius: 3px 3px 0 0;
  background-color: #fafafc;
  padding: 15px 20px;
  cursor: pointer;
}
.scheduled-item-text_block__3vZvF {
  display: block;
  background: #fff;
  color: #323d49;
  font-size: 15px;
  height: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: justify;
  padding: 15px 20px;
  border-radius: 0 0 3px 3px;
}
