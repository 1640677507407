.index-textarea__1w-UO {
  width: 249px;
  height: 177px;
  border: 1px solid #e6eaef;
  border-radius: 4px;
  padding: 10px 10px 10px 12px;
  font-size: 14px;
  line-height: 22px;
  resize: none;
  margin-top: 8px;
  outline: none;
}
.index-textarea__1w-UO:placeholder {
  color: #aab6c8;
}
.index-textarea__1w-UO.index-textarea_error__z0rLg {
  border-color: #eb5757;
}
