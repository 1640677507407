.qa-dashboard-content__1jSJa {
  width: 100%;
  height: 100%;
  background-color: #fff;
  min-width: 1125px;
}
.qa-dashboard-table__hQ1WG {
  padding: 18px 16px;
  overflow: auto;
  height: calc(100% - 56px);
}
