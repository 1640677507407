.styles-container__3jNPg {
  font-family: Hind;
  font-size: 14px;
  color: #47475b;
  margin: 15px;
  border-radius: 3px;
  height: calc(100% - 30px);
  border: solid 1px #b9bcc1;
  overflow: auto;
}
.styles-section__jNwl- {
  display: inline-block;
  max-width: 500px;
  vertical-align: top;
  margin: 15px 50px 15px 15px;
}
.styles-section_title__njnqC {
  font-size: 16px;
}
.styles-label__2Xv9j {
  display: block;
}
.styles-input__25GrJ {
  width: 100%;
  padding: 9px 7px;
  border-radius: 3px;
  border: solid 1px #b9bcc1;
  resize: vertical;
}
.styles-input__25GrJ:focus {
  outline: none;
}
.styles-error__mPhRr {
  display: block;
  color: #f87b6e;
  font-size: 13px;
}
.styles-submit_container__ywYac {
  text-align: center;
  overflow: hidden;
  margin: 15px 0;
}
.styles-edit_container__3SdiN {
  margin-top: 20px;
}
.styles-tags_source__2Hpeo {
  margin-top: 10px;
  text-align: left;
}
.styles-tags_source_container__2yMfN {
  display: inline-block;
  cursor: pointer;
}
.styles-tags_source_text__52h92 {
  display: inline-block;
  vertical-align: super;
  margin-left: 10px;
}
