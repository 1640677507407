.search-input-input-default__2Sqv0,
.search-input-input__3h3Zk {
  height: 40px;
  border: 1px solid #e2e6ed;
  padding: 10px 14px;
  white-space: nowrap;
}
.search-input-input-default__2Sqv0:focus,
.search-input-input__3h3Zk:focus {
  outline: none;
  border-color: #0a71bf;
}
.search-input-input-full__nZob2 {
  width: 100%;
}
.search-input-input-no-border__2ETtT {
  border: none;
}
.search-input-input-rounded__3Yykh,
.search-input-input__3h3Zk {
  border-radius: 2px;
}
.search-input-input-with-address__17a5L {
  width: 100%;
  white-space: nowrap;
}
.search-input-input-address__2dOkG {
  padding-right: 111px;
}
.search-input-input-address-name__2X9zd {
  position: relative;
  color: #333;
  margin-left: -97px;
  padding: 10px 14px 10px 14px;
  width: 80px;
  border-left: 1px solid #c1cdd6;
}
.search-input-input-field__1-Sle {
  white-space: nowrap;
  margin-bottom: 20px;
}
.search-input-input-fielded__2xYW6 {
  padding-right: calc(25% + 28px);
}
.search-input-input-textarea__2sjMf {
  white-space: normal;
}
.search-input-input-button__11JYz {
  position: relative;
  display: inline-block;
  background-color: #9eaab9;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  height: 24px;
  line-height: 24px;
  width: 25%;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
  margin-left: -14px;
}
.search-input-input-base__1bty2 {
  width: 300px;
  display: inline-block;
  vertical-align: top;
  border: 1px solid #e5e9ed;
  border-radius: 3px;
}
.search-input-root__3lmmU {
  position: relative;
}
.search-input-root__3lmmU.search-input-root_show_clear_cross__1suOM .search-input-clear_cross__hUuUA {
  display: inline-block;
  vertical-align: middle;
}
.search-input-root__3lmmU.search-input-root_show_clear_cross__1suOM .search-input-input__3h3Zk {
  padding-right: 30px;
}
.search-input-root__3lmmU.search-input-enable_type_choosing__Zk82W .search-input-input__3h3Zk {
  padding-left: 72px;
  background-position-x: 45px;
}
.search-input-input__3h3Zk {
  width: 100%;
  height: 36px;
  padding-right: 10px;
  margin-right: -25px;
  border: none;
  padding-left: 37px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid' width='17' height='18' viewBox='0 0 33 34'%3E %3Cdefs%3E %3Cstyle%3E .cls-1 %7B fill: %2347475b; opacity: 0.8; fill-rule: evenodd; %7D %3C/style%3E %3C/defs%3E %3Cpath d='M33.006,32.202 L25.267,24.489 C27.713,21.884 29.223,18.397 29.223,14.557 C29.223,6.527 22.668,-0.006 14.609,-0.006 C6.551,-0.006 -0.004,6.527 -0.004,14.557 C-0.004,22.587 6.551,29.120 14.609,29.120 C17.897,29.120 20.923,28.020 23.367,26.187 L31.205,33.997 L33.006,32.202 ZM2.544,14.557 C2.544,7.927 7.957,2.533 14.609,2.533 C21.263,2.533 26.675,7.927 26.675,14.557 C26.675,21.187 21.263,26.581 14.609,26.581 C7.957,26.581 2.544,21.187 2.544,14.557 Z' class='cls-1'/%3E %3C/svg%3E") no-repeat left 10px center #fff;
}
.search-input-input__3h3Zk::-webkit-input-placeholder {
  color: #a9b5c9;
  font-weight: 400;
  font-size: 13px;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.search-input-input__3h3Zk:focus::-webkit-input-placeholder {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.search-input-input__3h3Zk:focus {
  outline: none;
}
.search-input-clear_cross__hUuUA {
  display: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid' width='16' height='16' viewBox='0 0 33 33'%3E %3Cdefs%3E %3Cstyle%3E .cls-1 %7B fill: %23e5e9ed; fill-rule: evenodd; %7D %3C/style%3E %3C/defs%3E %3Cpath d='M16.500,-0.000 C7.387,-0.000 -0.001,7.387 -0.001,16.500 C-0.001,25.613 7.387,33.000 16.500,33.000 C25.613,33.000 33.000,25.613 33.000,16.500 C33.000,7.387 25.613,-0.000 16.500,-0.000 ZM25.200,22.731 L22.225,25.707 L16.500,19.981 L10.774,25.707 L7.799,22.731 L13.525,17.006 L7.799,11.280 L10.774,8.305 L16.500,14.031 L22.225,8.305 L25.200,11.280 L19.475,17.006 L25.200,22.731 Z' class='cls-1'/%3E %3C/svg%3E") no-repeat center;
}
.search-input-type_select__3tLZO {
  font-family: Hind;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #47475b;
  padding-bottom: 4px;
  display: inline-block;
  position: absolute;
  left: 5px;
  top: 11px;
  width: 30px;
}
.search-input-options_container__2EH3Y {
  width: 110px;
}
