.list-item-container__3t71n {
  display: block;
  border-bottom: 1px solid #e6eaef;
  padding: 20px 15px 20px 30px;
  background-color: #fff;
  text-decoration: none;
  position: relative;
}
.list-item-container__3t71n:hover {
  background-color: #fdfdfd;
}
.list-item-selected__uFV1d {
  background: #f2f5f9;
}
.list-item-selected__uFV1d:hover {
  background-color: #f2f5f9;
}
.list-item-main_value__12Ivc {
  color: #57697b;
  font-weight: 600;
}
.list-item-disabled__3PwrS {
  cursor: not-allowed;
}
.list-item-multiselected_enabled__1iqiF {
  padding: 20px 20px 20px 50px;
}
.list-item-multiselected_enabled__1iqiF:before {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #dee3ea;
  position: absolute;
  left: 10px;
  top: 50%;
  margin: -10px 0 0 0;
}
.list-item-multiselected__3K9_p:before {
  content: '✓';
  text-align: center;
  line-height: 20px;
  font-size: 9px;
  color: #fff;
  background: #44adff;
  border: 1px solid #44adff;
}
.list-item-span__3DCsF {
  padding-right: 10px;
}
