.list-item-container__1Smft {
  display: block;
  text-decoration: none;
  width: 100%;
  padding: 15px 22px 12px 33px;
}
.list-item-container__1Smft + .list-item-container__1Smft {
  border-top: solid 1px #e6eaef;
}
.list-item-name_value__2l91q {
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  color: #47475b;
}
.list-item-merged_value__2nLT1 {
  display: inline-block;
  float: right;
  font-size: 12px;
  font-weight: 500;
  color: #a9b5c9;
}
.list-item-selected__1qyQK {
  background: #f2f5f9;
}
.list-item-status_marker__1O1j7 {
  display: block;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  right: 20px;
  top: 10px;
}
.list-item-status_marker__1O1j7.list-item-marker_status_new_message__32lkF {
  background: #76d466;
}
.list-item-status_marker__1O1j7.list-item-marker_status_opened__3jxeB {
  background: #53a8ff;
}
.list-item-status_marker__1O1j7.list-item-marker_status_closed__3XB6G {
  background: #999;
}
.list-item-status_marker__1O1j7.list-item-marker_status_dormant__3LZa8 {
  background: #ff0;
}
