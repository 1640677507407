.reply-templates-manager-container_message__21vPg {
  position: absolute;
  bottom: calc(100% + 10px);
  left: -13;
  z-index: 4;
  width: 100%;
}
.reply-templates-manager-container_message__21vPg.reply-templates-manager-templates__1QrUQ {
  bottom: 181px;
}
.reply-templates-manager-content__3Db9C {
  height: 355px;
  background-color: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);
  border: solid 1px #cdd0d3;
  overflow: hidden;
  white-space: nowrap;
}
.reply-templates-manager-container_ranking__1HWCi {
  width: 100%;
}
.reply-templates-manager-arrow_message__3_yhM {
  position: absolute;
  top: 100%;
  left: 63px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #cdd0d3;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  background-color: #f8fafc;
  margin-top: -5px;
}
.reply-templates-manager-arrow_ranking__zt_CT {
  position: absolute;
  bottom: 22;
  right: -4;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: #cdd0d3;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  background-color: #f8fafc;
  margin-top: -5px;
}
