.system-attention-container__3OXj4 {
  border: 1px solid #53a8ff;
  border-radius: 3px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 300px;
  text-align: center;
  padding: 15px;
  background: #fff;
  z-index: 50;
}
.system-attention-text__21jrE {
  display: block;
  margin-bottom: 20px;
  font-family: Hind;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  color: #53a8ff;
}
.system-attention-button__1Wibj {
  border-radius: 2px;
  border: solid 1px #56aafc;
  font-family: Hind;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  vertical-align: top;
  margin-left: 10px;
  padding: 8px 0 7px;
  background-color: #53a8ff;
  color: #fff;
  padding: 7px 15px;
}
.system-attention-button__1Wibj:active,
.system-attention-button__1Wibj:focus {
  outline: none;
}
.system-attention-button__1Wibj:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
