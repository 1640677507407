.additions-container__2-F-T {
  margin-top: 12px;
  opacity: 0.4;
  font-size: 12px;
  font-weight: 600;
  color: #47475b;
}
.additions-status_progress__dU5kf {
  color: #47475b;
  opacity: 0.8;
}
.additions-status_timer__3Qw_C {
  opacity: 0.5;
  float: right;
  color: #57697b;
  font-weight: 500;
}
.additions-status_text__76B61 {
  display: inline-block;
  overflow: hidden;
  word-break: break-all;
}
.additions-status_text_progress__o--rP {
  max-width: 165px;
}
