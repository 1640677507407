.categories-section-categories__16KnN {
  width: 240px;
  height: 100%;
  background-color: #f8fafc;
}
.categories-section-input_field__2sYpM {
  border-radius: 3px;
  width: 203px;
  background-color: #fff;
  border: solid 1px #a9b5c9;
  font-size: 15px;
  color: #47475b;
  padding: 8px;
  margin: 0 15px 0 7px;
}
.categories-section-input_field__2sYpM:focus,
.categories-section-input_field__2sYpM:active {
  outline: none;
}
.categories-section-input_field_edit__7i8xN {
  margin-right: 0;
}
.categories-section-options__3EV17 {
  padding: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.categories-section-options__3EV17 .categories-section-label__34Whp {
  margin: 0 10px;
}
.categories-section-button__YrVLR {
  margin-left: 8px;
}
.categories-section-delete__gpWpr {
  margin-left: 10px;
}
.categories-section-delete__gpWpr:hover svg * {
  stroke: #fc6453;
}
.categories-section-delete_popup__BWed3 {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: calc(50% - 8px);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.categories-section-edit__2PEAM svg * {
  stroke: #4a4849;
}
.categories-section-edit__2PEAM:hover svg * {
  stroke: #ff9d00;
}
.categories-section-category_controls__EpuNp {
  display: none;
  margin-top: -10px;
  position: absolute;
  right: 5px;
  top: 50%;
}
.categories-section-category__3YUdl {
  position: relative;
}
.categories-section-category__3YUdl:hover .categories-section-category_controls__EpuNp {
  display: inline-block;
}
.categories-section-category__3YUdl:hover .categories-section-personal__1_FMi {
  background-image: url(/6a578dd1aba2ddd1185883a7b9c12bfa.png);
}
.categories-section-personal__1_FMi {
  padding-left: 20px;
  background-size: 11px 12px;
  background-repeat: no-repeat;
  background-image: url(/afae74931909f903613d6619539f53ee.png);
}
.categories-section-personal_selected__2tD7s {
  background-image: url(/6a578dd1aba2ddd1185883a7b9c12bfa.png);
}
.categories-section-pagination__1CBnX {
  text-align: center;
}
