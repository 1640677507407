.hash-tags-input-default__1hUdY {
  height: 40px;
  border: 1px solid #e2e6ed;
  padding: 10px 14px;
  white-space: nowrap;
}
.hash-tags-input-default__1hUdY:focus {
  outline: none;
  border-color: #0a71bf;
}
.hash-tags-input-full__33iCk {
  width: 100%;
}
.hash-tags-input-no-border__384nD {
  border: none;
}
.hash-tags-input-rounded__2U9CQ {
  border-radius: 2px;
}
.hash-tags-input-with-address__3JjEz {
  width: 100%;
  white-space: nowrap;
}
.hash-tags-input-address__3tbzs {
  padding-right: 111px;
}
.hash-tags-input-address-name__29uP4 {
  position: relative;
  color: #333;
  margin-left: -97px;
  padding: 10px 14px 10px 14px;
  width: 80px;
  border-left: 1px solid #c1cdd6;
}
.hash-tags-input-field__2tmwm {
  white-space: nowrap;
  margin-bottom: 20px;
}
.hash-tags-input-fielded__2aOUC {
  padding-right: calc(25% + 28px);
}
.hash-tags-input-textarea__3v8PC {
  white-space: normal;
}
.hash-tags-input-button__362Wb {
  position: relative;
  display: inline-block;
  background-color: #9eaab9;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  height: 24px;
  line-height: 24px;
  width: 25%;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
  margin-left: -14px;
}
.hash-tags-input-base__3V3YZ,
.hash-tags-search_tags__VDERs {
  width: 300px;
  display: inline-block;
  vertical-align: top;
  border: 1px solid #e5e9ed;
  border-radius: 3px;
}
.hash-tags-container__3UJva {
  position: relative;
  display: inline-block;
  width: 500px;
  font-family: Hind;
  font-size: 14px;
  color: #47475b;
  min-height: 44px;
  background-color: #fff;
  -webkit-box-shadow: inset 0 -1px 0 0 #e6eaef;
          box-shadow: inset 0 -1px 0 0 #e6eaef;
  border: solid 1px #b9bcc1;
  border-radius: 3px;
  z-index: 3;
}
.hash-tags-container_view__3izyK {
  width: 250px;
}
.hash-tags-back__KUFvT {
  position: relative;
  cursor: pointer;
  border-bottom: solid 1px #b9bcc1;
  padding: 12px 12px 12px 32px;
  font-weight: bold;
  overflow: hidden;
}
.hash-tags-back__KUFvT:hover,
.hash-tags-back_selected__3Vsuz {
  background-color: rgba(241,243,246,0.5);
}
.hash-tags-back_maintitle__2uUf3 {
  display: inline-block;
  max-width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  float: right;
}
.hash-tags-back_subtitle__20yqp {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  font-weight: normal;
}
.hash-tags-arrow__2geVu {
  display: inline-block;
  position: absolute;
  width: 7.5px;
  height: 7.5px;
  border-color: #ccc;
  border-style: solid;
  border-top-width: 1px;
  border-right-width: 1px;
  border-left-width: 0px;
  border-bottom-width: 0px;
  -webkit-transform: rotate(-135deg) translate(0, -50%);
          transform: rotate(-135deg) translate(0, -50%);
  left: 20px;
  top: calc(50% - 7px);
}
.hash-tags-back__KUFvT:hover .hash-tags-arrow__2geVu {
  border-color: #53a8ff;
}
.hash-tags-new__2B-fI {
  border-top: solid 1px #b9bcc1;
  padding: 12px;
  cursor: pointer;
}
.hash-tags-new__2B-fI:hover {
  background-color: rgba(241,243,246,0.5);
}
.hash-tags-position_center__1_clW {
  border-top: solid 1px #b9bcc1;
  text-align: center;
}
.hash-tags-search_tags__VDERs {
  width: 100%;
  border: none;
  border-bottom: 1px solid #e5e9ed;
  border-radius: 0;
}
