.index-header__3Az1V {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 8px;
}
.index-header--loading__PhGob {
  opacity: 0.5;
  pointer-events: none;
}
.index-header__3Az1V .index-header_collapse_item__A-epr {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.index-header__3Az1V .index-header_collapse_item__arrow__Q6S74 {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z' fill='%232C2D40'/%3E%3C/svg%3E%0A");
  width: 24px;
  height: 24px;
  display: block;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.index-header__3Az1V .index-header_collapse_item__arrow--open__3y-kF {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
.index-header__3Az1V .index-header_collapse_item__message_text__3OeAW {
  color: #2c2d40;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.index-header__3Az1V .index-header_collapse_item__message_text--long__3Z1GZ {
  max-width: 240px;
}
.index-header__3Az1V .index-header_menu_icon__SxTej {
  position: relative;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z' fill='%23AAB6C8'/%3E%3C/svg%3E%0A");
}
.index-header__3Az1V .index-header_menu_icon__SxTej .index-header_menu_menu__27goS {
  position: absolute;
  right: 0;
  top: 100%;
  background: #fff;
  border: 1px solid #e6eaef;
  -webkit-filter: drop-shadow(0px 4px 5px rgba(0,0,0,0.05));
          filter: drop-shadow(0px 4px 5px rgba(0,0,0,0.05));
  border-radius: 4px;
  min-width: 111px;
  z-index: 1;
}
.index-header__3Az1V .index-header_menu_icon__SxTej .index-header_menu_menu__item__3HPdg {
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  padding: 8px 12px;
  font-style: normal;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.index-header__3Az1V .index-header_menu_icon__SxTej .index-header_menu_menu__item__3HPdg:hover {
  background-color: #f8f8f8;
}
.index-header__3Az1V .index-header_menu_icon__SxTej .index-header_menu_menu__item--disable__3e9uK {
  cursor: not-allowed;
  opacity: 0.5;
}
.index-header__3Az1V .index-header_menu_icon__SxTej .index-header_menu_menu__item--disable__3e9uK:hover {
  background-color: #fff;
}
