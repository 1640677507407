.message-form-button-base__1N1WS {
  border: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 2px;
}
.message-form-button-base__1N1WS:focus {
  outline: none;
}
.message-form-button-default__zHEjl,
.message-form-submit__2gbat {
  padding: 0 14px;
  height: 32px;
  line-height: 33px;
}
.message-form-button-default__zHEjl.message-form-only_icon__1mv9H,
.message-form-submit__2gbat.message-form-only_icon__1mv9H {
  font-size: 18px;
  width: 36px;
}
.message-form-button-default__zHEjl .message-form-badge__1r5Qs,
.message-form-submit__2gbat .message-form-badge__1r5Qs {
  height: 16px;
  line-height: 16px;
}
.message-form-button-small__1yuY_ {
  padding: 0 13px;
  text-transform: uppercase;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.message-form-button-small__1yuY_.message-form-only_icon__1mv9H {
  padding-top: 9px;
  font-size: 13px;
  width: 28px;
}
.message-form-button-extra-small__1VxBz {
  padding: 0 6px;
  text-transform: uppercase;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
}
.message-form-button-primary__1aRnB,
.message-form-submit__2gbat {
  background-color: #188fea;
  color: #fff;
}
.message-form-button-primary__1aRnB:hover,
.message-form-submit__2gbat:hover {
  background-color: #0a71bf;
}
.message-form-button-light__YH8-y {
  background-color: #fff;
  color: #0a71bf;
}
.message-form-button-transparent__2iB8l {
  background-color: none;
  color: #fff;
  border: 1px solid #fff;
}
.message-form-button-transparent__2iB8l:hover {
  background-color: #fff;
  color: #0a71bf;
}
.message-form-button-danger__1F1PS {
  background-color: #d85151;
  color: #fff;
}
.message-form-button-danger__1F1PS:hover {
  background-color: #c13030;
}
.message-form-button-full__33E39 {
  width: 100%;
  text-align: center;
}
.message-form-button-secondary__1urlo {
  background-color: #aaa;
  color: #fff;
  font-weight: bold;
}
.message-form-button-secondary__1urlo:hover {
  background-color: #0a71bf;
}
.message-form-button-tertiary__181HY {
  color: #aaa;
  font-weight: bold;
}
.message-form-button-tertiary__181HY:hover {
  color: #0a71bf;
}
.message-form-button-dark__1U5mB {
  background-color: #0a71bf;
  color: #fff;
}
.message-form-button-dark__1U5mB:hover {
  background-color: #005fa7;
}
.message-form-button-only-icon__3ioEt {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.message-form-button-icon-big__2d_uW {
  font-size: 18px;
  width: 38px;
}
.message-form-button-icon__pEKA4 {
  margin-right: 5px;
}
.message-form-button-bold__2LWXH {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.message-form-button-float-right__zu1VP {
  float: right;
}
.message-form-button-with-hr__2SZwQ {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 0;
}
.message-form-button-with-hr__2SZwQ:after {
  display: block;
  position: relative;
  bottom: 15px;
  content: '';
  border-bottom: 1px solid #c1cdd6;
  z-index: -1;
}
.message-form-button-processing__15Wqf {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
}
.message-form-button-invisible__FlLvH {
  visibility: hidden;
}
.message-form-button-tooltip__3kUOC {
  top: 38px;
  left: 50%;
  margin-left: -75px;
  color: #fff;
  width: 150px;
  font-size: 13px;
  text-align: center;
  text-transform: initial;
  position: absolute;
  padding: 5px;
  z-index: 2;
  background-color: #3e434d;
  height: 26px;
  line-height: 16px;
  opacity: 0.8;
  border-radius: 2px;
}
.message-form-button-tooltip__3kUOC:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-bottom-color: #3e434d;
  border-width: 5px;
  margin-left: -5px;
}
.message-form-button-badge__3rLxS {
  padding: 0px 7px;
  min-width: 10px;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #f86e5f;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 40px;
}
.message-form-input-default__2ltC_,
.message-form-text_area__1Fq5k {
  height: 40px;
  border: 1px solid #e2e6ed;
  padding: 10px 14px;
  white-space: nowrap;
}
.message-form-input-default__2ltC_:focus,
.message-form-text_area__1Fq5k:focus {
  outline: none;
  border-color: #0a71bf;
}
.message-form-input-full__3tZlI,
.message-form-text_area__1Fq5k {
  width: 100%;
}
.message-form-input-no-border__39INk {
  border: none;
}
.message-form-input-rounded__p02Cr {
  border-radius: 2px;
}
.message-form-input-with-address__2soQS {
  width: 100%;
  white-space: nowrap;
}
.message-form-input-address__pTYs_ {
  padding-right: 111px;
}
.message-form-input-address-name__1OQfS {
  position: relative;
  color: #333;
  margin-left: -97px;
  padding: 10px 14px 10px 14px;
  width: 80px;
  border-left: 1px solid #c1cdd6;
}
.message-form-input-field__2CFJW {
  white-space: nowrap;
  margin-bottom: 20px;
}
.message-form-input-fielded__1o38I {
  padding-right: calc(25% + 28px);
}
.message-form-input-textarea__327Kk {
  white-space: normal;
}
.message-form-input-button__3zuwE {
  position: relative;
  display: inline-block;
  background-color: #9eaab9;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  height: 24px;
  line-height: 24px;
  width: 25%;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
  margin-left: -14px;
}
.message-form-input-base__RtHCk {
  width: 300px;
  display: inline-block;
  vertical-align: top;
  border: 1px solid #e5e9ed;
  border-radius: 3px;
}
.message-form-text_area__1Fq5k {
  border: none;
  height: calc(100% - 50px);
  white-space: normal;
  resize: none;
  padding: 5px 40px 5px 10px;
  color: #47475b;
}
.message-form-text_area__1Fq5k::-webkit-input-placeholder {
  color: #616171;
  font-weight: 400;
  font-size: 13px;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.message-form-text_area__1Fq5k:focus::-webkit-input-placeholder {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.message-form-text_area__1Fq5k:focus {
  outline: none;
}
.message-form-counter__2CgC6 {
  position: absolute;
  top: 10px;
  right: 5px;
  color: #a9b5c9;
  font-size: 12px;
}
.message-form-status_autocomplete__2f5bH {
  display: block;
  position: absolute;
  top: 30px;
  right: 5px;
  color: #a9b5c9;
  font-size: 12px;
  cursor: pointer;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE4IDE0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzAwOUNGRiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTk4OCA2NDFjMi43NjEgMCA1IDIuMjM5IDUgNXMtMi4yMzkgNS01IDUtNS0yLjIzOS01LTUgMi4yMzktNSA1LTV6bTAgMmMtMS42NTcgMC0zIDEuMzQzLTMgM3MxLjM0MyAzIDMgMyAzLTEuMzQzIDMtMy0xLjM0My0zLTMtM3ptMC02YzMuNzQ1IDAgNyAxLjMwMyA4Ljc5MSAzLjYyMy4zMzguNDM3LjI1NyAxLjA2NS0uMTggMS40MDItLjQzNy4zMzgtMS4wNjUuMjU3LTEuNDAyLS4xOC0xLjM3NS0xLjc3OS00LjAzNy0yLjg0NS03LjIwOS0yLjg0NS0zLjE3MiAwLTUuODM0IDEuMDY2LTcuMjA5IDIuODQ1LS4zMzcuNDM3LS45NjUuNTE4LTEuNDAyLjE4LS40MzctLjMzNy0uNTE4LS45NjUtLjE4LTEuNDAyQzk4MSA2MzguMzAzIDk4NC4yNTUgNjM3IDk4OCA2Mzd6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOTc5IC02MzcpIi8+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
  width: 18px;
  height: 14px;
}
.message-form-status_autocomplete__2f5bH.message-form-disabled__1vT8M {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  cursor: auto;
}
.message-form-status_autocomplete__2f5bH.message-form-hide__3TB9x {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE4IDE3Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzAwOUNGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik05ODguNzIyIDY0OC45MDJsMS4zODQgMS42MTZjLS42NDIuMzAxLTEuMzUuNDgyLTIuMTA2LjQ4Mi0yLjc1NyAwLTUtMi4yNDQtNS01IDAtMS4wMy4zMTQtMS45OS44NTEtMi43ODZsMS4zODYgMS42MThjLS4xNTMuMzYtLjIzNy43NTQtLjIzNyAxLjE2OCAwIDEuNjU0IDEuMzQ2IDMgMyAzIC4yNSAwIC40OS0uMDQuNzIyLS4wOThNOTg4IDY0M2MxLjY1NCAwIDMgMS4zNDUgMyAzIDAgLjI2Ni0uMDQ1LjUyLS4xMTIuNzY1bC0zLjE5LTMuNzI1Yy4xMDEtLjAxMi4xOTctLjA0LjMwMi0uMDRtOC43OTEtMi4zNzhjLTEuNzc4LTIuMzAyLTQuOTgzLTMuNjIyLTguNzktMy42MjItMS43NSAwLTMuMzYyLjI5LTQuNzc4LjgxNWwtMS4yNTQtMS40NjZjLS4zNTktLjQxOS0uOTktLjQ2OC0xLjQxLS4xMDktLjQyLjM2LS40NjguOTkxLS4xMDkgMS40MWwuOTE2IDEuMDcxYy0uODQ1LjUyNy0xLjU4MiAxLjE1Ny0yLjE1OCAxLjkwMS0uMzM3LjQzNy0uMjU3IDEuMDY1LjE4MSAxLjQwMy40MzcuMzM4IDEuMDY0LjI1OCAxLjQwMi0uMTguNDgxLS42MjIgMS4xMy0xLjE1IDEuODk1LTEuNTgzbDEwLjE3NyAxMS44ODVjLjE5Ny4yMzEuNDc4LjM1Ljc2LjM1LjIzIDAgLjQ2MS0uMDc5LjY1LS4yNC40Mi0uMzYuNDY4LS45OS4xMS0xLjQxbC0yLjA1Ni0yLjRjLjQxOC0uNzI0LjY3My0xLjU1Mi42NzMtMi40NDcgMC0yLjc1Ny0yLjI0My01LTUtNS0uNjIyIDAtMS4yMS4xMjgtMS43NTkuMzM4bC0xLjYxMy0xLjg4M2MxLjAyNi0uMjk1IDIuMTY1LS40NTUgMy4zNzItLjQ1NSAzLjEzOCAwIDUuODMyIDEuMDYzIDcuMjA4IDIuODQ1LjE5OC4yNTUuNDkzLjM5Ljc5Mi4zOS4yMTQgMCAuNDMtLjA2OS42MTEtLjIxLjQzNy0uMzM4LjUxOC0uOTY2LjE4LTEuNDAzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOTc5IC02MzYpIi8+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
  width: 18px;
  height: 16.5px;
}
.message-form-status_shortcuts_wrap__zTGDJ {
  display: block;
  position: absolute;
  top: 55px;
  right: 5px;
}
.message-form-status_shortcuts_wrap__zTGDJ span:first-letter {
  text-transform: uppercase;
}
.message-form-status_shortcuts__2E_Vw {
  display: block;
  color: #a9b5c9;
  font-size: 12px;
  cursor: pointer;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE4IDE1Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPHBhdGggc3Ryb2tlPSIjMDA5Q0ZGIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTUgMTNIMmMtMS4xMDQgMC0yLS44OTYtMi0yVjJDMCAuODk2Ljg5NiAwIDIgMGgxMmMxLjEwNCAwIDIgLjg5NiAyIDJ2OWMwIDEuMTA0LS44OTYgMi0yIDJINXoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00IC0zKSB0cmFuc2xhdGUoNSA0KSIvPgogICAgICAgICAgICAgICAgPGcgZmlsbD0iIzAwOUNGRiI+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTIuMzg3IDEuMTkzYzAgLjY1OS0uNTM1IDEuMTkzLTEuMTk0IDEuMTkzQy41MzQgMi4zODYgMCAxLjg1MiAwIDEuMTkzUy41MzQgMCAxLjE5MyAwczEuMTk0LjUzNCAxLjE5NCAxLjE5M002LjE5MyAxLjE5M2MwIC42NTktLjUzNCAxLjE5My0xLjE5MyAxLjE5My0uNjYgMC0xLjE5NC0uNTM0LTEuMTk0LTEuMTkzUzQuMzQgMCA1IDBjLjY2IDAgMS4xOTMuNTM0IDEuMTkzIDEuMTkzTTEwIDEuMTkzYzAgLjY1OS0uNTM0IDEuMTkzLTEuMTkzIDEuMTkzLS42NiAwLTEuMTk0LS41MzQtMS4xOTQtMS4xOTNTOC4xNDcgMCA4LjgwNyAwQzkuNDY2IDAgMTAgLjUzNCAxMCAxLjE5MyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQgLTMpIHRyYW5zbGF0ZSg1IDQpIHRyYW5zbGF0ZSgzIDUuMzA3KSIvPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
  width: 18px;
  height: 15px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.message-form-status_shortcuts__2E_Vw:hover {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE4IDE1Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPHBhdGggZmlsbD0iIzAwOUNGRiIgc3Ryb2tlPSIjMDA5Q0ZGIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTUgMTNIMmMtMS4xMDQgMC0yLS44OTYtMi0yVjJDMCAuODk2Ljg5NiAwIDIgMGgxMmMxLjEwNCAwIDIgLjg5NiAyIDJ2OWMwIDEuMTA0LS44OTYgMi0yIDJINXoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zMSAtMykgdHJhbnNsYXRlKDMyIDQpIi8+CiAgICAgICAgICAgICAgICA8ZyBmaWxsPSIjRkZGIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMi4zODcgMS4xOTNjMCAuNjU5LS41MzUgMS4xOTMtMS4xOTQgMS4xOTNDLjUzNCAyLjM4NiAwIDEuODUyIDAgMS4xOTNTLjUzNCAwIDEuMTkzIDBzMS4xOTQuNTM0IDEuMTk0IDEuMTkzTTYuMTkzIDEuMTkzYzAgLjY1OS0uNTM0IDEuMTkzLTEuMTkzIDEuMTkzLS42NiAwLTEuMTk0LS41MzQtMS4xOTQtMS4xOTNTNC4zNCAwIDUgMGMuNjYgMCAxLjE5My41MzQgMS4xOTMgMS4xOTNNMTAgMS4xOTNjMCAuNjU5LS41MzQgMS4xOTMtMS4xOTMgMS4xOTMtLjY2IDAtMS4xOTQtLjUzNC0xLjE5NC0xLjE5M1M4LjE0NyAwIDguODA3IDBDOS40NjYgMCAxMCAuNTM0IDEwIDEuMTkzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzEgLTMpIHRyYW5zbGF0ZSgzMiA0KSB0cmFuc2xhdGUoMyA1LjMwNykiLz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==");
}
.message-form-status_shortcuts__2E_Vw.message-form-hide__3TB9x {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzAwOUNGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0zNC4zOTIgMjMuMjM5Yy4zODYtLjMzLjk1NS0uMzE1IDEuMzIyLjAyMmwuMDg4LjA5LjU5Mi42OTZINDdjMS41OTUgMCAyLjkwMyAxLjI1MSAyLjk5NSAyLjgyNGwuMDA1LjE3NnY5YzAgLjk1LS40NSAxLjc4NS0xLjE0NCAyLjMzbC0uMTUyLjExMy4zNy40MzVjLjM1OS40Mi4zMDkgMS4wNS0uMTEyIDEuNDEtLjE4OC4xNi0uNDE4LjIzOC0uNjQ4LjIzOC0uMjQyIDAtLjQ4NC0uMDg4LS42NzItLjI1OWwtLjA5LS4wOTJMMzQuMjggMjQuNjQ5Yy0uMzU4LS40Mi0uMzA4LTEuMDUxLjExMi0xLjQxem0tMS41MzYgMS43MzVsLjAzOC4wNyAxLjI2NCAxLjQ4MmMtLjA2NS4xMDEtLjEyNy4yMjYtLjE1LjM5bC0uMDA4LjEzdjljMCAuNTEyLjM4Ny45MzYuODg0Ljk5NGwuMTE2LjAwN2g4LjEyNWwxLjcwNSAySDM1Yy0xLjU5NSAwLTIuOTAzLTEuMjUyLTIuOTk1LTIuODI0TDMyIDM2LjA0N3YtOWMwLS42ODIuMjM1LTEuMzUuNjYxLTEuODc4bC4wOTUtLjEuMS0uMDk1ek00NyAyNi4wNDdoLTguOTAxTDQ3LjQgMzYuOTZjLjMxMi0uMTQuNTQyLS40My41OS0uNzhsLjAwOS0uMTM0di05YzAtLjUxMi0uMzg3LS45MzYtLjg4NC0uOTk0TDQ3IDI2LjA0N3ptLTkuODA3IDQuMzA3Yy42NiAwIDEuMTk0LjUzNCAxLjE5NCAxLjE5M3MtLjUzNSAxLjE5My0xLjE5NCAxLjE5M2MtLjY1OSAwLTEuMTkzLS41MzQtMS4xOTMtMS4xOTMgMC0uNjYuNTM0LTEuMTkzIDEuMTkzLTEuMTkzem03LjYxNCAwYy42NTkgMCAxLjE5My41MzMgMS4xOTMgMS4xOTIgMCAuNjYtLjUzNCAxLjE5NC0xLjE5MyAxLjE5NC0uNjYgMC0xLjE5NC0uNTM0LTEuMTk0LTEuMTk0IDAtLjY1OS41MzUtMS4xOTMgMS4xOTQtMS4xOTN6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzIgLTIzKSIvPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==");
  width: 18px;
  height: 17.6px;
}
.message-form-bottom_group__2iqpk {
  margin: 0 20px;
  position: relative;
  height: 35px;
}
.message-form-actions_left__1JbNp {
  padding: 0;
  margin: 0;
}
.message-form-actions_left_container__3Ly39 {
  margin-left: 20px;
  vertical-align: top;
  display: inline-block;
}
.message-form-actions_left_container_assigners__1JVrl {
  margin-top: 10px;
}
.message-form-actions_right__3JhsM {
  position: absolute;
  right: 0;
  top: 0;
}
.message-form-visibility_hidden__3qk_8 {
  visibility: hidden;
  -webkit-animation-name: message-form-textarea_moved__1C78m;
          animation-name: message-form-textarea_moved__1C78m;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}
.message-form-container_shortcuts__1Qf-n {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
}
@-webkit-keyframes message-form-textarea_moved__1C78m {
  from {
    visibility: inherit;
    opacity: 1;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}
@keyframes message-form-textarea_moved__1C78m {
  from {
    visibility: inherit;
    opacity: 1;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}
