.customer-orders-error__1p0gO {
  text-align: center;
  color: #f87b6e;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.customer-orders-no_data__1wEk6 {
  font-family: Hind;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #57697b;
  text-align: center;
}
.customer-orders-note__95uMg {
  position: absolute;
  border: 1px solid #53a8ff;
  padding: 5px;
  color: #52a7fd;
  background-color: #fff;
  margin-top: 37px;
  right: 15px;
  display: none;
}
.customer-orders-note_visible__6Ivca {
  display: block;
}
.customer-orders-container__X8CBg {
  list-style: none;
  padding: 0 15px;
  margin: 0;
}
.customer-orders-order_container__1WjBI {
  border-bottom: solid 1px #e6eaef;
}
.customer-orders-order_container__1WjBI:last-child {
  border-bottom: none;
}
.customer-orders-title__2-ufV {
  color: #6c6c7c;
  font-weight: 600;
  margin-bottom: 10px;
}
.customer-orders-status__wBXN1 {
  font-size: 13px;
  color: #919aa2;
}
.customer-orders-status_text__1pp8X {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 5px;
}
.customer-orders-status_timer__1_ilM {
  float: right;
}
.customer-orders-status__wBXN1:hover .customer-orders-title__2-ufV {
  color: #53a8ff;
}
.customer-orders-status__wBXN1:hover .customer-orders-status__wBXN1 {
  color: #2c2c40;
}
.customer-orders-text_container__1R9TI {
  display: block;
  font-family: Hind;
  font-size: 15px;
  cursor: pointer;
  padding: 15px 0;
}
.customer-orders-draft_icon__DJkqv {
  display: inline-block;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE2IDE0Ij4NCiAgICA8ZyBmaWxsPSIjNkM2QzdDIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPg0KICAgICAgICA8cGF0aCBkPSJNMS40NjQgNC4zMzdoOC40NzN2LS44MjNIMS40NjR2LjgyM3ptMCAxLjk0Mmg4LjQ3M3YtLjgyMkgxLjQ2NHYuODIyem0wIDEuOTQzaDguNDczdi0uODIzSDEuNDY0di44MjN6bTAgMS45NDNoMS45OTh2LS44MjNIMS40NjR2LjgyM3ptMC03Ljc3aDUuODI5di0uODIzaC01Ljgzdi44MjJ6TTEwLjI2OSAwSDEuMTNDLjUwNyAwIDAgLjUwNiAwIDEuMTMxVjEyLjg1YzAgLjYyNS41MDcgMS4xMzEgMS4xMzEgMS4xMzFoOS4xMzhjLjYyNSAwIDEuMTMxLS41MDYgMS4xMzEtMS4xMzFWMS4xMzFjMC0uNjI1LS41MDYtMS4xMy0xLjEzMS0xLjEzek0xMi44MjggMTEuNjg1VjIuNDgzaDIuMzgxdjkuMjAybC0xLjE5IDIuMjk2LTEuMTkxLTIuMjk2ek0xNC4wMTggMGMuNjU4IDAgMS4xOTEuNTIgMS4xOTEgMS4xNjF2LjU1NmgtMi4zODF2LS41NTZDMTIuODI4LjUyIDEzLjM2IDAgMTQuMDE4IDB6Ii8+DQogICAgPC9nPg0KPC9zdmc+");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px auto;
  width: 18px;
  height: 18px;
  background-color: transparent;
  margin-left: 10px;
  float: right;
  margin-top: 13px;
}
.customer-orders-orders_count__1-Jsn {
  margin: 10px 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  display: block;
  line-height: 16px;
  font-size: 13px;
  color: #333;
}
.customer-orders-search__2D3XA {
  background: #ff0;
}
