.styles-container_dropdown__1HPpS .styles-dropdown_item__302Nq {
  margin-bottom: 10px;
}
.styles-container_dropdown__1HPpS .styles-btn_dropdown__1H_g- {
  position: relative;
  min-height: 41px;
}
.styles-container_dropdown__1HPpS .styles-btn_dropdown__1H_g- span {
  display: inline-block;
  max-width: 175px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
}
.styles-container_dropdown__1HPpS .styles-btn_dropdown__1H_g-.styles-btn_dropdown_error__3N0z3 {
  border-color: #eb5757;
  margin-bottom: 25px;
}
.styles-container_dropdown__1HPpS .styles-btn_dropdown__1H_g-.styles-btn_dropdown_error__3N0z3:after {
  content: 'Field is required';
  position: absolute;
  display: block;
  color: #eb5757;
  bottom: -23px;
  left: 0;
  font-weight: 700;
}
.styles-container_dropdown__1HPpS .styles-item__RICXM {
  padding: 3px 25px 5px 10px;
  font-size: 14px;
  border-radius: 3px;
  position: relative;
  color: #637280;
  white-space: nowrap;
  word-break: break-all;
  margin: 0;
  display: block;
  margin-bottom: 5px;
  cursor: pointer;
  max-width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.styles-container_dropdown__1HPpS .styles-item__RICXM.styles-active__3Nqur,
.styles-container_dropdown__1HPpS .styles-item__RICXM:hover {
  background-color: #ebf7ff;
}
.styles-container_dropdown__1HPpS .styles-priority__wDDmk {
  display: inline-block;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  background-color: #e6eaef;
}
.styles-container_dropdown__1HPpS .styles-priority__wDDmk.styles-high__1zVWc {
  background-color: #e95a1c;
}
.styles-container_dropdown__1HPpS .styles-priority__wDDmk.styles-medium__3BF67 {
  background-color: #dab42b;
}
.styles-container_dropdown__1HPpS .styles-priority__wDDmk.styles-low__1NaOT {
  background-color: #12a054;
}
.styles-container_dropdown__1HPpS .styles-group_name__1WSD3 {
  text-transform: uppercase;
  margin: 10px 0;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
}
