.save-button-button-base__3u5Zs {
  border: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 2px;
}
.save-button-button-base__3u5Zs:focus {
  outline: none;
}
.save-button-button-default__2mHjS,
.save-button-button_common__3Yz64 {
  padding: 0 14px;
  height: 32px;
  line-height: 33px;
}
.save-button-button-default__2mHjS.save-button-only_icon__1NA4w,
.save-button-button_common__3Yz64.save-button-only_icon__1NA4w {
  font-size: 18px;
  width: 36px;
}
.save-button-button-default__2mHjS .save-button-badge__3kJLj,
.save-button-button_common__3Yz64 .save-button-badge__3kJLj {
  height: 16px;
  line-height: 16px;
}
.save-button-button-small__tetPd {
  padding: 0 13px;
  text-transform: uppercase;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.save-button-button-small__tetPd.save-button-only_icon__1NA4w {
  padding-top: 9px;
  font-size: 13px;
  width: 28px;
}
.save-button-button-extra-small__1liGK {
  padding: 0 6px;
  text-transform: uppercase;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
}
.save-button-button-primary__HZz_Y,
.save-button-button_type_normal__3s5I8 {
  background-color: #188fea;
  color: #fff;
}
.save-button-button-primary__HZz_Y:hover,
.save-button-button_type_normal__3s5I8:hover {
  background-color: #0a71bf;
}
.save-button-button-light__v3EIa {
  background-color: #fff;
  color: #0a71bf;
}
.save-button-button-transparent__-mAHR {
  background-color: none;
  color: #fff;
  border: 1px solid #fff;
}
.save-button-button-transparent__-mAHR:hover {
  background-color: #fff;
  color: #0a71bf;
}
.save-button-button-danger__3jPjZ,
.save-button-button_type_tryAgain__1xXTT {
  background-color: #d85151;
  color: #fff;
}
.save-button-button-danger__3jPjZ:hover,
.save-button-button_type_tryAgain__1xXTT:hover {
  background-color: #c13030;
}
.save-button-button-full__2yf69 {
  width: 100%;
  text-align: center;
}
.save-button-button-secondary__8wvRm {
  background-color: #aaa;
  color: #fff;
  font-weight: bold;
}
.save-button-button-secondary__8wvRm:hover {
  background-color: #0a71bf;
}
.save-button-button-tertiary__TvmHq {
  color: #aaa;
  font-weight: bold;
}
.save-button-button-tertiary__TvmHq:hover {
  color: #0a71bf;
}
.save-button-button-dark__2i2CK {
  background-color: #0a71bf;
  color: #fff;
}
.save-button-button-dark__2i2CK:hover {
  background-color: #005fa7;
}
.save-button-button-only-icon__DsF6j {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.save-button-button-icon-big__QzX7j {
  font-size: 18px;
  width: 38px;
}
.save-button-button-icon__s2HQT {
  margin-right: 5px;
}
.save-button-button-bold__2046E {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.save-button-button-float-right__2UANK {
  float: right;
}
.save-button-button-with-hr__1YnbO {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 0;
}
.save-button-button-with-hr__1YnbO:after {
  display: block;
  position: relative;
  bottom: 15px;
  content: '';
  border-bottom: 1px solid #c1cdd6;
  z-index: -1;
}
.save-button-button-processing__2nVUJ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
}
.save-button-button-invisible__20NMB {
  visibility: hidden;
}
.save-button-button-tooltip__1-VRI,
.save-button-tooltip__3pOf9 {
  top: 38px;
  left: 50%;
  margin-left: -75px;
  color: #fff;
  width: 150px;
  font-size: 13px;
  text-align: center;
  text-transform: initial;
  position: absolute;
  padding: 5px;
  z-index: 2;
  background-color: #3e434d;
  height: 26px;
  line-height: 16px;
  opacity: 0.8;
  border-radius: 2px;
}
.save-button-button-tooltip__1-VRI:after,
.save-button-tooltip__3pOf9:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-bottom-color: #3e434d;
  border-width: 5px;
  margin-left: -5px;
}
.save-button-button-badge__3cMAM {
  padding: 0px 7px;
  min-width: 10px;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #f86e5f;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 40px;
}
.save-button-root__1oXsX {
  display: inline-block;
  position: relative;
}
.save-button-button_type_normal__3s5I8.save-button-process__1thMa {
  color: #188fea;
}
.save-button-button_type_tryAgain__1xXTT.save-button-process__1thMa {
  color: #d85151;
}
.save-button-process__1thMa img {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -9px;
  margin-right: -9px;
}
.save-button-tooltip__3pOf9 {
  bottom: 45px;
  top: auto;
  left: 0;
  margin-left: 0;
}
.save-button-tooltip__3pOf9:after {
  bottom: auto;
  top: 100%;
  border-bottom-color: transparent;
  left: 29%;
}
.save-button-tooltip_success__1OZLu {
  background: #008000;
}
.save-button-tooltip_success__1OZLu:after {
  border-top-color: #008000;
}
.save-button-tooltip_error__2Mn7G {
  background: #d85151;
}
.save-button-tooltip_error__2Mn7G:after {
  border-top-color: #d85151;
}
