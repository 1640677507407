.reply-template-selector-button-base__3lgX9 {
  border: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 2px;
}
.reply-template-selector-button-base__3lgX9:focus {
  outline: none;
}
.reply-template-selector-button-default__7v5le {
  padding: 0 14px;
  height: 32px;
  line-height: 33px;
}
.reply-template-selector-button-default__7v5le.reply-template-selector-only_icon__FJDpy {
  font-size: 18px;
  width: 36px;
}
.reply-template-selector-button-default__7v5le .reply-template-selector-badge__34iXi {
  height: 16px;
  line-height: 16px;
}
.reply-template-selector-button-small__1JV1Z {
  padding: 0 13px;
  text-transform: uppercase;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.reply-template-selector-button-small__1JV1Z.reply-template-selector-only_icon__FJDpy {
  padding-top: 9px;
  font-size: 13px;
  width: 28px;
}
.reply-template-selector-button-extra-small__1HYc8 {
  padding: 0 6px;
  text-transform: uppercase;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
}
.reply-template-selector-button-primary__25q-Q {
  background-color: #188fea;
  color: #fff;
}
.reply-template-selector-button-primary__25q-Q:hover {
  background-color: #0a71bf;
}
.reply-template-selector-button-light__3gQs1,
.reply-template-selector-open_button__3UnqA {
  background-color: #fff;
  color: #0a71bf;
}
.reply-template-selector-button-transparent__1BtHX {
  background-color: none;
  color: #fff;
  border: 1px solid #fff;
}
.reply-template-selector-button-transparent__1BtHX:hover {
  background-color: #fff;
  color: #0a71bf;
}
.reply-template-selector-button-danger__8zuKs {
  background-color: #d85151;
  color: #fff;
}
.reply-template-selector-button-danger__8zuKs:hover {
  background-color: #c13030;
}
.reply-template-selector-button-full__3E5KA {
  width: 100%;
  text-align: center;
}
.reply-template-selector-button-secondary__3KyiZ {
  background-color: #aaa;
  color: #fff;
  font-weight: bold;
}
.reply-template-selector-button-secondary__3KyiZ:hover {
  background-color: #0a71bf;
}
.reply-template-selector-button-tertiary__2yk21 {
  color: #aaa;
  font-weight: bold;
}
.reply-template-selector-button-tertiary__2yk21:hover {
  color: #0a71bf;
}
.reply-template-selector-button-dark__Kk3Nz {
  background-color: #0a71bf;
  color: #fff;
}
.reply-template-selector-button-dark__Kk3Nz:hover {
  background-color: #005fa7;
}
.reply-template-selector-button-only-icon__3rVP8 {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.reply-template-selector-button-icon-big__32Qv3 {
  font-size: 18px;
  width: 38px;
}
.reply-template-selector-button-icon__2rd75 {
  margin-right: 5px;
}
.reply-template-selector-button-bold__3Cq9B {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.reply-template-selector-button-float-right__2nYHB {
  float: right;
}
.reply-template-selector-button-with-hr__1DAcQ {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 0;
}
.reply-template-selector-button-with-hr__1DAcQ:after {
  display: block;
  position: relative;
  bottom: 15px;
  content: '';
  border-bottom: 1px solid #c1cdd6;
  z-index: -1;
}
.reply-template-selector-button-processing__3YkRI {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
}
.reply-template-selector-button-invisible__278QD {
  visibility: hidden;
}
.reply-template-selector-button-tooltip__3CUyd {
  top: 38px;
  left: 50%;
  margin-left: -75px;
  color: #fff;
  width: 150px;
  font-size: 13px;
  text-align: center;
  text-transform: initial;
  position: absolute;
  padding: 5px;
  z-index: 2;
  background-color: #3e434d;
  height: 26px;
  line-height: 16px;
  opacity: 0.8;
  border-radius: 2px;
}
.reply-template-selector-button-tooltip__3CUyd:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-bottom-color: #3e434d;
  border-width: 5px;
  margin-left: -5px;
}
.reply-template-selector-button-badge__2GdFi {
  padding: 0px 7px;
  min-width: 10px;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #f86e5f;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 40px;
}
.reply-template-selector-root__21uvp {
  display: inline-block;
}
.reply-template-selector-open_button__3UnqA {
  height: 20px;
  width: 23px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 20px;
  cursor: pointer;
}
