.messages-ranking-container__Xb9Xy {
  padding: 10px;
  border-top: 1px solid #f1f3f6;
}
.messages-ranking-conv_container__2mC3u {
  margin-top: 13px;
  border-top: 1px solid #f1f3f6;
  border-bottom: 1px solid #f1f3f6;
}
.messages-ranking-btn__1Bd16 {
  display: block;
  background-color: #53a8ff;
  color: #fff;
  width: 100%;
  height: 35px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
  border: none;
}
.messages-ranking-btn__1Bd16:focus,
.messages-ranking-btn__1Bd16:active {
  outline: none;
}
.messages-ranking-stats_container__3lVDQ {
  padding: 5px;
}
.messages-ranking-stats_number__KqVWM {
  font-size: 18px;
  font-weight: bold;
  color: #6c6c7c;
  margin-bottom: 5px;
}
.messages-ranking-stats_name__2NHRc {
  font-size: 12px;
  font-weight: normal;
  color: #919aa2;
  margin-bottom: 3px;
}
.messages-ranking-stats_field__15T8N {
  display: inline-block;
  width: 33.33%;
}
