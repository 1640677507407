.warning-popup-container__2TAg7 {
  display: inline-block;
  position: absolute;
  padding: 15px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  border: solid 1px #a9b5c9;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #47475b;
  width: 200px;
  margin-bottom: 15px;
  cursor: default;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.warning-popup-text__3gh_r {
  margin: 0 0 15px;
  line-height: 1.5;
}
.warning-popup-delete_button__1PcXM {
  margin-left: 10px;
}
.warning-popup-arrow__1jkYr {
  position: absolute;
  top: -9;
  left: 50%;
  width: 10px;
  height: 10px;
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: #a9b5c9;
  background-color: #fff;
  -webkit-transform: rotate(-45deg) translateX(-50%);
          transform: rotate(-45deg) translateX(-50%);
}
