.reply-answers-container__1XPWc {
  display: inline-block;
  position: relative;
  vertical-align: top;
  height: 100%;
  width: calc(100% - 180px);
  background-color: #fff;
}
.reply-answers-header__1_dg8 {
  padding: 10px 10px;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0,0,0,0.09);
          box-shadow: 0 1px 1px 0 rgba(0,0,0,0.09);
}
.reply-answers-search__1yQP_,
.reply-answers-search__1yQP_:active,
.reply-answers-search__1yQP_:focus {
  border: solid 1px #a9b5c9;
}
.reply-answers-list__18rMO {
  list-style: none;
  padding: 0 10px;
  margin: 10px 0;
  overflow: auto;
  height: calc(100% - 76px);
}
.reply-answers-list_editable__1ufZT {
  height: calc(100% - 104px);
}
.reply-answers-list_with_new__tbiul {
  height: calc(100% - 273px);
}
.reply-answers-list_item__1PjmQ,
.reply-answers-list_item_edit__21277 {
  border-bottom: 1px dotted #cdd0d3;
}
.reply-answers-list_item__1PjmQ:last-of-type,
.reply-answers-list_item_edit__21277:last-of-type {
  border-bottom: none;
}
.reply-answers-list_item_edit__21277 {
  margin: 10px 0;
}
.reply-answers-controls__1TLRy {
  display: none;
  float: right;
  font-size: 13px;
  font-weight: 600;
  color: #53a8ff;
}
.reply-answers-controls__1TLRy span {
  margin: 0 4px;
  cursor: pointer;
}
.reply-answers-controls__1TLRy span:first-of-type {
  margin-left: 0;
}
.reply-answers-controls__1TLRy span:last-of-type {
  margin-right: 0;
}
.reply-answers-controls__1TLRy span:hover {
  text-decoration: underline;
}
.reply-answers-item__3IlLF {
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.reply-answers-item__3IlLF:hover {
  background-color: #edf6ff;
}
.reply-answers-item__3IlLF:hover .reply-answers-controls__1TLRy {
  display: block;
}
.reply-answers-item_name__2Usxd {
  display: inline-block;
  margin: 0;
  margin-left: 5px;
  font-size: 15px;
  font-weight: 600;
  color: #47475b;
  white-space: initial;
  word-break: break-all;
  vertical-align: top;
}
.reply-answers-item_text__3Qi-z {
  margin: 0;
  font-size: 14px;
  color: #47475b;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 16px;
}
.reply-answers-item_text__3Qi-z span {
  display: inline-block;
}
.reply-answers-item_text_large__ttQaR {
  display: inline-block;
  width: calc(100% - 75px);
}
.reply-answers-item_text_container__35MoY {
  margin: 5px 0 0;
}
.reply-answers-item_name__2Usxd em,
.reply-answers-item_text__3Qi-z em {
  background-color: #ffeac1;
  font-style: inherit;
}
.reply-answers-show_more__3zmgv {
  font-size: 14px;
  color: #53a8ff;
  float: right;
  cursor: pointer;
}
.reply-answers-show_more__3zmgv:hover {
  text-decoration: underline;
}
.reply-answers-show_more_text__3aqDG {
  white-space: normal;
}
.reply-answers-favorite__1jYVw {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url(/60a3500bdaa5b2a2cee805cb55eaf39a.png) center no-repeat;
  background-size: cover;
  cursor: pointer;
}
.reply-answers-favorite__1jYVw:hover {
  background: url(/9daf5284e88542cfaa608b6876cfbafa.png) center no-repeat;
}
.reply-answers-favorite_active__gao47 {
  background-image: url(/540665534fcb65972979e6a46a903c25.png);
}
.reply-answers-add_new__3x9M4 {
  padding: 7px 10px;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0,0,0,0.09);
          box-shadow: 0 1px 1px 0 rgba(0,0,0,0.09);
  margin-bottom: 10px;
}
.reply-answers-add_new_text__1YxEr {
  font-family: Hind;
  font-size: 14px;
  color: #53a8ff;
  cursor: pointer;
}
.reply-answers-add_new_text__1YxEr:hover {
  text-decoration: underline;
}
.reply-answers-add_new_form__1iTiL {
  margin: 0 10px;
}
.reply-answers-pagination__2PLqO {
  text-align: center;
}
