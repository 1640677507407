.agents-selector-indicator__24eoi {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 5px;
}
.agents-selector-input__1rIJY {
  border: 1px solid #e2e6ed;
  border-radius: 3px;
}
.agents-selector-onlineIndicator__3meJ1 {
  background: #0c0;
}
.agents-selector-dormantIndicator__hssVd {
  background: #cc0;
}
.agents-selector-offlineIndicator__2g-Xu {
  background: #aaa;
}
