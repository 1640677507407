.custom-select-container__select__1qWz8 {
  border: none;
  cursor: pointer;
}
.custom-select-container__select__1qWz8:focus {
  outline: none;
}
.custom-select-container__select_icon__2-IsU {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #000 transparent transparent transparent;
  margin-top: 8px;
  float: right;
}
.custom-select-container__options__25-wC {
  position: absolute;
  border-radius: 2px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 4px 0 rgba(0,0,0,0.07);
          box-shadow: 0 0 4px 0 rgba(0,0,0,0.07);
  border: 1px solid #d3d3d3;
  margin-top: 2px;
  z-index: 1;
  max-height: 300px;
  overflow: auto;
  display: inline-block;
  padding: 0;
}
.custom-select-container__options-option__2WB6O {
  padding: 0 14px;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.14;
  color: #47475b;
  cursor: pointer;
}
.custom-select-container__options-option__2WB6O:hover {
  background-color: #edf6ff;
}
.custom-select-container__options-option_current__2G7F1 {
  color: #56aafc;
}
