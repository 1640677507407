.notification-item-container__3JpkY {
  width: 100%;
  background: #ffe0e8;
  -webkit-box-shadow: 0px 4px 8px rgba(0,0,0,0.01);
          box-shadow: 0px 4px 8px rgba(0,0,0,0.01);
  border-radius: 12px;
  position: relative;
  padding: 12px 25px 12px 12px;
  margin-bottom: 10px;
}
.notification-item-close_button__3TN4u {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background-color: transparent;
  display: inline-block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: transparent;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjgzMzIgMS4zNDE2NkwxMC42NTgyIDAuMTY2NjY0TDUuOTk5ODQgNC44MjVMMS4zNDE1IDAuMTY2NjY0TDAuMTY2NTA0IDEuMzQxNjZMNC44MjQ4NCA2TDAuMTY2NTA0IDEwLjY1ODNMMS4zNDE1IDExLjgzMzNMNS45OTk4NCA3LjE3NUwxMC42NTgyIDExLjgzMzNMMTEuODMzMiAxMC42NTgzTDcuMTc0ODQgNkwxMS44MzMyIDEuMzQxNjZaIiBmaWxsPSIjMkMyRDQwIi8+Cjwvc3ZnPgo=");
  opacity: 0.5;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.notification-item-close_button__3TN4u:hover {
  opacity: 1;
}
.notification-item-close_button__3TN4u:active,
.notification-item-close_button__3TN4u:focus {
  outline: none;
}
.notification-item-body__1zzh_ {
  margin: 10px;
}
.notification-item-name__2fzli,
.notification-item-status__9WHl- {
  padding: 0;
  margin: 0;
}
.notification-item-name__2fzli {
  line-height: 24px;
  color: #323d49;
  font-size: 16px;
  font-weight: 700;
}
.notification-item-last_time__anrn2 {
  line-height: 20px;
  color: #323d49;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  top: 15px;
  right: 15px;
}
.notification-item-status__9WHl- {
  line-height: 20px;
  color: #000;
  font-size: 16px;
}
