.customer-communication-error__3Ov2g {
  text-align: center;
  color: #f87b6e;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.customer-communication-no_data__3I0pp {
  font-family: Hind;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #57697b;
  text-align: center;
}
.customer-communication-container__fgf50 {
  list-style: none;
  padding: 5px 15px 15px;
  margin: 0;
  font-family: Hind;
}
.customer-communication-number__cj4zr {
  font-size: 15px;
  font-weight: 600;
  color: #6c6c7c;
}
.customer-communication-message__31QmI {
  float: right;
  margin-top: 2px;
}
.customer-communication-message_new__1vB6r svg * {
  fill: #fc694f;
}
.customer-communication-thread_item__3u3qs {
  border-bottom: solid 1px #e6eaef;
  padding: 13px 0;
  cursor: pointer;
}
.customer-communication-thread_item__3u3qs:last-child {
  border-bottom: none;
}
.customer-communication-thread_item__3u3qs:hover .customer-communication-number__cj4zr {
  color: #53a8ff;
}
.customer-communication-thread_info__2CB54 {
  margin-top: 8px;
  font-size: 13px;
  color: #919aa2;
}
.customer-communication-time__NK6s- {
  float: right;
}
