.group-list-container__3Rlwd {
  font-family: 'Hind', sans-serif;
  color: #47475b;
}
.group-list-list__qinCI {
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 3px;
}
.group-list-item_container__GxRb8 {
  border-bottom: 1px solid #bec4cb;
  width: 100%;
}
.group-list-item_container__GxRb8:last-child {
  border-bottom: none;
}
.group-list-item__344pn {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  width: 100%;
  padding: 20px;
  cursor: pointer;
  font-size: 15px;
}
.group-list-item__344pn:hover {
  background: #f2f5f9;
}
.group-list-item__344pn:hover .group-list-remove__SiRN0 {
  display: inline-block;
}
.group-list-item_active__2geYQ {
  background: #f2f5f9;
}
.group-list-remove__SiRN0 {
  display: none;
  width: 15px;
  height: 15px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid' width='12' height='12' viewBox='0 0 16 16'%3E %3Cdefs%3E %3Cstyle%3E .cls-1 %7B fill: %23f26d5b; fill-rule: evenodd; %7D %3C/style%3E %3C/defs%3E %3Cpath d='M16.003,2.733 L13.267,-0.003 L8.001,5.262 L2.736,-0.003 L-0.000,2.733 L5.265,7.998 L-0.000,13.263 L2.736,15.999 L8.001,10.734 L13.267,15.999 L16.003,13.263 L10.737,7.998 L16.003,2.733 Z' class='cls-1'/%3E %3C/svg%3E") center no-repeat;
  float: right;
}
