.custometio-item-container__3s1sE {
  display: block;
  border-radius: 3px;
  border: solid 1px #e6e6f2;
  margin: 15px;
}
.custometio-item-header__1Ljdl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 55px;
  border-radius: 3px 3px 0 0;
  background-color: #fafafc;
  padding: 15px 20px;
  cursor: pointer;
  color: #323d49;
  font-size: 14px;
  line-height: 24px;
}
.custometio-item-string_divider__1j9UN {
  display: inline-block;
  margin: 3px 10px;
  height: 4px;
  width: 4px;
  background-color: #c2c2cc;
  border-radius: 50%;
  padding: 0;
}
.custometio-item-status__2v1kv {
  font-weight: 700;
}
.custometio-item-activities__LRaVZ {
  margin-left: 20px;
}
