.modal-preview-component-container__2ovox {
  display: inline-block;
  max-width: 500px;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  padding: 5px;
}
.modal-preview-component-container__2ovox img {
  width: 100%;
}
.modal-preview-component-text__RjWyJ {
  font-family: Hind;
  font-size: 16px;
  text-align: center;
  color: #47475b;
  margin: 15px 0 20px;
}
.modal-preview-component-button__2essq {
  display: block;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cg fill='none' fill-rule='evenodd'%3E %3Cg fill='%23FFF'%3E %3Cg%3E %3Cpath d='M6.613 5.21l.094.083L12 10.585l5.293-5.292c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094L13.415 12l5.292 5.293c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083L12 13.415l-5.293 5.292c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094L10.585 12 5.293 6.707c-.39-.39-.39-1.024 0-1.414.36-.36.928-.388 1.32-.083z' transform='translate(-945 -30) translate(945 30)'/%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E") no-repeat center center;
  position: absolute;
  right: -35px;
  top: 2px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
