.log-message-root__1IQpi {
  padding: 0 0 0 15px;
  margin: 5px 0;
  color: #a9b5c9;
  font-size: 12px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  clear: both;
}
.log-message-root__1IQpi .log-message-time__ic9TG {
  font-weight: 600;
}
