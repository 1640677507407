.agent-list-container__3m5CD {
  min-width: 970px;
  background-color: #fff;
}
.agent-list-agents__2HxDJ {
  padding: 0 17px 17px;
  background-color: #fff;
  height: calc(100% - 100px);
  overflow: auto;
}
.agent-list-agents__container__2i1y9 {
  width: 100%;
}
.agent-list-agents__head__2fxWC {
  font-family: Hind;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  color: #919aa2;
  background-color: #fff;
  height: 39px;
  margin-left: 17px;
}
.agent-list-agents__head-item__3CUI1 {
  display: inline-block;
  margin-top: 13px;
}
.agent-list-agents__head_name__3vYfg {
  width: 400px;
}
.agent-list-agents__head_role__1HD_2 {
  width: 195px;
}
.agent-list-agents__head_status__2kY07 {
  width: 165px;
}
.agent-list-agents__pages__3hx1j {
  width: 100%;
  text-align: center;
}
