.agent-list-container__von-Z {
  font-family: 'Hind', sans-serif;
  color: #47475b;
}
.agent-list-title__bF807 {
  font-weight: bold;
  font-size: 16px;
}
.agent-list-list__3KoQG {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #bec4cb;
  border-radius: 3px;
  max-height: calc(100% - 200px);
  overflow: auto;
  min-height: 42px;
}
.agent-list-item_container__1Yupv {
  border-bottom: 1px solid #bec4cb;
}
.agent-list-item_container__1Yupv:last-child {
  border-bottom: none;
}
.agent-list-item__cX8CA {
  padding: 7px;
  cursor: pointer;
}
.agent-list-item__cX8CA:hover {
  background: #f2f5f9;
}
.agent-list-avatar__1t_2z {
  height: 28px;
  width: 28px;
  border-radius: 14px;
  background-color: #dde5ef;
  margin-right: 13px;
  vertical-align: middle;
}
.agent-list-agent_name__1pIG_ {
  display: inline-block;
  width: calc(100% - 70px);
  overflow-x: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.agent-list-text_center__3gMd5 {
  text-align: center;
}
.agent-list-position_relative__VWuO2 {
  position: relative;
}
