.styles-container__2aK7y {
  width: 100%;
  height: 100%;
  background-color: #fff;
  min-width: 1125px;
  padding: 15px;
}
.styles-container__2aK7y .styles-buttons_wrap__2W9lE {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
}
.styles-container__2aK7y .styles-btn__3K1HY {
  margin-right: 10px;
}
