.active-reminders-button-base__1mp1T {
  border: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 2px;
}
.active-reminders-button-base__1mp1T:focus {
  outline: none;
}
.active-reminders-button-default__3NedK,
.active-reminders-show_all__2s8q0,
.active-reminders-change_date__hgIfR {
  padding: 0 14px;
  height: 32px;
  line-height: 33px;
}
.active-reminders-button-default__3NedK.active-reminders-only_icon__mT23O,
.active-reminders-show_all__2s8q0.active-reminders-only_icon__mT23O,
.active-reminders-change_date__hgIfR.active-reminders-only_icon__mT23O {
  font-size: 18px;
  width: 36px;
}
.active-reminders-button-default__3NedK .active-reminders-badge__1i0Ys,
.active-reminders-show_all__2s8q0 .active-reminders-badge__1i0Ys,
.active-reminders-change_date__hgIfR .active-reminders-badge__1i0Ys {
  height: 16px;
  line-height: 16px;
}
.active-reminders-button-small__2vjP_,
.active-reminders-show_all__2s8q0,
.active-reminders-change_date__hgIfR {
  padding: 0 13px;
  text-transform: uppercase;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.active-reminders-button-small__2vjP_.active-reminders-only_icon__mT23O,
.active-reminders-show_all__2s8q0.active-reminders-only_icon__mT23O,
.active-reminders-change_date__hgIfR.active-reminders-only_icon__mT23O {
  padding-top: 9px;
  font-size: 13px;
  width: 28px;
}
.active-reminders-button-extra-small__1nkX4 {
  padding: 0 6px;
  text-transform: uppercase;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
}
.active-reminders-button-primary__2VXWK {
  background-color: #188fea;
  color: #fff;
}
.active-reminders-button-primary__2VXWK:hover {
  background-color: #0a71bf;
}
.active-reminders-button-light__3PKH_,
.active-reminders-show_all__2s8q0,
.active-reminders-change_date__hgIfR {
  background-color: #fff;
  color: #0a71bf;
}
.active-reminders-button-transparent__1vkcw {
  background-color: none;
  color: #fff;
  border: 1px solid #fff;
}
.active-reminders-button-transparent__1vkcw:hover {
  background-color: #fff;
  color: #0a71bf;
}
.active-reminders-button-danger__2lCei {
  background-color: #d85151;
  color: #fff;
}
.active-reminders-button-danger__2lCei:hover {
  background-color: #c13030;
}
.active-reminders-button-full__seoor {
  width: 100%;
  text-align: center;
}
.active-reminders-button-secondary__2exEp {
  background-color: #aaa;
  color: #fff;
  font-weight: bold;
}
.active-reminders-button-secondary__2exEp:hover {
  background-color: #0a71bf;
}
.active-reminders-button-tertiary__WWmAd {
  color: #aaa;
  font-weight: bold;
}
.active-reminders-button-tertiary__WWmAd:hover {
  color: #0a71bf;
}
.active-reminders-button-dark__2tzwv {
  background-color: #0a71bf;
  color: #fff;
}
.active-reminders-button-dark__2tzwv:hover {
  background-color: #005fa7;
}
.active-reminders-button-only-icon__bholp {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.active-reminders-button-icon-big__6khA7 {
  font-size: 18px;
  width: 38px;
}
.active-reminders-button-icon__1uFzL {
  margin-right: 5px;
}
.active-reminders-button-bold__1wL3p {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.active-reminders-button-float-right__3rQy0 {
  float: right;
}
.active-reminders-button-with-hr__36xGY {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 0;
}
.active-reminders-button-with-hr__36xGY:after {
  display: block;
  position: relative;
  bottom: 15px;
  content: '';
  border-bottom: 1px solid #c1cdd6;
  z-index: -1;
}
.active-reminders-button-processing__Ik97q {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
}
.active-reminders-button-invisible__36CkY {
  visibility: hidden;
}
.active-reminders-button-tooltip__37oGz {
  top: 38px;
  left: 50%;
  margin-left: -75px;
  color: #fff;
  width: 150px;
  font-size: 13px;
  text-align: center;
  text-transform: initial;
  position: absolute;
  padding: 5px;
  z-index: 2;
  background-color: #3e434d;
  height: 26px;
  line-height: 16px;
  opacity: 0.8;
  border-radius: 2px;
}
.active-reminders-button-tooltip__37oGz:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-bottom-color: #3e434d;
  border-width: 5px;
  margin-left: -5px;
}
.active-reminders-button-badge__25L3C {
  padding: 0px 7px;
  min-width: 10px;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #f86e5f;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 40px;
}
.active-reminders-header__1DsqY {
  margin-bottom: 15px;
  background: #dde5ef;
  color: #57697b;
  text-align: center;
  border-radius: 3px;
  padding: 9px;
}
.active-reminders-show_all__2s8q0 {
  float: right;
  padding-top: 0;
}
.active-reminders-reminder__1ueH7 {
  padding-bottom: 15px;
}
.active-reminders-reminder__1ueH7:hover .active-reminders-edit_btn__3YfWm,
.active-reminders-reminder__1ueH7:hover .active-reminders-delete__2FNH4 {
  display: inline-block;
}
.active-reminders-reminder__1ueH7 + .active-reminders-reminder__1ueH7 {
  border-top: dashed 1px #dde5ef;
  padding-top: 15px;
}
.active-reminders-reminder_body__155__ {
  margin-left: 4px;
}
.active-reminders-reminder_active__Dy0_b {
  background-color: #f3f8fd;
}
.active-reminders-time__3VbKh {
  font-family: Hind;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  color: #6c6c7c;
  margin-bottom: 10px;
  display: inline-block;
}
.active-reminders-added_by__2yRIl {
  color: #808080;
  float: right;
}
.active-reminders-text__21Wgj {
  color: #57697b;
  margin-bottom: 10px;
  word-break: break-all;
  white-space: pre-line;
}
.active-reminders-countdown__oM5es {
  font-weight: normal;
  color: #919aa2;
  font-size: 14px;
  display: inline-block;
  margin-left: 15px;
}
.active-reminders-edit_btn__3YfWm {
  display: none;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  height: 13px;
  vertical-align: bottom;
  line-height: 17px;
  color: #a9b5c9;
  float: right;
  padding-right: 3px;
  margin-right: 5px;
  padding-left: 15px;
  cursor: pointer;
}
.active-reminders-edit_btn__3YfWm:hover {
  color: #6c6c7c;
}
.active-reminders-edit__1hDxd {
  float: right;
}
.active-reminders-change_date__hgIfR {
  display: inline-block;
}
.active-reminders-delete__2FNH4 {
  display: none;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  height: 13px;
  vertical-align: bottom;
  line-height: 17px;
  color: #a9b5c9;
  float: right;
  padding-right: 3px;
  padding-left: 15px;
  background: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8' standalone='no'?%3E %3Csvg width='11px' height='13px' viewBox='0 0 11 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: Sketch 39.1 (31720) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3EPage 1%3C/title%3E %3Cdesc%3ECreated with Sketch.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='auto-answer' transform='translate(-926.000000, -589.000000)' fill='%23A9B5C9'%3E %3Cg id='Group-10' transform='translate(355.000000, 466.000000)'%3E %3Cg id='Group-13' transform='translate(562.000000, 115.000000)'%3E %3Cg id='delete'%3E %3Cpath d='M11.7884254,8 L11.7884254,9.51820776 L9,9.51820776 L9,12.0460629 L10.0797722,12.0460629 L10.0797722,20.6929592 L18.9201033,20.6929592 L18.9201033,12.0460629 L20,12.0460629 L20,9.51820776 L17.2115746,9.51820776 L17.2115746,8 L11.7884254,8 Z M12.5357588,9.5180832 L16.4642412,9.5180832 L16.4642412,8.74733338 L12.5357588,8.74733338 L12.5357588,9.5180832 Z' id='Page-1'%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E") no-repeat left;
  cursor: pointer;
}
.active-reminders-delete__2FNH4:hover {
  color: #808080;
}
.active-reminders-select_circle__3Uu9F {
  width: 23px;
  height: 23px;
  border: 1px solid #edecea;
  border-radius: 50%;
  float: left;
}
