.reply-categories-container__1Fia3 {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 180px;
  height: 100%;
  background-color: #f8fafc;
  border-right: solid 1px #cdd0d3;
  padding: 10px 10px 20px;
  overflow: auto;
}
.reply-categories-list__2vsUI {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: auto;
}
.reply-categories-item__3quRb {
  padding: 7px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 15px;
  color: #47475b;
  white-space: initial;
  word-break: break-all;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.reply-categories-item__3quRb:hover {
  background-color: #ccc;
}
.reply-categories-item_selected__1-LmM {
  background-color: #53a8ff !important;
  color: #fff;
}
.reply-categories-item_selected__1-LmM .reply-categories-favorite_icon__1gKAz {
  background-image: url(/540665534fcb65972979e6a46a903c25.png);
}
.reply-categories-item_selected__1-LmM .reply-categories-personal_icon__2_Mre {
  background-image: url(/6a578dd1aba2ddd1185883a7b9c12bfa.png);
}
.reply-categories-favorite_icon__1gKAz,
.reply-categories-personal_icon__2_Mre {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 4px;
  vertical-align: middle;
}
.reply-categories-favorite_label__2dIHB,
.reply-categories-personal_label__2wSqs {
  vertical-align: middle;
}
.reply-categories-favorite_icon__1gKAz {
  background-image: url(/9daf5284e88542cfaa608b6876cfbafa.png);
}
.reply-categories-favorite__3rYYM:hover .reply-categories-favorite_icon__1gKAz {
  background-image: url(/540665534fcb65972979e6a46a903c25.png);
}
.reply-categories-personal_icon__2_Mre {
  background-image: url(/afae74931909f903613d6619539f53ee.png);
}
.reply-categories-personal__1QTyS:hover .reply-categories-personal_icon__2_Mre {
  background-image: url(/6a578dd1aba2ddd1185883a7b9c12bfa.png);
}
.reply-categories-pagination__1uz-5 {
  text-align: center;
}
