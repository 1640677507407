.conversation-button-base__8zi5r {
  border: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 2px;
}
.conversation-button-base__8zi5r:focus {
  outline: none;
}
.conversation-button-default__1c6NM {
  padding: 0 14px;
  height: 32px;
  line-height: 33px;
}
.conversation-button-default__1c6NM.conversation-only_icon__14ElC {
  font-size: 18px;
  width: 36px;
}
.conversation-button-default__1c6NM .conversation-badge__3Tp1X {
  height: 16px;
  line-height: 16px;
}
.conversation-button-small__292EJ {
  padding: 0 13px;
  text-transform: uppercase;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.conversation-button-small__292EJ.conversation-only_icon__14ElC {
  padding-top: 9px;
  font-size: 13px;
  width: 28px;
}
.conversation-button-extra-small__1LHIw,
.conversation-next__1p8GB,
.conversation-prev__3u6OD {
  padding: 0 6px;
  text-transform: uppercase;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
}
.conversation-button-primary__NM2RB,
.conversation-next__1p8GB,
.conversation-prev__3u6OD {
  background-color: #188fea;
  color: #fff;
}
.conversation-button-primary__NM2RB:hover,
.conversation-next__1p8GB:hover,
.conversation-prev__3u6OD:hover {
  background-color: #0a71bf;
}
.conversation-button-light__1Qara {
  background-color: #fff;
  color: #0a71bf;
}
.conversation-button-transparent__2Q1_C {
  background-color: none;
  color: #fff;
  border: 1px solid #fff;
}
.conversation-button-transparent__2Q1_C:hover {
  background-color: #fff;
  color: #0a71bf;
}
.conversation-button-danger__7sr3F {
  background-color: #d85151;
  color: #fff;
}
.conversation-button-danger__7sr3F:hover {
  background-color: #c13030;
}
.conversation-button-full__2mzkk {
  width: 100%;
  text-align: center;
}
.conversation-button-secondary__Ed3rn {
  background-color: #aaa;
  color: #fff;
  font-weight: bold;
}
.conversation-button-secondary__Ed3rn:hover {
  background-color: #0a71bf;
}
.conversation-button-tertiary__hsnvf {
  color: #aaa;
  font-weight: bold;
}
.conversation-button-tertiary__hsnvf:hover {
  color: #0a71bf;
}
.conversation-button-dark__b793g {
  background-color: #0a71bf;
  color: #fff;
}
.conversation-button-dark__b793g:hover {
  background-color: #005fa7;
}
.conversation-button-only-icon__1qoI9 {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.conversation-button-icon-big__1e0qi {
  font-size: 18px;
  width: 38px;
}
.conversation-button-icon__I_k3X {
  margin-right: 5px;
}
.conversation-button-bold__3WVO- {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.conversation-button-float-right__BnyVH {
  float: right;
}
.conversation-button-with-hr__1FTpi {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 0;
}
.conversation-button-with-hr__1FTpi:after {
  display: block;
  position: relative;
  bottom: 15px;
  content: '';
  border-bottom: 1px solid #c1cdd6;
  z-index: -1;
}
.conversation-button-processing__1Bcbo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
}
.conversation-button-invisible__1ootc {
  visibility: hidden;
}
.conversation-button-tooltip__YhKPH {
  top: 38px;
  left: 50%;
  margin-left: -75px;
  color: #fff;
  width: 150px;
  font-size: 13px;
  text-align: center;
  text-transform: initial;
  position: absolute;
  padding: 5px;
  z-index: 2;
  background-color: #3e434d;
  height: 26px;
  line-height: 16px;
  opacity: 0.8;
  border-radius: 2px;
}
.conversation-button-tooltip__YhKPH:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-bottom-color: #3e434d;
  border-width: 5px;
  margin-left: -5px;
}
.conversation-button-badge__3SNn0 {
  padding: 0px 7px;
  min-width: 10px;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #f86e5f;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 40px;
}
.conversation-base__3Tw36 {
  min-width: 640px;
}
.conversation-container__FvHPv {
  position: absolute;
  top: 55px;
  right: 293px;
  bottom: 0;
  left: 0;
}
.conversation-divider__2fS09 {
  color: #a7a7b0;
  text-align: center;
  margin: 5px 0 -5px 0;
}
.conversation-unassigned_folder_container__3nUQ3 {
  right: 38px;
}
.conversation-burger__wmtD6 {
  position: relative;
  cursor: pointer;
  width: 38px;
  height: 38px;
}
.conversation-burger__wmtD6:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -9px;
  margin-top: -6px;
  width: 18px;
  height: 2px;
  background: #53a8ff;
  -webkit-box-shadow: 0 5px 0 0 #53a8ff, 0 10px 0 0 #53a8ff;
          box-shadow: 0 5px 0 0 #53a8ff, 0 10px 0 0 #53a8ff;
}
.conversation-customer_info__3JutN {
  position: absolute;
  top: 55px;
  width: 293px;
  bottom: 0;
  right: 0;
  overflow: hidden;
}
.conversation-customer_info_hidden__1xgF0 {
  position: absolute;
  background-color: #fff;
  top: 55px;
  width: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  bottom: 0;
  right: 0;
  overflow: hidden;
  border-left: 1px solid #e5e9ed;
}
.conversation-customer_info_error_panel__aZIGG {
  position: absolute;
  left: 20px;
  top: 12px;
  font-size: 20px;
  color: #f00;
}
.conversation-wrap__3eB_M {
  position: absolute;
  overflow: hidden;
}
.conversation-show__3cWo0 {
  opacity: 1;
}
.conversation-opened__3hc7x {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.conversation-form__1xP97 {
  position: absolute;
  min-height: 180px;
  left: 0;
  right: 0;
  top: calc(100% - from-height);
  bottom: 0px;
  background: #fff;
}
.conversation-header__Y0-h0 {
  width: 100%;
  background: #fff;
  height: 55px;
  padding: 10px 10px 0;
  border-bottom: 1px solid #e5e9ed;
}
.conversation-scroll__2OAmT {
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0px;
  background-color: #fff;
}
.conversation-scroll__2OAmT.conversation-scroll_with_form__2iLHG {
  bottom: 180px;
}
.conversation-scroll__2OAmT.conversation-scroll_with_messages_search_result_panel__3wmNd {
  top: 85px;
}
.conversation-tags_selector_container__1ikWq {
  position: absolute;
  right: 0;
  bottom: 40px;
  left: 0;
  height: 48px;
  background: #d0d9e4;
  padding: 6px 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.conversation-assignerComponent__2EkHc {
  left: 268px;
}
.conversation-messages_search_result_panel__1xdEt {
  height: 30px;
  background: #eee;
  width: 100%;
  font-size: 12px;
  line-height: 30px;
  padding: 0 20px;
}
.conversation-navigation__2LhNv {
  float: right;
}
.conversation-search_panel_loader__2cTC5 {
  -webkit-transform: none;
          transform: none;
}
.conversation-search_panel_loader__2cTC5 img {
  padding: 5px;
  height: 100%;
}
.conversation-next__1p8GB {
  margin-top: 4px;
  margin-left: 10px;
}
.conversation-prev__3u6OD {
  margin-top: 4px;
}
.conversation-position__Wzvk4 {
  margin-left: 10px;
}
.conversation-dialogue__1q2A2 {
  position: relative;
  overflow: hidden;
  padding-right: 10px;
}
.conversation-new_conversation_wrapper__1ctB2 {
  border-top: 1px solid #e5e9ed;
}
.conversation-new_conversation__28Acq {
  position: absolute;
  right: 22px;
  bottom: 22px;
  height: 32px;
  background-color: #53a8ff;
  border-radius: 2px;
  line-height: 32px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 0 10px;
}
.conversation-assigner_container__2TmPo {
  position: absolute;
  display: inline-block;
  top: 0;
  max-height: calc(100% - 50px);
  left: 215px;
  z-index: 2;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  border: solid 1px #e7ebf0;
  overflow: hidden;
}
.conversation-agents_height__2n8WZ {
  max-height: calc(100vh - 142px);
}
.conversation-scroll_container___BOLW {
  position: absolute;
  display: inline-block;
  width: 50px;
  top: 0;
  bottom: 0;
  text-align: center;
  overflow: hidden;
}
.conversation-scroll_item__3r_VG {
  position: absolute;
  display: block;
  height: 50px;
  width: 100%;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid' width='17' height='17' viewBox='0 0 17 17'%3E %3Cdefs%3E %3Cstyle%3E .cls-1 %7B fill: %23dde5ef; fill-rule: evenodd; %7D %3C/style%3E %3C/defs%3E %3Cpath d='M8.501,0.003 C3.806,0.003 -0.000,3.807 -0.000,8.500 C-0.000,13.193 3.806,16.998 8.501,16.998 C13.196,16.998 17.002,13.193 17.002,8.500 C17.002,3.807 13.196,0.003 8.501,0.003 ZM11.910,8.958 L9.403,6.453 L9.403,14.003 L7.598,14.003 L7.598,6.453 L5.092,8.958 L3.815,7.682 L8.500,2.998 L13.186,7.682 L11.910,8.958 Z' class='cls-1'/%3E %3C/svg%3E") no-repeat center;
  cursor: pointer;
}
.conversation-scroll_item__3r_VG:hover {
  background-color: #f8fafc;
}
.conversation-scroll_top__KXhPj {
  top: 0;
}
.conversation-scroll_bottom__3AimY {
  bottom: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.conversation-opened_conversation__oLLt_ {
  display: inline-block;
  margin-left: 50px;
  width: calc(100% - 50px);
}
.conversation-opened_conversation_with_scheduled__t4jGd {
  padding-bottom: 40px;
}
.conversation-visibility_hidden__A16Sj {
  visibility: hidden;
}
.conversation-resizable_border___W0zm {
  height: 5px;
  background-color: #e5e9ed;
  cursor: ns-resize;
}
.conversation-is_typing_block__3CbMb {
  position: absolute;
  top: -20px;
  left: 20px;
}
.conversation-is_typing_block_with_top__3ufvI {
  top: -60px;
}
.conversation-is_typing_block_dots__2l2Dj {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #188fea;
  color: #188fea;
  -webkit-animation: conversation-dotFlashing__3oH7R 1s infinite linear alternate;
          animation: conversation-dotFlashing__3oH7R 1s infinite linear alternate;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.conversation-is_typing_block_dots__2l2Dj:before,
.conversation-is_typing_block_dots__2l2Dj:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.conversation-is_typing_block_dots__2l2Dj:before {
  left: -15px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #188fea;
  color: #188fea;
  -webkit-animation: conversation-dotFlashing__3oH7R 1s infinite alternate;
          animation: conversation-dotFlashing__3oH7R 1s infinite alternate;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.conversation-is_typing_block_dots__2l2Dj:after {
  left: 15px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #188fea;
  color: #188fea;
  -webkit-animation: conversation-dotFlashing__3oH7R 1s infinite alternate;
          animation: conversation-dotFlashing__3oH7R 1s infinite alternate;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
@-webkit-keyframes conversation-dotFlashing__3oH7R {
  0% {
    background-color: #188fea;
  }
  50%, 100% {
    background-color: #edf6ff;
  }
}
@keyframes conversation-dotFlashing__3oH7R {
  0% {
    background-color: #188fea;
  }
  50%, 100% {
    background-color: #edf6ff;
  }
}
