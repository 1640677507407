.button-button__tF-Eo {
  display: inline-block;
  border-radius: 2px;
  background-color: #53a8ff;
  border: solid 1px #56aafc;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  vertical-align: top;
  padding: 8px 10px 7px;
}
.button-button__tF-Eo:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
.button-button__tF-Eo:focus,
.button-button__tF-Eo:active {
  outline: none;
}
.button-button_disabled__3QwMo {
  color: #d4d4d4;
  border-color: #d4d4d4;
  background-color: #fff;
}
.button-button_white__c6Q4y {
  background-color: #fff;
  border-color: #56aafc;
  color: #53a8ff;
  margin-left: 5px;
}
.button-button_red__1Au_U {
  background-color: #fc6453;
  border-color: #fc6453;
  color: #fff;
}
