.idle-popup-container__2Pg8y {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0,0,0,0.55);
  overflow: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 10;
}
.idle-popup-container__2Pg8y .idle-popup-modal__2AViO {
  padding: 20px;
  width: 600px;
  height: 435px;
  border-radius: 3px;
  background-color: #fff;
  margin: auto;
  z-index: 1;
  position: relative;
  overflow: initial;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: center;
}
.idle-popup-container__2Pg8y .idle-popup-danger_panel__2bxU- {
  display: block;
  background: #f00;
  border-radius: 3px;
  padding: 5px 20px;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}
.idle-popup-container__2Pg8y .idle-popup-text__2-WWL {
  font-size: 35px;
  margin-bottom: 10px;
}
.idle-popup-container__2Pg8y .idle-popup-minutes__2y8hA {
  font-size: 35px;
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}
.idle-popup-container__2Pg8y .idle-popup-btn__7y0cq {
  color: #fff;
  background-color: #56aafc;
  text-transform: uppercase;
  border: none;
  border-radius: 3px;
  border: solid 1px #56aafc;
  font-family: Hind;
  font-size: 20px;
  cursor: pointer;
  padding: 8px 30px 7px;
  font-weight: 600;
}
.idle-popup-container__2Pg8y .idle-popup-btn__7y0cq:disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: auto;
}
