.index-modal__h0W1a {
  padding: 16px 10px 10px;
  min-width: 421px;
}
.index-modal__header__3osGZ {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #2c2d40;
  margin-bottom: 4px;
}
.index-modal__text__2t0ni {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}
.index-modal__h0W1a .index-modal_btn_block__1k2hA {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  gap: 0 16px;
}
.index-modal__h0W1a .index-modal_btn_block__delete__IKgk_ {
  background-color: #eb5757;
  color: #fff;
  border: none;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.index-modal__h0W1a .index-modal_btn_block__delete__IKgk_:hover {
  background-color: rgba(235,87,87,0.616);
}
