.qa-statistic-head-column__1WP5x {
  cursor: pointer;
}
.qa-statistic-fullname__2TIxo {
  width: 250px;
  text-align: left;
  padding-left: 9px;
}
.qa-statistic-teamlead__11N90 {
  width: 250px;
}
.qa-statistic-success_row__1zh-y {
  background-color: rgba(0,255,3,0.2);
}
.qa-statistic-select__2uD8C {
  position: relative;
  padding: 0 5px;
}
.qa-statistic-select__2uD8C:hover {
  background-color: #e8f3f1;
}
.qa-statistic-select__options__2LWZb {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  top: 18px;
  width: 100%;
}
