.groups-header__xYYjH {
  font-family: 'Hind', sans-serif;
  color: #47475b;
  margin: 20px;
}
.groups-title__2Tlrb {
  display: inline-block;
  padding-top: 7px;
  font-weight: bold;
  font-size: 16px;
}
.groups-button__2fBRP {
  display: inline-block;
  border-radius: 2px;
  background-color: #53a8ff;
  border: solid 1px #56aafc;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  vertical-align: top;
  padding: 8px 10px 7px;
}
.groups-button__2fBRP:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
.groups-button__2fBRP:focus,
.groups-button__2fBRP:active {
  outline: none;
}
.groups-button_right__xh8H_ {
  float: right;
}
.groups-button_disabled__282zv {
  color: #d4d4d4;
  border-color: #d4d4d4;
  background-color: #fff;
}
.groups-button_cancel__3Fd3O {
  background-color: #fff;
  border-color: #56aafc;
  color: #53a8ff;
  margin-left: 5px;
}
.groups-input__88kgN {
  padding: 6px;
  width: calc(100% - 125px);
}
.groups-input__88kgN:focus,
.groups-input__88kgN:active {
  outline: none;
}
.groups-new_controls__urMw7 {
  margin-top: 15px;
}
.groups-error__2Z5ko {
  margin-top: 20px;
  text-align: center;
  color: #f87b6e;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.groups-position_relative__1_Y_w {
  position: relative;
}
.groups-text_center__1vWMf {
  text-align: center;
}
