.customer_comment-container_small__38Hdw {
  display: block;
  overflow: hidden;
  height: 65px;
}
.customer_comment-container_full__1eR9I {
  display: block;
}
.customer_comment-container_all__1Kmon {
  position: relative;
}
.customer_comment-toggle__1Ls_t {
  text-align: center;
  color: #57697b;
  padding: 3px;
  border: 1px solid #d7dee9;
}
.customer_comment-textarea__2riyr textarea {
  padding: 4px;
  background: #fff;
  border: 1px solid #d7dee9;
  border-radius: 2px;
}
.customer_comment-textarea__2riyr textarea:focus {
  outline: none;
}
.customer_comment-title_icon__3QG0r {
  height: 17px;
  width: 15px;
}
.customer_comment-edit_button__1et1_ {
  display: inline-block;
  padding: 1px 5px;
  border: 0;
  background: transparent;
  position: absolute;
  top: -27px;
  right: 0;
}
