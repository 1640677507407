.styles-container__103Qq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.styles-container__103Qq.styles-error__3HxUz {
  border: 1px solid #f00;
}
.styles-container__103Qq input,
.styles-container__103Qq button {
  width: 32px;
  height: 32px;
  border: 1px solid #e6eaef;
  text-align: center;
}
.styles-container__103Qq input {
  border-left: none;
  border-right: none;
}
.styles-container__103Qq .styles-btn_left__3XZ0R,
.styles-container__103Qq .styles-btn_right__12pYy {
  background-repeat: no-repeat;
  background-position: center;
}
.styles-container__103Qq .styles-btn_left__3XZ0R {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03LjQxIDEwLjU5TDIuODMgNkw3LjQxIDEuNDFMNiAwTDAgNkw2IDEyTDcuNDEgMTAuNTlaIiBmaWxsPSIjMUU4QUY2Ii8+Cjwvc3ZnPgo=");
}
.styles-container__103Qq .styles-btn_right__12pYy {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjU4OTk5NiAxMC41OUw1LjE3IDZMMC41ODk5OTYgMS40MUwyIDBMOCA2TDIgMTJMMC41ODk5OTYgMTAuNTlaIiBmaWxsPSIjMUU4QUY2Ii8+Cjwvc3ZnPgo=");
}
