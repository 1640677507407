.styles-no_data_container__Q1tXS {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 16px 0;
  padding: 20px 0;
}
.styles-no_data_container__text__1JA5k {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #595a6b;
}
.styles-no_data_container__sad_icon__2Hz9W {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.49 2C6.97 2 2.5 6.48 2.5 12C2.5 17.52 6.97 22 12.49 22C18.02 22 22.5 17.52 22.5 12C22.5 6.48 18.02 2 12.49 2ZM12.5 20C8.08 20 4.5 16.42 4.5 12C4.5 7.58 8.08 4 12.5 4C16.92 4 20.5 7.58 20.5 12C20.5 16.42 16.92 20 12.5 20ZM16 11C16.83 11 17.5 10.33 17.5 9.5C17.5 8.67 16.83 8 16 8C15.17 8 14.5 8.67 14.5 9.5C14.5 10.33 15.17 11 16 11ZM9 11C9.83 11 10.5 10.33 10.5 9.5C10.5 8.67 9.83 8 9 8C8.17 8 7.5 8.67 7.5 9.5C7.5 10.33 8.17 11 9 11ZM12.5 13.5C10.17 13.5 8.19 14.96 7.39 17H17.61C16.81 14.96 14.83 13.5 12.5 13.5Z' fill='%23AAB6C8'/%3E%3C/svg%3E%0A");
  display: block;
  width: 24px;
  height: 24px;
}
