.item-activities-open_button__2Fw_K {
  border: 0px solid;
  width: 26px;
  height: 26px;
  border-radius: 3px;
  background-color: transparent;
  padding: 0;
}
.item-activities-open_button__2Fw_K span {
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  margin: 0 2px;
  line-height: 26px;
  background-color: #6c6c7c;
}
.item-activities-open_button_is_open__1IX50 {
  background-color: #53a8ff;
}
.item-activities-open_button_is_open__1IX50 span {
  background-color: #fff;
}
.item-activities-container__vSFqf {
  position: relative;
  display: inline-block;
}
.item-activities-activities__102fo {
  position: absolute;
  top: 35px;
  right: 0;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.11);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.11);
  border: solid 1px #dadae6;
  background-color: #fff;
  z-index: 1;
}
.item-activities-activities_list__2gqDl {
  list-style-type: none;
  padding: 0;
}
.item-activities-activities_list__2gqDl li {
  display: inline-block;
  padding: 6px 15px;
  height: 35px;
  width: 160px;
  cursor: pointer;
}
.item-activities-activities_list__2gqDl li:hover {
  background-color: #f0f0f0;
}
.item-activities-activities_list__2gqDl li a {
  color: #323d49;
  text-decoration: none;
}
