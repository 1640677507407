.take-new-button-container__2F-U6 {
  display: inline-block;
  margin-left: 30px;
}
.take-new-button-button__Vce1I {
  width: 115px;
  height: 35px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.take-new-button-button__Vce1I:focus,
.take-new-button-button__Vce1I:active {
  outline: none;
}
.take-new-button-button_disabled__3uhM1 {
  background-color: #e6eaef;
  color: #a9b5c9;
  cursor: auto;
  border: 1px solid #e6eaef;
}
.take-new-button-button_active__sON8R {
  background-color: #53a8ff;
  border: solid 1px #53a8ff;
  color: #fff;
}
.take-new-button-button_strong__3_8Ou {
  background-color: #f87b6e;
  border: solid 1px #f87b6e;
  color: #fff;
}
.take-new-button-button_green__1aqs5 {
  background-color: #76d466;
  border: solid 1px #76d466;
  color: #fff;
}
