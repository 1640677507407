.file-uploader-container__2KG-C {
  display: inline-block;
  height: 100%;
  font-family: Hind;
  max-width: calc(100% - 200px);
}
.file-uploader-upload_button__3KWuW {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='21' viewBox='0 0 23 21'%3E %3Cpath fill='none' fill-rule='evenodd' stroke='%2386C2FF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M10.969 20l7.278-7.278.06-.062 1.833-1.832a5.356 5.356 0 1 0-7.574-7.574l-1.833 1.832.002.001-7.34 7.34c-.35.35-.664.658-.94.938l.94-.94-1.33 1.33a3.637 3.637 0 1 0 5.144 5.144l1.98-1.979-1.668 1.67 8.304-8.305-.592.592 1.354-1.352a1.949 1.949 0 1 0-2.756-2.756L12.476 8.12l-6.273 6.273'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  width: 22px;
  height: 20px;
  cursor: pointer;
}
.file-uploader-upload_button_top__1vCRW {
  margin-top: -30px;
  -webkit-animation-name: file-uploader-move_to_top__2AOfo;
          animation-name: file-uploader-move_to_top__2AOfo;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}
.file-uploader-files_container__1tPFY {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.file-uploader-file_container__2ti-5 {
  width: 97px;
  height: 29px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}
.file-uploader-file_container_removed__3ne02 {
  width: 0;
  margin-right: 0;
  -webkit-animation-name: file-uploader-file_removed__naaSX;
          animation-name: file-uploader-file_removed__naaSX;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
.file-uploader-file_container__2ti-5 .file-uploader-cancel_hidden__3pjLv {
  opacity: 0;
  -webkit-animation-name: file-uploader-slow_hidden__2p3vF;
          animation-name: file-uploader-slow_hidden__2p3vF;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
.file-uploader-file_container__2ti-5:hover .file-uploader-cancel_hidden__3pjLv {
  opacity: 1;
  -webkit-animation-name: file-uploader-slow_visibility__AEn6F;
          animation-name: file-uploader-slow_visibility__AEn6F;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
.file-uploader-file_state__2JhPd {
  display: inline-block;
  width: 72px;
  height: 17px;
  margin-right: 8px;
  position: relative;
}
.file-uploader-filename__36f3e {
  display: inline-block;
  font-size: 12px;
  text-align: center;
  color: #6c6c7c;
  width: 72px;
  height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 2px;
}
.file-uploader-progress__1PiIH {
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  right: 0;
  top: 0;
  background: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg' stroke='%2353a8ff'%3E %3Cg fill='none' fill-rule='evenodd'%3E %3Cg transform='translate(1 1)' stroke-width='2'%3E %3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3E %3Cpath d='M36 18c0-9.94-8.06-18-18-18'%3E %3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='1s' repeatCount='indefinite'/%3E %3C/path%3E %3C/g%3E %3C/g%3E %3C/svg%3E") no-repeat;
  background-size: cover;
}
.file-uploader-progress_hide__3Z9_z {
  display: none;
}
.file-uploader-cancel__24vuL {
  display: inline-block;
  width: 17px;
  height: 17px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3E %3Cg fill='none' fill-rule='evenodd'%3E %3Ccircle cx='8.5' cy='8.5' r='8.5' fill='%23F87B6E'/%3E %3Cg stroke='%23FFF'%3E %3Cpath d='M5.697 5.697l6.06 6.06M11.757 5.697l-6.06 6.06'/%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  cursor: pointer;
  vertical-align: baseline;
}
.file-uploader-error__1jNlN {
  font-size: 12px;
  color: f87b6e;
}
@-webkit-keyframes file-uploader-move_to_top__2AOfo {
  from {
    margin-top: 0;
  }
  to {
    margin-top: -30px;
  }
}
@keyframes file-uploader-move_to_top__2AOfo {
  from {
    margin-top: 0;
  }
  to {
    margin-top: -30px;
  }
}
@-webkit-keyframes file-uploader-slow_visibility__AEn6F {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes file-uploader-slow_visibility__AEn6F {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes file-uploader-slow_hidden__2p3vF {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes file-uploader-slow_hidden__2p3vF {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes file-uploader-file_removed__naaSX {
  from {
    width: 97px;
    margin-right: 10px;
  }
  to {
    width: 0;
    margin-right: 0;
  }
}
@keyframes file-uploader-file_removed__naaSX {
  from {
    width: 97px;
    margin-right: 10px;
  }
  to {
    width: 0;
    margin-right: 0;
  }
}
