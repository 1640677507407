.take-new-conversation-stats__Z4M6X {
  display: inline-block;
  color: #f87b6e;
  font-family: Hind;
  font-size: 16px;
  font-weight: 500;
  color: #919aa2;
}
.take-new-conversation-stats__Z4M6X strong {
  color: #6c6c7c;
}
.take-new-conversation-shift_bar__381Wi {
  border-bottom: 2px solid #e6eaef;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 10px 10px;
  margin-bottom: 10px;
}
.take-new-conversation-shift_bar__381Wi .take-new-conversation-col__1Qcwy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.take-new-conversation-shift_bar__381Wi .take-new-conversation-item__X104w + .take-new-conversation-item__X104w {
  margin-left: 15px;
}
.take-new-conversation-shift_bar__381Wi .take-new-conversation-cnt__34MGJ {
  font-weight: 600;
  display: inline-block;
  margin-left: 5px;
}
.take-new-conversation-shift_bar__381Wi .take-new-conversation-cnt__34MGJ.take-new-conversation-danger__3ztwg {
  color: #f00;
}
.take-new-conversation-shift_bar__381Wi .take-new-conversation-cnt__34MGJ.take-new-conversation-success__3ZYlh {
  color: #219653;
}
.take-new-conversation-bage__r_a-1 {
  padding: 2px 6px;
  background-color: #fff;
  border-radius: 3px;
  color: #1e8af6;
  border: 1px solid #092f55;
}
