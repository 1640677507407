.index-container__1xrvb {
  width: 220px;
  position: fixed;
  right: 0;
  bottom: 0;
  background: #fff;
  padding: 10px;
}
.index-btn_less__10DOW {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  padding: 8px 16px;
  width: 200px;
  height: 44px;
  background: #f2f5f8;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
  margin-bottom: 15px;
  width: 100%;
}
.index-icon_less__13_DP {
  display: inline-block;
  width: 28px;
  height: 28px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: transparent;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjI4IiBoZWlnaHQ9IjI4IiByeD0iMTQiIGZpbGw9IiM0NzQ4NUIiLz4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzc4Ml8xMTM1NCkiPgo8cGF0aCBkPSJNMTAuMTc1IDExLjY1ODNMMTQgMTUuNDc1TDE3LjgyNSAxMS42NTgzTDE5IDEyLjgzMzNMMTQgMTcuODMzM0w5IDEyLjgzMzNMMTAuMTc1IDExLjY1ODNaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzc4Ml8xMTM1NCI+CjxyZWN0IHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0id2hpdGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQgNC41KSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=");
}
.index-icon_count__2xrFB {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 28px;
  height: 28px;
  background: #eb5757;
  color: #fff;
  border-radius: 50%;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  font-style: normal;
}
.index-icon_count__active__229Sq {
  background: #ffe0e8;
}
