.customer-tickets-error__16uJl {
  text-align: center;
  color: #f87b6e;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.customer-tickets-no_data__Gd6qV {
  font-family: Hind;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #57697b;
  text-align: center;
}
.customer-tickets-tickets__1Vq_W {
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
}
.customer-tickets-ticket_container__2TSoV {
  cursor: pointer;
  padding: 15px 15px 0;
}
.customer-tickets-indicator__3_0Bh {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 1px;
  background-color: #a9b5c9;
  margin-right: 7px;
}
.customer-tickets-ticket_type__kPIBn {
  font-size: 13px;
  letter-spacing: 0.1px;
  color: #2c2c40;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}
.customer-tickets-reference__FHQHL {
  font-size: 15px;
  letter-spacing: 0.1px;
  color: #2c2c40;
  margin-bottom: 5px;
  word-break: break-all;
}
.customer-tickets-assignee_group__3_Yit {
  font-weight: 600;
  color: #a9b5c9;
}
.customer-tickets-status__5kSLT {
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #919aa2;
  margin-bottom: 15px;
}
.customer-tickets-divider__3CK9K {
  height: 2px;
  width: 100%;
  background-color: #e6eaef;
}
