.condition-modal-container__2k23M {
  display: inline-block;
  width: 400px;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  padding: 22px 20px 30px 20px;
}
.condition-modal-text__In4q1 {
  font-size: 18px;
  text-align: center;
  color: #47485b;
  margin: 15px 0 20px;
  font-weight: bold;
}
.condition-modal-button__3emGJ {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}
.condition-modal-button__3emGJ + .condition-modal-button__3emGJ {
  margin-left: 10px;
}
.condition-modal-btns_container__3MHcZ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 20px;
}
