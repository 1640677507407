.dashboard-container__1T3Ya {
  font-family: Hind;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.dashboard-tabs__3zhpS {
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 15px 0 10px 5px;
  width: 15%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  vertical-align: top;
}
.dashboard-tab__xiYgu {
  display: block;
  width: 100%;
  padding-right: 10px;
}
.dashboard-tab_link__2DbNm {
  display: inline-block;
  border-bottom: none;
  position: relative;
  list-style: none;
  padding: 7px 10px 7px 10px;
  cursor: pointer;
  background: #6c6c7c;
  color: #fff;
  margin: 0px 0px 2px 4px;
  text-decoration: none;
  width: 100%;
}
.dashboard-tab_active__3VSqg {
  background: #f8f9fb;
  color: #6c6c7c;
}
.dashboard-chart__1toY3 {
  display: inline-block;
  width: 85%;
  height: 100%;
}
