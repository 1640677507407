.admin-wrapper-component-header__1lyVC {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 20px;
  border-bottom: solid 1px #e6eaef;
  height: 50px;
}
.admin-wrapper-component-agent_selector__JXim2 {
  display: inline-block;
  vertical-align: middle;
}
.admin-wrapper-component-component_selector__1_LZt {
  width: 150px;
  border: 1px solid #e2e6ed;
  border-radius: 3px;
}
.admin-wrapper-component-component_selector__1_LZt.admin-wrapper-component-error__2Jlbz {
  border: 1px solid #f86e5f;
}
.admin-wrapper-component-search_field__10B7f {
  display: inline-block;
  height: 36px;
  width: 300px;
  border: 1px solid #e2e6ed;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px;
  margin: 0 10px;
  vertical-align: middle;
}
.admin-wrapper-component-search_field_input__1E4Eu {
  height: 34px;
}
