.index-report_header__2FQ5d {
  background: #f2f5f8;
  padding: 10px 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.index-report_header--loading__22mZc {
  opacity: 0.5;
  pointer-events: none;
}
.index-report_header__2FQ5d .index-report_header_left_block__3NBI_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0 16px;
}
.index-report_header__2FQ5d .index-report_header_left_block__icon__2lzU4 {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.8333 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM15.8333 15.8333H4.16667V4.16667H15.8333V15.8333ZM9.16667 14.1667H10.8333V10.8333H14.1667V9.16667H10.8333V5.83333H9.16667V9.16667H5.83333V10.8333H9.16667V14.1667Z' fill='black'/%3E%3C/svg%3E%0A");
  display: block;
  cursor: pointer;
}
.index-report_header__2FQ5d .index-report_header_left_block__icon--open__2W629 {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM14.1667 10.8333H5.83333V9.16667H14.1667V10.8333Z' fill='%232C2D40'/%3E%3C/svg%3E%0A");
}
.index-report_header__2FQ5d .index-report_header_left_block__status__257zz {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.index-report_header__2FQ5d .index-report_header_left_block__status--issue__20uEr {
  background-color: #eb5757;
}
.index-report_header__2FQ5d .index-report_header_left_block__status--comment__3q47p {
  background-color: #58aafc;
}
.index-report_header__2FQ5d .index-report_header_left_block__status--good_job__5KKqh {
  background-color: #76d466;
}
.index-report_header__2FQ5d .index-report_header_left_block__status_header__1w11N {
  color: #2c2d40;
  font-size: 14px;
  line-height: 22px;
}
.index-report_header__delete_icon__1SoDd {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.00008 15.8333C5.00008 16.75 5.75008 17.5 6.66675 17.5H13.3334C14.2501 17.5 15.0001 16.75 15.0001 15.8333V5.83333H5.00008V15.8333ZM6.66675 7.5H13.3334V15.8333H6.66675V7.5ZM12.9167 3.33333L12.0834 2.5H7.91675L7.08342 3.33333H4.16675V5H15.8334V3.33333H12.9167Z' fill='%236C6D7C'/%3E%3C/svg%3E%0A");
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.index-report_header__delete_icon__1SoDd:hover {
  opacity: 0.5;
}
