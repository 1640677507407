.double-column-col1__syrZi {
  position: absolute;
  top: 0;
  width: 30%;
  bottom: 0;
  left: 70px;
  background-color: #fff;
  overflow: auto;
}
.double-column-col2__2IAim {
  position: absolute;
  top: 0;
  width: calc(70% - 70px);
  bottom: 0;
  left: calc(30% + 70px);
  overflow-x: auto;
  overflow-y: hidden;
  background-color: #f2f5f9;
}
.double-column-col2_overflow__1kdEh {
  overflow-y: auto;
  position: absolute;
  top: 0;
  width: calc(70% - 70px);
  bottom: 0;
  left: calc(30% + 70px);
  background-color: #f2f5f9;
}
