.ranking-filter-container__1HQ4_ {
  background-color: #f2f5f8;
  padding: 12px 15px 9px 15px;
  border: solid 1px #e6eaef;
  position: relative;
}
.ranking-filter-calendar__2V0k8 {
  display: inline-block;
  vertical-align: bottom;
}
.ranking-filter-textbox__394uE {
  border: solid 1px #e6eaef;
  border-radius: 3px;
  margin-bottom: 10px;
}
.ranking-filter-textbox_input__itr9u {
  color: #47475b;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  height: 30px !important;
}
.ranking-filter-filters__VSBvu {
  display: inline-block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background-color: #fff;
  border: solid 1px #e6eaef;
  text-align: center;
  vertical-align: bottom;
  padding: 7px;
  float: right;
}
.ranking-filter-filters__VSBvu:hover {
  border-color: #53a8ff;
}
.ranking-filter-filters_selected__2sExj {
  border-color: #53a8ff;
}
.ranking-filter-filters_nonempty__OtH9p svg * {
  fill: #53a8ff;
}
.ranking-filter-filters__tag__3abEs {
  display: inline-block;
  border-radius: 3px;
  background-color: #f2f5f8;
  border: solid 1px #919aa1;
  padding: 4px 8px;
  margin: 0 5px 5px 0;
}
.ranking-filter-filters__tag_label__xgCwV {
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #6c6c7c;
  margin-right: 8px;
}
.ranking-filter-filters__tag_icon__l0jvD {
  display: inline-block;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='9px' height='8px' viewBox='0 0 9 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3E4661414C-B36B-4F09-B400-5626A21FCA79%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='QA-dashboard_selected-filters' transform='translate(-144.000000, -102.000000)' stroke='%236C6C7C'%3E %3Cg id='Group-4' transform='translate(81.000000, 93.000000)'%3E %3Cg id='close' transform='translate(63.000000, 9.000000)'%3E %3Cpath d='M8,0.4 L0.646089476,7.75391052' id='Path-14'%3E%3C/path%3E %3Cpath d='M8,0.4 L0.646089476,7.75391052' id='Path-14' transform='translate(4.323045, 4.076955) scale(-1, 1) translate(-4.323045, -4.076955) '%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  width: 8px;
  height: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}
.ranking-filter-filters__tags__19ORo {
  margin-top: 15px;
  overflow-y: auto;
  max-height: 40%;
}
.ranking-filter-selectors-container__3m9n1 {
  position: absolute;
  right: 20px;
  margin-top: 7px;
  width: 235px;
  padding: 10px;
  border-radius: 1px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 4px 0 rgba(0,0,0,0.07);
          box-shadow: 0 0 4px 0 rgba(0,0,0,0.07);
  border: solid 1px #d3d3d3;
  z-index: 1;
}
.ranking-filter-selectors-container_arrow__3f_6x {
  position: absolute;
  top: -4px;
  right: 6px;
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-color: #d3d3d3;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 7px;
  height: 7px;
  background-color: #fff;
}
.ranking-filter-selectors-container__apply__1QaaP {
  display: inline-block;
  width: 100%;
  height: 30px;
  border-radius: 3px;
  background-color: #56aafc;
  border: solid 1px #56aafc;
  margin-top: 5px;
  margin-bottom: 15px;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #fff;
}
.ranking-filter-selectors-container__apply__1QaaP:focus {
  outline: none;
}
.ranking-filter-selectors-container__apply__1QaaP:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
.ranking-filter-selectors-container__reset__3oPog {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.ranking-filter-selectors-container__reset-button__2kv2b {
  border: none;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #f87b6e;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='15px' height='15px' viewBox='0 0 15 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3E9EAE7ADF-6219-4643-98DF-2FF4FFD653E9%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='QA-dashboard_filters' transform='translate(-236.000000, -331.000000)'%3E %3Cg id='filters' transform='translate(162.000000, 85.000000)'%3E %3Cg id='Group-4' transform='translate(75.000000, 245.000000)'%3E %3Cg id='reset' transform='translate(0.000000, 2.000000)'%3E %3Ccircle id='Oval-6' stroke='%23F77C70' fill='%23FFFFFF' cx='6.5' cy='6.5' r='6.5'%3E%3C/circle%3E %3Cg id='Group-29' transform='translate(3.823529, 3.823529)' stroke='%23F87B6E'%3E %3Cpath d='M4.94753119,0.405409985 L0.405409985,4.94753119' id='Path-13'%3E%3C/path%3E %3Cpath d='M4.94753119,0.405409985 L0.405409985,4.94753119' id='Path-13' transform='translate(2.676471, 2.676471) scale(-1, 1) translate(-2.676471, -2.676471) '%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  padding-left: 20px;
  padding-top: 2px;
  margin-bottom: 5px;
}
.ranking-filter-selectors-container__reset-button__2kv2b:active,
.ranking-filter-selectors-container__reset-button__2kv2b:focus {
  outline: none;
}
.ranking-filter-select__2kxG8 {
  display: block;
  border: solid 1px #e6eaef;
  padding: 8px;
  margin-bottom: 6px;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #a9b5c9;
  border-radius: 3px;
}
.ranking-filter-select__options__1EOVz {
  width: 213px;
  margin-left: -9px;
  margin-top: 5px;
}
.ranking-filter-float-right__3p5Z6 {
  float: right;
}
.ranking-filter-next-unranked-btn__223SQ {
  margin-top: 10px;
  padding: 5px 25px;
  text-transform: uppercase;
}
