.conversation-tags-container__1jtaf {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.conversation-tags-icon__1JlYm {
  display: inline-block;
  width: 20px;
  height: 18px;
  cursor: pointer;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='21' viewBox='0 0 23 21'%3E %3Cg fill='none' fill-rule='evenodd' stroke='%2386C2FF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.8'%3E %3Cpath d='M8.74 2L5.183 19.326M17.33 2l-3.558 17.326M1.744 15h17.253M3.516 6h17.253'/%3E %3C/g%3E %3C/svg%3E") center no-repeat;
}
.conversation-tags-list_container__3ttDo {
  position: absolute;
  bottom: 100%;
  border-radius: 3px;
  z-index: 2;
  margin-bottom: 10px;
  z-index: 3;
}
.conversation-tags-tooltip__a--BV {
  display: inline-block;
  position: absolute;
  width: 16px;
  background-color: #fc6353;
  border: solid 2px #fff;
  border-radius: 50px;
  font-size: 12px;
  top: 0;
  right: 0;
  color: #fff;
  text-align: center;
  margin-top: -6px;
  margin-right: -6px;
}
