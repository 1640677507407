.agent-list-item-datepicker_changed__3yfuc div {
  color: #53a8ff;
}
.agent-list-item-agent__fV4Gl {
  border: 1px solid #e6eaef;
  background-color: #fff;
}
.agent-list-item-agent__fV4Gl:hover {
  background-color: #edf6ff;
}
.agent-list-item-agent__fV4Gl:first-child {
  border-radius: 3px 3px 0 0;
}
.agent-list-item-agent__fV4Gl:last-child {
  border-radius: 0 0 3px 3px;
}
.agent-list-item-agent__fV4Gl td {
  border-right: 1px solid #e6eaef;
  border-collapse: collapse;
}
.agent-list-item-agent__image__12KIB {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin: 7px 15px 7px 12px;
  background-color: #dde5ef;
}
.agent-list-item-agent__label__3lmsx {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  font-family: Hind;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #47475b;
}
.agent-list-item-agent__label__notify_panels__3aFjw {
  border-radius: 2px;
  background-color: #d8544f;
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  padding: 1px 10px 0px;
}
.agent-list-item-agent__label_secondary__184l_ {
  font-size: 14px;
  color: #6c6c7c;
}
.agent-list-item-agent__status__ky2YT {
  color: #f87b6e;
}
.agent-list-item-agent__status-point__1DFqq {
  display: inline-block;
  width: 7px;
  height: 7px;
  background-color: #f87b6e;
  border-radius: 5px;
  margin-right: 6px;
}
.agent-list-item-agent__status-point_online__AIU5Q {
  background-color: #76d466;
}
.agent-list-item-agent__status-point_online__AIU5Q + .agent-list-item-agent__status__ky2YT {
  color: #76d466;
}
.agent-list-item-agent__status-point_break__39PbB {
  background-color: #feaa06;
}
.agent-list-item-agent__status-point_break__39PbB + .agent-list-item-agent__status__ky2YT {
  color: #feaa06;
}
.agent-list-item-agent__link__3QBoB {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.agent-list-item-agent__link_center__1nNJD {
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: block;
  width: 100%;
}
.agent-list-item-agent__row__3jrxJ {
  word-break: break-word;
  padding: 0 5px;
  width: 75px;
}
.agent-list-item-agent__row_center__zhu0l {
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  max-width: 75px;
}
.agent-list-item-agent__row_item__22TkI {
  width: 75px;
}
.agent-list-item-agent__row_min__31mJF {
  width: 50px;
}
.agent-list-item-agent__row_name__1GTzp {
  width: 250px;
}
.agent-list-item-agent__row_location__1TtOi {
  width: 200px;
}
.agent-list-item-agent__row_role__3lmWC {
  width: 75px;
}
.agent-list-item-agent__row_status__3q63G {
  width: 80px;
}
.agent-list-item-agent__row_counter__2XzYE {
  width: 75px;
}
.agent-list-item-agent__fV4Gl a {
  text-decoration: none;
}
.agent-list-item-agent__fV4Gl .agent-list-item-icon_remove__22HSm {
  border: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  position: relative;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%232a86e1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='3 6 5 6 21 6'%3E%3C/polyline%3E%3Cpath d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'%3E%3C/path%3E%3Cline x1='10' y1='11' x2='10' y2='17'%3E%3C/line%3E%3Cline x1='14' y1='11' x2='14' y2='17'%3E%3C/line%3E%3C/svg%3E");
}
.agent-list-item-agent__fV4Gl .agent-list-item-rc_confirm__5t2qt {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: transparent;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjYiIGZpbGw9IiMyQTg2RTEiLz4KPHBhdGggZD0iTTYgOC4yODU3MUw3LjgxODE4IDEwTDExIDciIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS4zMzI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}
.agent-list-item-agent__fV4Gl .agent-list-item-rc_cancel__3zeOk {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: transparent;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjYiIGZpbGw9IiNFQjU3NTciLz4KPHBhdGggZD0iTTEwIDZMNiAxMCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjMzMjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNiA2TDEwIDEwIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuMzMyNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
}
.agent-list-item-agent__fV4Gl .agent-list-item-rc_break_at__MrxQp {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: transparent;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDIwMDEwOTA0Ly9FTiIKICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4wIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiB3aWR0aD0iMjQ0LjAwMDAwMHB0IiBoZWlnaHQ9IjIxOC4wMDAwMDBwdCIgdmlld0JveD0iMCAwIDI0NC4wMDAwMDAgMjE4LjAwMDAwMCIKIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIG1lZXQiPgoKPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsMjE4LjAwMDAwMCkgc2NhbGUoMC4xMDAwMDAsLTAuMTAwMDAwKSIKZmlsbD0iIzFmYjE0MSIgc3Ryb2tlPSJub25lIj4KPHBhdGggZD0iTTExNjYgMTg4OCBjLTEyIC0xNyAtMTYgLTQ5IC0xNiAtMTM0IDAgLTEyNSAxMyAtMTU2IDYzIC0xNTYgNTIgMAo1NyAxMyA1NyAxNDkgMCA2OCAtNSAxMzMgLTEwIDE0NCAtMTUgMjggLTc0IDI2IC05NCAtM3oiLz4KPHBhdGggZD0iTTE0NDAgMTgzMCBjLTE3IC0xNyAtMjAgLTMzIC0yMCAtOTcgMCAtOTggNiAtMTE4IDM2IC0xMzIgMjkgLTEzIDU4Ci0yIDc0IDI5IDE2IDMxIDEzIDE3MiAtNiAxOTggLTE5IDI4IC01NyAyOSAtODQgMnoiLz4KPHBhdGggZD0iTTkxNyAxNzczIGMtMjUgLTEzIC0yNyAtMTkgLTI3IC03NiAwIC0zNCA0IC02NyA4IC03MyAxMyAtMjEgNDcgLTMyCjY5IC0yMyAzMCAxMSA0MyAzOSA0MyA5MSAwIDc0IC00MSAxMDkgLTkzIDgxeiIvPgo8cGF0aCBkPSJNNjUyIDE0MzIgYy0xMiAtMTIgLTE4IC0zMSAtMTcgLTUzIDIgLTE5IDM1IC0yNTQgNzQgLTUyNCA3NiAtNTI1Cjc0IC01MTcgMTQxIC01NjMgMzMgLTIyIDM3IC0yMiAzNjIgLTIyIDMwMyAwIDMzMiAxIDM2NiAxOSA1MSAyNiA3NyA3OCA5MgoxODYgMTggMTMwIDEzIDEyNSAxMjYgMTI1IDEwOSAwIDE1NiAxNyAxOTcgNzIgMzMgNDUgMzUgNTIgNTcgMjA1IDMyIDIxNSAyNgoyNTkgLTQzIDMyMCAtNDMgMzggLTkxIDUzIC0xNzAgNTMgbC01OCAwIDcgNTMgYzEyIDg3IDEwIDExOCAtMTEgMTMzIC0xNSAxMgotMTE1IDE0IC01NjIgMTQgLTUxMyAwIC01NDQgLTEgLTU2MSAtMTh6IG0xMjcyIC0zMjMgYzMyIC0yNSAzMiAtMzYgNiAtMjE1Ci0xMyAtOTAgLTI2IC0xNDQgLTM3IC0xNTYgLTEzIC0xNSAtMzIgLTE4IC0xMDAgLTE4IC03MiAwIC04MyAyIC04MyAxOCAwIDkKMTIgMTAxIDI3IDIwNSBsMjYgMTg3IDY3IDAgYzUzIDAgNzMgLTQgOTQgLTIxeiIvPgo8L2c+Cjwvc3ZnPgo=");
}
