.section-style-section__1_n7b {
  display: inline-block;
  vertical-align: top;
  border-left: 1px solid #a9b5c9;
}
.section-style-section_list__2h2jV {
  list-style: none;
  margin: 0;
  padding: 0;
  height: calc(100% - 87px);
  overflow-y: auto;
}
.section-style-header__2NO93 {
  position: relative;
  height: 45px;
  background-color: #e6eaef;
  border-bottom: 1px solid #a9b5c9;
}
.section-style-title__yju8R {
  position: absolute;
  top: 50%;
  left: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #47475b;
}
.section-style-content__2fNKm {
  padding: 15px 0 0 15px;
  margin-right: 0;
  position: relative;
  min-height: 100px;
  height: 100%;
}
.section-style-add_new__1O8hO {
  background-color: rgba(0,0,0,0);
  border: none;
  font-size: 14px;
  color: #53a8ff;
  margin-bottom: 6px;
}
.section-style-add_new__1O8hO:active,
.section-style-add_new__1O8hO:focus {
  outline: none;
}
.section-style-add_new_container__24jvh {
  overflow: hidden;
}
.section-style-add_new_icon__2TyT3 {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #f8fafc;
  border: solid 1px #53a8ff;
  border-radius: 50%;
  font-style: normal;
  font-size: 16px;
  vertical-align: middle;
  margin-right: 8px;
}
.section-style-add_new_icon__2TyT3 span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.section-style-add_new__1O8hO:hover .section-style-add_new_icon__2TyT3 {
  background-color: #53a8ff;
  color: #fff;
}
.section-style-user_name__2hMA3 {
  display: block;
  color: #a0a0a0;
}
.section-style-item__UM82f {
  padding: 10px 60px 10px 15px;
  cursor: pointer;
  border-radius: 4px 0 0 4px;
  font-size: 15px;
  color: #47475b;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  word-break: break-all;
}
.section-style-item__UM82f:hover {
  background-color: #ccc;
}
.section-style-item__UM82f em {
  font-style: normal;
  background-color: #ffeac1;
}
.section-style-item_selected__s7OoA {
  background-color: #53a8ff !important;
  color: #fff;
}
.section-style-item_selected__s7OoA em {
  color: #47475b;
}
.section-style-item_selected__s7OoA .section-style-user_name__2hMA3 {
  color: #efefef;
}
.section-style-dropable__g8WNs {
  background-color: #e0ffe7;
}
.section-style-hide__35WUF {
  opacity: 0;
}
