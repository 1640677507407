.customer-card-container__3u-R3 {
  display: block;
  position: relative;
}
.customer-card-label__2FP48 {
  font-weight: bold;
  padding-right: 6px;
}
.customer-card-primary_info__2ILxe {
  width: 209px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.customer-card-info__1rtSU {
  max-width: 170px;
}
.customer-card-info__1rtSU > * {
  margin-left: 10px;
}
.customer-card-optout__2sITB {
  margin-top: 10px;
}
.customer-card-optout__text__2GfW0 {
  font-size: 14px;
  line-height: 28px;
  vertical-align: top;
  font-weight: normal;
  color: #919aa2;
  margin-left: 10px;
}
.customer-card-time__3wt23 {
  display: block;
  font-size: 12px;
  color: #8f9aa3;
  line-height: 20px;
  height: 20px;
}
.customer-card-credentials__VKO1C {
  margin-top: 16px;
}
.customer-card-name__1iFji {
  margin-top: 3px;
  max-width: 170px;
}
.customer-card-image__1qXZ0 {
  background-color: #dde5ef;
  border-radius: 23px;
  margin-top: 8px;
  width: 27px;
  height: 27px;
}
.customer-card-rating__3aJmv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 20px 0 20px 31px;
}
.customer-card-rating_label__1-2rC {
  font-size: 13px;
  font-weight: 500;
  color: #6c6c7c;
}
.customer-card-nickname__1pmMM {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.customer-card-nickname_label__2jxsm {
  font-size: 13px;
  font-weight: 500;
  color: #6c6c7c;
  margin-right: 16px;
}
.customer-card-nickname_icon__XyNTM {
  background-image: url(/4f0b1d07727d193f5ca02d2d8f318cfe.png);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  background-color: #dde5ef;
  background-size: 16px 16px;
  border-radius: 8px;
}
.customer-card-loading_cursor__mQCGU {
  cursor: wait;
}
.customer-card-link__14fWm {
  position: relative;
}
