.edit-answer-form-form__I7LuO {
  display: block;
  width: 100%;
}
.edit-answer-form-name__5-BnP,
.edit-answer-form-text__3_IBg {
  display: block;
  width: 100%;
  border-radius: 3px;
  border: solid 1px #a9b5c9;
  font-size: 15px;
  color: #47475b;
  padding: 7px 15px;
}
.edit-answer-form-name__5-BnP:active,
.edit-answer-form-text__3_IBg:active,
.edit-answer-form-name__5-BnP:focus,
.edit-answer-form-text__3_IBg:focus {
  outline: none;
}
.edit-answer-form-text__3_IBg {
  resize: none;
  margin: 10px 0;
  height: 75px;
}
.edit-answer-form-controls__VsfB- {
  text-align: right;
}
.edit-answer-form-button__3ejIz {
  width: 100px;
  margin-left: 10px;
}
