.ranked-agents-list-search__1eRwD {
  display: inline-block;
  position: absolute;
  max-height: 450px;
  border-radius: 3px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 4px 0 rgba(0,0,0,0.19);
          box-shadow: 0 0 4px 0 rgba(0,0,0,0.19);
  border: solid 1px #bec4cb;
  top: 47px;
  background-color: #fff;
  z-index: 1;
}
.ranked-agents-list-search__agents__3HnO9 {
  max-height: 395px;
  overflow-y: auto;
}
.ranked-agents-list-search__agent__2MjnZ {
  padding: 5px 13px;
  overflow: auto;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #bec4cb;
  cursor: pointer;
}
.ranked-agents-list-search__agent__2MjnZ:hover {
  background-color: #edf6ff;
}
.ranked-agents-list-search__agent-icon__Gkdlw {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: #fff;
  border: solid 1px #e6eaef;
  vertical-align: sub;
  margin-right: 10px;
}
.ranked-agents-list-search__agent-icon_active__3cvTk {
  border: 0;
  background: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3E220E13FF-1AA8-4390-9878-12EE97A02E45%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='QA-dashboard_bad-mark' transform='translate(-96.000000, -214.000000)'%3E %3Cg id='list' transform='translate(83.000000, 84.000000)'%3E %3Cg id='Group-32'%3E %3Cg id='Group-31'%3E %3Cg transform='translate(0.000000, 119.000000)'%3E %3Cg id='Group-28' transform='translate(13.000000, 11.000000)'%3E %3Crect id='Rectangle-18' fill='%2356AAFC' x='0' y='0' width='18' height='18' rx='2'%3E%3C/rect%3E %3Cpolyline id='Path-4' stroke='%23FFFFFF' stroke-width='2' points='4.71428571 8.33116883 8.06493506 11.6818182 13.9285714 5.81818182'%3E%3C/polyline%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E") center no-repeat;
}
.ranked-agents-list-search__agent-label__YOUGx {
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #47475b;
  display: inline-block;
  padding-top: 8px;
}
.ranked-agents-list-search__agent-label_active__1Q6CG {
  color: #56aafc;
}
.ranked-agents-list-search__agent-image__Oi2gS {
  height: 28px;
  width: 28px;
  border-radius: 14px;
  float: right;
  background-color: #dde5ef;
  margin-left: 13px;
}
.ranked-agents-list-search__apply__IR7pw {
  width: calc(100% - 26px);
  border-radius: 3px;
  background-color: #56aafc;
  border: solid 1px #56aafc;
  margin: 11px 13px;
  padding: 8px 0;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.ranked-agents-list-search__apply__IR7pw:focus {
  outline: none;
}
.ranked-agents-list-search__apply__IR7pw:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
.ranked-agents-list-search__pagination__2J359 {
  text-align: center;
  margin: 0 13px;
}
