.customer-form-wrapper__oFuvG {
  width: 100%;
  height: 100%;
  background-color: #f2f5f9;
  padding-top: 1;
}
.customer-form-container__3JSOt {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 14px;
  min-width: 450px;
  width: 55%;
  height: 100%;
}
.customer-form-scroll_container__2A2Xz {
  width: 100%;
  min-height: 200px;
  background: #fff;
  border-radius: 3px;
  border: solid 1px #dde5ef;
}
.customer-form-simple_row__3QwP9 {
  width: 100%;
  display: block;
  position: relative;
  vertical-align: middle;
  min-height: 50px;
}
.customer-form-simple_column__2P-5q {
  display: inline-block;
  vertical-align: top;
  min-width: 240px;
}
.customer-form-right_column__2Mqtj {
  display: inline-block;
  text-align: right;
  float: right;
}
.customer-form-head_content__36ScZ {
  display: inline-block;
  width: calc(100% - 140px);
  padding: 28px 15px 16px 0;
  border-bottom: solid 1px #dde5ef;
  min-height: 120px;
  position: relative;
  margin-bottom: 15px;
}
.customer-form-avatar__PSeb3 {
  display: inline-block;
  float: left;
  width: 77px;
  height: 77px;
  margin-top: 24px;
  margin-left: 30px;
  margin-right: 33px;
  border-radius: 50px;
  background-color: #dde5ef;
}
.customer-form-name_field__1Vip0 {
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
  color: #47475b;
  height: 28px;
  line-height: 28px;
  margin-bottom: 5px;
  max-width: 300px;
}
.customer-form-created_at__l1kyv {
  display: block;
  font-size: 12px;
  line-height: 28px;
  vertical-align: top;
  font-weight: normal;
  color: #919aa2;
}
.customer-form-optout__34-0a {
  margin-top: 10px;
}
.customer-form-optout__text__2TK17 {
  font-size: 14px;
  line-height: 28px;
  vertical-align: top;
  font-weight: normal;
  color: #919aa2;
  margin-right: 10px;
}
.customer-form-phone_number__1kCmo {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #6c6c7c;
  height: 24px;
  max-width: 180px;
}
.customer-form-time__2FM6C {
  display: block;
  font-size: 13px;
  font-weight: normal;
  line-height: 12px !important;
  color: #47475b;
}
.customer-form-conversation_link__3pxHr {
  text-decoration: none;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  color: #53a8ff;
}
.customer-form-add_info__1U3FY {
  position: absolute;
  right: 16px;
  font-size: 11px;
  font-weight: normal;
  color: #6c6c7c;
  height: 19px;
  cursor: pointer;
  top: -4px;
}
.customer-form-add_info_icon__Pg7FE {
  display: inline-block;
  height: 19px;
  width: 19px;
  float: right;
  border-radius: 2px;
  border: solid 1px #a9b5c9;
}
.customer-form-plus__3AADr {
  background: #a9b5c9;
  height: 9px;
  position: relative;
  width: 1px;
  top: 4px;
  left: 8px;
}
.customer-form-plus__3AADr:after {
  content: '';
  background: #a9b5c9;
  height: 1px;
  left: -4px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.customer-form-add_info_title__9zlAo {
  display: inline-block;
  line-height: 19px;
  float: left;
  padding-right: 5px;
  -webkit-user-select: none;
}
.customer-form-new_type_list__2Glut {
  position: absolute;
  right: 16px;
  top: 40px;
  background: #fff;
  z-index: 2;
  overflow: hidden;
  -webkit-box-shadow: 0 0 5px #e0e2e4;
          box-shadow: 0 0 5px #e0e2e4;
  border-radius: 2px;
  padding: 5px 7px;
}
.customer-form-new_type_list_item__EKYko {
  padding: 3px 5px;
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
  color: #57697b;
}
.customer-form-new_type_list_item__EKYko:hover {
  background: #e5f2ff;
  border-radius: 2px;
}
.customer-form-middle_container__3pkg0 {
  width: calc(100% - 140px);
  padding: 0 15px 15px 0;
  margin-left: 140px;
}
.customer-form-other_credentials__pjjBH {
  line-height: 20px;
  height: 20px !important;
  font-size: 14px;
  font-weight: normal;
  color: #47475b;
  max-width: 225px;
}
.customer-form-email_credential__2FNAg {
  line-height: 20px;
  height: 20px !important;
  font-size: 14px;
  font-weight: 500;
  width: 200px;
  color: #47475b;
}
.customer-form-left_title__2EMNQ {
  display: inline-block;
  float: left;
  min-height: 40px;
  padding-right: 33px;
  width: 140px;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  color: #919aa2;
  cursor: default;
  -webkit-user-select: none;
}
.customer-form-left_title_tags__1k-it {
  float: none;
}
.customer-form-tags_container__365bB {
  display: inline-block;
  width: calc(100% - 140px);
  padding: 0 16px 20px 0;
}
.customer-form-comment_container__FSivt {
  display: inline-block;
  width: calc(100% - 156px);
  margin-right: 16px;
  padding: 0 0px 20px 0;
  border-bottom: solid 1px #dde5ef;
  margin-bottom: 25px;
}
.customer-form-textarea__3EcaW {
  color: #57697b;
  overflow: hidden;
  resize: none;
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  border: none;
}
.customer-form-merged_container__2EIgp {
  display: inline-block;
  width: calc(100% - 140px);
  padding: 0 16px 20px 0;
  min-height: 50px;
}
.customer-form-buttons__2V7mU {
  position: relative;
  height: 68px;
  -webkit-user-select: none;
}
.customer-form-button_discard__1Dsp5 {
  display: inline-block;
  vertical-align: middle;
  width: 126px;
  height: 35px;
  line-height: 35px;
  border-radius: 3px;
  border: solid 1px #6c6c7b;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: #6c6c7c;
  float: right;
  margin: 10px 22px 23px 0;
  cursor: pointer;
}
.customer-form-button_save__18DnI {
  display: inline-block;
  vertical-align: middle;
  width: 126px;
  height: 35px;
  line-height: 35px;
  border-radius: 3px;
  background-color: #53a8ff;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  float: right;
  margin: 10px 22px 23px 0;
  cursor: pointer;
}
.customer-form-popup_error__1IRA- {
  position: absolute;
  width: 140px;
  height: 40px;
  background-color: rgba(255,0,0,0.75);
  right: 15px;
  top: -38px;
  border-radius: 6px;
  vertical-align: middle;
  line-height: 40px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #473939;
}
.customer-form-popup_error__1IRA-:before {
  top: 100%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-top-color: rgba(255,0,0,0.75);
  border-width: 8px;
  margin-left: 40px;
}
.customer-form-popup_saved__HL1fA {
  position: absolute;
  width: 140px;
  height: 40px;
  background-color: rgba(33,192,14,0.75);
  right: 15px;
  top: -38px;
  border-radius: 6px;
  vertical-align: middle;
  line-height: 40px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #473939;
}
.customer-form-popup_saved__HL1fA:before {
  top: 100%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-top-color: rgba(33,192,14,0.75);
  border-width: 8px;
  margin-left: 40px;
}
.customer-form-merged_item__2Ya_j {
  margin-bottom: 15px;
}
.customer-form-merged_item__2Ya_j:hover {
  color: #f00;
}
.customer-form-merged_item__2Ya_j:hover .customer-form-merged_item_unmerge__1xfDE {
  visibility: visible;
}
.customer-form-merged_credential__ek1c6 {
  text-decoration: none;
  color: #56aaff;
  padding-right: 10px;
  cursor: default;
}
.customer-form-merged_item_unmerge__1xfDE {
  color: #f88276;
  padding: 0 10px;
  font-size: 10px;
  cursor: pointer;
  visibility: hidden;
}
.customer-form-merged_popup_background__304EC {
  position: fixed;
  background-color: rgba(0,0,0,0.8);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
}
.customer-form-merged_popup_form__3NsTP {
  position: fixed;
  left: calc(50% - 200px);
  top: 20%;
  width: 400px;
  background-color: #fff;
}
.customer-form-merged_popup_extended__YXCI7 {
  height: calc(60% - 60px);
}
.customer-form-merged_popup_form_title__3bpdI {
  border-radius: 3px 3px 0 0;
  font-weight: bold;
  font-size: 14px;
  background-color: #f2f5f8;
  color: #47475b;
  padding: 15px 10px;
}
.customer-form-merged_popup_form_content__2c3ZC {
  padding: 0 15px;
  height: calc(100% - 127px);
  overflow: auto;
}
.customer-form-search__3Nrc4 {
  border: 1px solid #d1d2d8;
  border-radius: 2px;
  margin: 15px;
}
.customer-form-customer__1YpX9 {
  position: relative;
  cursor: pointer;
}
.customer-form-customer_single__2HjHr {
  padding: 15px 0;
  margin-left: 35px;
}
.customer-form-bordered__3FYrq {
  border-top: 2px dashed #c9cdd1;
}
.customer-form-customer_name__vCPjl {
  color: #53a8ff;
  font-weight: bold;
  font-size: 16px;
}
.customer-form-customer_credentials___fsid {
  padding-top: 9px;
}
.customer-form-hr__33sz4 {
  border: 0;
  height: 2px;
  background: #eff3f8;
}
.customer-form-buttons_area__YdeAo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 60px;
  background-color: #fff;
  border-radius: 0 0 3px 3px;
  padding: 0 15px;
}
.customer-form-button__1mv0R {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 3px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  margin-left: 10px;
}
.customer-form-disabled_button__WftqN {
  background-color: #dee1e6 !important;
  border: 1px solid #dee1e6 !important;
  cursor: default !important;
}
.customer-form-primary_button__Lyuu1 {
  background-color: #53a8ff;
  border: 1px solid #53a8ff;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}
.customer-form-secondary_button__35iY9 {
  color: #6c6c7c;
  border: 1px solid #6c6c7c;
}
.customer-form-checkbox__3gX9N {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  top: 15px;
  border: 1px solid #a9b5c9;
}
.customer-form-checkbox_selected__2UFm5 {
  background-color: #53a8ff;
  border: 1px solid #53a8ff;
  color: #fff;
  text-align: center;
}
.customer-form-main_text__1BH8s {
  color: #57697b;
}
.customer-form-is_original__27TlF {
  position: absolute;
  top: 15px;
  right: 0;
  color: #b6c0d2;
  font-size: 10px;
}
.customer-form-thin_block__YM6jY {
  width: 224px;
}
.customer-form-loading_cursor__2vKXQ {
  cursor: wait !important;
}
