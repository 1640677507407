.emoji-picker-container__3OALB {
  display: inline-block;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}
.emoji-picker-icon__3eb1i {
  width: 20px;
  height: 20px;
}
.emoji-picker-picker__8a-g4 {
  position: absolute;
  bottom: 25px;
}
.emoji-picker-picker__8a-g4 * {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.emoji-picker-picker__8a-g4 li {
  margin: 0 !important;
}
