.take-new-modal-container__381Wh {
  display: inline-block;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.17);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.17);
  width: 290px;
  padding: 30px 20px;
  text-align: center;
  font-family: Hind;
  background-color: #fff;
}
.take-new-modal-text__1ju9D {
  font-size: 22px;
  line-height: 1.27;
  color: #47475b;
}
.take-new-modal-controls_container__2ntVz {
  margin-bottom: 5px;
}
.take-new-modal-control_accept__wfDTb {
  width: 100%;
  height: 40px;
  border-radius: 3px;
  background-color: #53a8ff;
  color: #fff;
  font-size: 15px;
  margin-bottom: 25px;
  border: none;
}
.take-new-modal-control_accept__wfDTb:active,
.take-new-modal-control_accept__wfDTb:focus {
  outline: none;
}
.take-new-modal-control_reject__2E7oI {
  font-size: 15px;
  color: #f87b6e;
  text-decoration: underline;
  cursor: pointer;
}
.take-new-modal-note__U80Fu {
  font-size: 13px;
  line-height: 1.38;
  color: #6c6c7c;
}
.take-new-modal-link__2_K39 {
  color: #53a8ff;
}
