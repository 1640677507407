.agent-profile-wrapper-overlay__2VLCz {
  background-color: #fff;
  height: 100%;
  padding: 1px;
}
.agent-profile-wrapper-content__3HBuM {
  position: relative;
  padding: 21px 16px;
  border-radius: 3px;
  height: 100%;
  background-color: #fff;
  overflow: auto;
}
.agent-profile-wrapper-back-button__djnRq {
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 6px 20px;
  color: #28b1de;
  text-decoration: none;
  border: 1px solid #28b1de;
  border-radius: 3px;
  font-weight: bold;
}
.agent-profile-wrapper-error__2Q2o8 {
  display: inline-block;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: rgba(248,123,110,0.6);
  border: 2px solid #f87b6e;
  border-radius: 3px;
  font-family: Hind;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  padding: 16px;
  width: 300px;
  max-height: 40%;
  overflow-y: auto;
}
.agent-profile-wrapper-error__title__E_vW1 {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  display: inline-block;
}
.agent-profile-wrapper-error__fieldname__2T4SC,
.agent-profile-wrapper-error__messages__3Zn7w {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  text-align: left;
  font-size: 16px;
  font-weight: normal;
}
.agent-profile-wrapper-error__fieldname__2T4SC {
  font-weight: bold;
}
.agent-profile-wrapper-error__message__5jkLF {
  margin: 0 0 10px;
}
.agent-profile-wrapper-error__close__3oabt {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: Hind;
  font-style: normal;
  font-stretch: normal;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
