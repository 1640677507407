.loadable-wrapper-error__3GKWD {
  color: #f00;
}
.loadable-wrapper-success__2Rr8k {
  opacity: 1;
  -webkit-transition: 0.2s ease opacity 0.2s;
  transition: 0.2s ease opacity 0.2s;
}
.loadable-wrapper-hidden__CJbQe {
  opacity: 0;
}
