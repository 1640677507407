.tooltip-container__2amuS {
  display: inline-block;
  position: relative;
}
.tooltip-container__2amuS .tooltip-content__327hP {
  background-color: #637280;
  border-radius: 3px;
  color: #fff;
  position: absolute;
  bottom: 100%;
  line-height: 1.23;
  letter-spacing: 0.1px;
  font-weight: 500;
  z-index: 3;
  opacity: 0;
  height: 0;
  width: 0;
  padding: 0;
  font-size: 0;
}
.tooltip-container__2amuS .tooltip-content__327hP:hover {
  white-space: nowrap;
  width: auto;
  height: auto;
  opacity: 1;
  padding: 10px 15px;
  font-size: 14px;
}
.tooltip-container__2amuS .tooltip-content__327hP.tooltip-position_left__2ldvb {
  left: 0;
}
.tooltip-container__2amuS .tooltip-content__327hP.tooltip-position_right__3YLzW {
  right: 0;
}
.tooltip-container__2amuS .tooltip-content__327hP.tooltip-position_aside_right__2JgW1 {
  top: -2px;
  bottom: -2px;
  margin-left: calc(100% + 4px);
  left: 0;
}
.tooltip-container__2amuS .tooltip-content__327hP.tooltip-position_down__2QxG5 {
  top: calc(100% + 3px);
  bottom: auto;
}
.tooltip-container__2amuS .tooltip-trigger__2ezSK {
  position: relative;
  display: inline-block;
  background-position: 0 0;
  background-repeat: no-repeat;
  cursor: pointer;
}
.tooltip-container__2amuS .tooltip-trigger__2ezSK.tooltip-trigger_z_index__2Lsmp {
  z-index: 1;
}
.tooltip-container__2amuS .tooltip-trigger__2ezSK:hover + .tooltip-content__327hP {
  white-space: nowrap;
  width: auto;
  height: auto;
  opacity: 1;
  padding: 10px 15px;
  font-size: 14px;
}
.tooltip-container__2amuS .tooltip-trigger__2ezSK:hover + .tooltip-content__327hP.tooltip-width_medium__2yLzX {
  min-width: 300px;
  word-break: break-word;
  white-space: pre-wrap;
}
