.tags-selector-container__3AoLn {
  display: block;
  text-decoration: none;
  position: relative;
  border: 1px solid #e2e6ed;
  border-radius: 2px;
}
.tags-selector-container__3AoLn .Select .Select-menu {
  background-color: #fff;
}
.tags-selector-container__3AoLn .Select .Select-control {
  height: auto;
}
.tags-selector-container__3AoLn .Select .Select-control .Select-clear-zone {
  padding: 2px 0 0 0;
  height: 30px;
  line-height: 30px;
}
.tags-selector-container__3AoLn .Select .Select-input {
  height: 30px;
}
.tags-selector-container__3AoLn .Select .Select-value {
  color: #b7c1d2;
  background: #fff;
  font-size: 12px;
  border: 1px solid #b7c1d2 !important;
  height: 20px;
}
.tags-selector-container__3AoLn .Select .Select-value .Select-value-label {
  color: #b6c0d2;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: value 0;
  height: 18px;
  line-height: 18px;
}
.tags-selector-container__3AoLn .Select .Select-value .Select-value-icon {
  float: right;
  color: #f00;
  background-color: #fff;
  height: 18px;
  line-height: 18px;
  border-right: none;
  font-size: 18px;
  padding: 2px 5px 0 5px;
}
.tags-selector-direction_top__2PFzH .Select-menu-outer {
  top: auto;
  bottom: 100%;
}
.tags-selector-tag__3Vqsh {
  padding-right: 6px;
}
.tags-selector-frequent__1c7FT {
  background-color: rgba(0,126,255,0.04) !important;
}
.tags-selector-frequent__1c7FT:hover {
  background-color: rgba(0,126,255,0.1) !important;
}
.tags-selector-frequentLastChild__2xe66 {
  border-bottom: 1px dotted rgba(0,126,255,0.4) !important;
}
.tags-selector-optionLink__3j2PK {
  color: #188fea;
  font-weight: bold;
  cursor: pointer;
}
