.app-component-menuContainer__36Jx3 {
  position: absolute;
  top: 0;
  width: 70px;
  bottom: 0;
  left: 0;
  background-color: #2c2f3f;
}
.app-component-layoutContainer__3iPwi {
  margin-left: 70px;
  background-color: #f2f5f9;
  overflow: auto;
}
.app-component-error__1Nd97 {
  color: #f00 !important;
}
