.ranking-status-container__6GdU7 {
  margin: 10px;
}
.ranking-status-container__6GdU7 li {
  margin-bottom: 10px;
}
.ranking-status-container__6GdU7 li ul {
  padding-top: 10px;
}
.ranking-status-container__6GdU7 button {
  display: block;
  width: 100%;
  border: none;
  padding: 5px;
  outline: none;
}
.ranking-status-container__6GdU7 button:active {
  outline: none;
  border: none;
}
.ranking-status-container__6GdU7 label {
  display: block;
  padding: 0 0 0 20px;
}
.ranking-status-container__6GdU7 label input {
  margin-right: 5px;
}
.ranking-status-btn__3sMqU {
  display: block;
  background-color: #53a8ff;
  color: #fff;
  border-radius: 3px;
  width: 100%;
  border: none;
  height: 30px;
  margin: 0 10px 20px 10px;
  outline: none;
  width: calc(100% - 20px);
  outline: none;
}
.ranking-status-btn__3sMqU:active {
  outline: none;
  border: none;
}
.ranking-status-hide__1a-Ik {
  display: none;
}
