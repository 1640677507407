.dashboard-button-base__3j9dN {
  border: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 2px;
}
.dashboard-button-base__3j9dN:focus {
  outline: none;
}
.dashboard-button-default__3s7vC,
.dashboard-button_add__1X8LI,
.dashboard-button_activate__2fhTi,
.dashboard-button_assign__dk0ZY,
.dashboard-button_multiselect__3TFrB,
.dashboard-button_delete__3kISY {
  padding: 0 14px;
  height: 32px;
  line-height: 33px;
}
.dashboard-button-default__3s7vC.dashboard-only_icon__2ozwn,
.dashboard-button_add__1X8LI.dashboard-only_icon__2ozwn,
.dashboard-button_activate__2fhTi.dashboard-only_icon__2ozwn,
.dashboard-button_assign__dk0ZY.dashboard-only_icon__2ozwn,
.dashboard-button_multiselect__3TFrB.dashboard-only_icon__2ozwn,
.dashboard-button_delete__3kISY.dashboard-only_icon__2ozwn {
  font-size: 18px;
  width: 36px;
}
.dashboard-button-default__3s7vC .dashboard-badge__1zsPy,
.dashboard-button_add__1X8LI .dashboard-badge__1zsPy,
.dashboard-button_activate__2fhTi .dashboard-badge__1zsPy,
.dashboard-button_assign__dk0ZY .dashboard-badge__1zsPy,
.dashboard-button_multiselect__3TFrB .dashboard-badge__1zsPy,
.dashboard-button_delete__3kISY .dashboard-badge__1zsPy {
  height: 16px;
  line-height: 16px;
}
.dashboard-button-small__Dia4e {
  padding: 0 13px;
  text-transform: uppercase;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.dashboard-button-small__Dia4e.dashboard-only_icon__2ozwn {
  padding-top: 9px;
  font-size: 13px;
  width: 28px;
}
.dashboard-button-extra-small__ySMBs {
  padding: 0 6px;
  text-transform: uppercase;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
}
.dashboard-button-primary__3IJuw,
.dashboard-button_add__1X8LI,
.dashboard-button_multiselect__3TFrB,
.dashboard-button_delete__3kISY {
  background-color: #188fea;
  color: #fff;
}
.dashboard-button-primary__3IJuw:hover,
.dashboard-button_add__1X8LI:hover,
.dashboard-button_multiselect__3TFrB:hover,
.dashboard-button_delete__3kISY:hover {
  background-color: #0a71bf;
}
.dashboard-button-light__oniKs {
  background-color: #fff;
  color: #0a71bf;
}
.dashboard-button-transparent__We0Y5,
.dashboard-button_activate__2fhTi,
.dashboard-button_assign__dk0ZY {
  background-color: none;
  color: #fff;
  border: 1px solid #fff;
}
.dashboard-button-transparent__We0Y5:hover,
.dashboard-button_activate__2fhTi:hover,
.dashboard-button_assign__dk0ZY:hover {
  background-color: #fff;
  color: #0a71bf;
}
.dashboard-button-danger__3jq_V {
  background-color: #d85151;
  color: #fff;
}
.dashboard-button-danger__3jq_V:hover {
  background-color: #c13030;
}
.dashboard-button-full__1uxzV {
  width: 100%;
  text-align: center;
}
.dashboard-button-secondary__3z_Qg {
  background-color: #aaa;
  color: #fff;
  font-weight: bold;
}
.dashboard-button-secondary__3z_Qg:hover {
  background-color: #0a71bf;
}
.dashboard-button-tertiary__2cB5w {
  color: #aaa;
  font-weight: bold;
}
.dashboard-button-tertiary__2cB5w:hover {
  color: #0a71bf;
}
.dashboard-button-dark__14eAC {
  background-color: #0a71bf;
  color: #fff;
}
.dashboard-button-dark__14eAC:hover {
  background-color: #005fa7;
}
.dashboard-button-only-icon__1Fi_J,
.dashboard-button_activate__2fhTi,
.dashboard-button_assign__dk0ZY {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.dashboard-button-icon-big__3UbPm {
  font-size: 18px;
  width: 38px;
}
.dashboard-button-icon__1LVBd {
  margin-right: 5px;
}
.dashboard-button-bold__2Wc39 {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.dashboard-button-float-right__YD5yX {
  float: right;
}
.dashboard-button-with-hr__2Rt-B {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 0;
}
.dashboard-button-with-hr__2Rt-B:after {
  display: block;
  position: relative;
  bottom: 15px;
  content: '';
  border-bottom: 1px solid #c1cdd6;
  z-index: -1;
}
.dashboard-button-processing__LVGTa {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
}
.dashboard-button-invisible__2kxlh {
  visibility: hidden;
}
.dashboard-button-tooltip__GZyZm {
  top: 38px;
  left: 50%;
  margin-left: -75px;
  color: #fff;
  width: 150px;
  font-size: 13px;
  text-align: center;
  text-transform: initial;
  position: absolute;
  padding: 5px;
  z-index: 2;
  background-color: #3e434d;
  height: 26px;
  line-height: 16px;
  opacity: 0.8;
  border-radius: 2px;
}
.dashboard-button-tooltip__GZyZm:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-bottom-color: #3e434d;
  border-width: 5px;
  margin-left: -5px;
}
.dashboard-button-badge__3_frv {
  padding: 0px 7px;
  min-width: 10px;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #f86e5f;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 40px;
}
.dashboard-header__2QlBA {
  height: 57px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background: #0a71bf;
}
.dashboard-button_label__3Rzuy {
  display: inline-block;
  white-space: nowrap;
}
.dashboard-headerWithTitle__3vCrN {
  font-size: 20px;
  color: #fff;
}
.dashboard-left_bar__1rQsF {
  left: 0;
  height: 57px;
  position: absolute;
  top: 0;
  width: 30%;
  border-left: 1px solid #6caad9;
  padding: 14px 10px 0px;
  background-color: #0a71bf;
}
.dashboard-right_bar__te5NZ {
  right: 0;
  height: 57px;
  position: absolute;
  top: 0;
  padding: 14px 10px 0px;
  background-color: #0a71bf;
  width: 70%;
}
.dashboard-work_area__V4pVt {
  position: absolute;
  top: 57px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dee3ea;
}
.dashboard-body_container__1SB2F {
  position: absolute;
  left: 245px;
  right: 0;
  bottom: 0;
  top: 0;
}
.dashboard-button_add__1X8LI {
  display: inline-block;
  float: right;
}
.dashboard-interval__376aP {
  float: right;
  width: 5px;
  height: 1px;
}
.dashboard-button_activate__2fhTi {
  float: right;
  margin-right: 5px;
}
.dashboard-button_assign__dk0ZY {
  float: right;
  margin-right: 5px;
}
.dashboard-button_multiselect__3TFrB {
  margin-right: 10px;
}
.dashboard-flexible_layout__tzUz1 {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 245px;
}
.dashboard-burger_wrap__XBA-n {
  width: 50px;
  height: 57px;
  cursor: pointer;
  z-index: 11;
  position: relative;
}
.dashboard-burger__1X4wN {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 20px;
  top: 20px;
  display: none;
}
.dashboard-burger_lines__J3LRy {
  display: block;
  position: relative;
  width: 100%;
  height: 2px;
  background: #fff;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  top: 8px;
}
.dashboard-burger_lines__J3LRy:after,
.dashboard-burger_lines__J3LRy:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background: #fff;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.dashboard-burger_lines__J3LRy:after {
  top: 8px;
}
.dashboard-burger_lines__J3LRy:before {
  top: -8px;
}
.dashboard-burger_lines_selected__3_n2M {
  background: transparent;
}
.dashboard-burger_lines_selected__3_n2M:before {
  -webkit-transform: translate(0px, 8px) rotate(-45deg);
          transform: translate(0px, 8px) rotate(-45deg);
}
.dashboard-burger_lines_selected__3_n2M:after {
  -webkit-transform: translate(0px, -8px) rotate(45deg);
          transform: translate(0px, -8px) rotate(45deg);
}
@media (max-width: 1024px) {
  .dashboard-logo__2_AQh {
    display: none;
  }
  .dashboard-button_label__3Rzuy {
    display: none;
  }
  .dashboard-button_add__1X8LI {
    display: inline-block;
    padding: 8px 6px;
    min-width: 30px;
    text-align: center;
  }
  .dashboard-button_icon__1Yad3 {
    margin-right: 0;
  }
}
@media (min-width: 1340px) {
  .dashboard-left_bar__1rQsF {
    width: 30%;
  }
  .dashboard-right_bar__te5NZ {
    width: 70%;
  }
}
@media (min-width: 1700px) {
  .dashboard-left_bar__1rQsF {
    width: 25%;
  }
  .dashboard-right_bar__te5NZ {
    width: 75%;
  }
}
@media (max-width: 1024px) {
  .dashboard-logo__2_AQh {
    left: 50px;
  }
  .dashboard-burger__1X4wN {
    display: block;
  }
  .dashboard-flexible_layout__tzUz1 {
    left: 0px;
  }
  .dashboard-overlay__10dhI {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;
  }
  .dashboard-overlay_animation__35dxF {
    background-color: #000;
    opacity: 0.6;
    display: block;
  }
  .dashboard-overlay_animation__35dxF {
    -webkit-animation: fade-and-hide 0.2s ease;
            animation: fade-and-hide 0.2s ease;
  }
}
@media (max-width: 700px) {
  .dashboard-left_bar__1rQsF {
    width: 100%;
    z-index: 8;
  }
  .dashboard-right_bar__te5NZ {
    width: 0%;
  }
}
