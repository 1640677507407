.dormant-modal-container__3fUUU {
  display: inline-block;
  width: 290px;
  padding: 15px;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: -1px -1px 5px 0px #53a7fc;
          box-shadow: -1px -1px 5px 0px #53a7fc;
  font-family: Hind;
  font-size: 22px;
  line-height: 1.27;
  text-align: center;
  color: #47475b;
}
.dormant-modal-link__1284Y {
  color: #53a8ff;
  word-break: break-word;
}
.dormant-modal-warning__2QUO3 {
  display: block;
}
