.channels-container__3gVLW {
  height: 32px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: inline-block;
}
.channels-current__1Zw9V {
  height: 32px;
  line-height: 30px;
  padding: 0 10px 0 10px;
  margin: 0 30px 0 10px;
  color: #7b8994;
  position: relative;
}
.channels-dropdown_container__1ThPG {
  display: none;
  position: absolute;
  width: 150px;
  left: -23px;
  background: #fff;
  bottom: 40px;
}
.channels-dropdown_container__1ThPG:before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px;
  border-color: #fff transparent transparent transparent;
  bottom: -14px;
  left: 75px;
  cursor: default;
}
.channels-dropdown__3VHs0 {
  width: 150px;
  background: #fff;
  padding: 7px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  -webkit-box-shadow: 0px 1px 6px 0px rgba(1,1,1,0.2);
          box-shadow: 0px 1px 6px 0px rgba(1,1,1,0.2);
  max-height: 442px;
  overflow-y: auto;
  cursor: default;
}
.channels-dropdown_opened__2I9n6 {
  display: block;
}
.channels-dropdown_item__2bt3S {
  padding: 5px 0 5px 0;
  cursor: pointer;
  border-radius: 3px;
  padding: 5px 10px;
  color: #57697b;
  overflow: hidden;
  text-overflow: ellipsis;
}
.channels-dropdown_item__2bt3S:hover {
  background-color: #e5f2ff;
}
.channels-group_type_limitless__3-Bl_,
.channels-group_type_limited__1IvdD,
.channels-group_type_strict_limited__2onY9 {
  border-style: solid;
  border-width: 2px 7px;
}
.channels-group_type_limitless__3-Bl_ {
  border-color: #99f;
}
.channels-group_type_limited__1IvdD {
  border-color: #ff0;
}
.channels-group_type_strict_limited__2onY9 {
  border-color: #f00;
}
