.scroll-style-outerContainer__3Gcmz {
  position: relative;
}
.scroll-style-innerContainer__3-SYH {
  overflow: auto;
}
.scroll-style-heightAuto__1j8ou {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
