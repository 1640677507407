.blacklisted-warning-modal-modalContainer__1axGS {
  background: #fff;
  padding: 30px;
  text-align: center;
}
.blacklisted-warning-modal-warning_icon__3_-5k {
  display: block;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  border-radius: 2px;
  background-color: #fff;
  border: solid 1px #b9bcc1;
  cursor: pointer;
  border: 0;
  background: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg xmlns='http://www.w3.org/2000/svg' width='65' height='60' viewBox='0 0 65 60' %3E %3Cdefs%3E %3Cpath id='a' d='M.648.363h62.368V56H.648z' /%3E %3C/defs%3E %3Cg fill='none' fill-rule='evenodd'%3E %3Cg transform='translate(2 3.897)'%3E %3Cmask id='b' fill='%23fff'%3E %3Cuse xlink-href='%23a' /%3E %3C/mask%3E %3Cpath fill='%23FED83A' d='M62.185 46.813L37.136 3.426A6.062 6.062 0 0 0 31.832.363a6.06 6.06 0 0 0-5.304 3.063L1.48 46.813a6.059 6.059 0 0 0 0 6.125A6.06 6.06 0 0 0 6.782 56h50.1a6.065 6.065 0 0 0 5.304-3.062 6.061 6.061 0 0 0 0-6.125' mask='url(%23b)' /%3E %3C/g%3E %3Cpath fill='%23f7981d' d='M35.715 20v11.855c0 1.271-.076 2.532-.228 3.782a56.528 56.528 0 0 1-.601 3.824h-4.352a56.543 56.543 0 0 1-.602-3.824 31.306 31.306 0 0 1-.227-3.782V20h6.01zM29 46.943c0-.498.093-.96.28-1.389.186-.428.442-.801.766-1.119a3.684 3.684 0 0 1 2.602-1.036c.51 0 .987.093 1.429.28a3.77 3.77 0 0 1 1.161.756 3.42 3.42 0 0 1 1.057 2.508c0 .497-.093.964-.28 1.399a3.38 3.38 0 0 1-.777 1.129 3.62 3.62 0 0 1-1.161.746c-.442.18-.919.27-1.429.27-.526 0-1.009-.09-1.451-.27a3.512 3.512 0 0 1-1.151-.746 3.472 3.472 0 0 1-.766-1.129 3.506 3.506 0 0 1-.28-1.399z' /%3E %3Cpath stroke='%23F7981D' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M42.43 12.621l-4.942-8.559A6.063 6.063 0 0 0 32.184 1a6.06 6.06 0 0 0-5.304 3.062L1.83 47.45a6.063 6.063 0 0 0 0 6.125 6.063 6.063 0 0 0 5.304 3.062h6.681m9.852 0h33.567a6.065 6.065 0 0 0 5.304-3.062 6.061 6.061 0 0 0 0-6.125L50.55 26.687' /%3E %3C/g%3E %3C/svg%3E") center no-repeat;
}
.blacklisted-warning-modal-modal_buttons__DJw2G {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin: 30px 0 0;
}
.blacklisted-warning-modal-showHideBtn__2I-HJ {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #333;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 10px;
}
.blacklisted-warning-modal-customer_row__2TQbW {
  margin-bottom: 9px;
}
