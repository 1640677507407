.dashboard-grid__1uk-h {
  background: #f8f9fb;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.dashboard-grid__full_height__3y2iG {
  height: 100%;
}
.dashboard-flex__2qbov {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dashboard-left_cell_of_grid__1Vz4d,
.dashboard-right_cell_of_grid__1CxVy {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 50%;
  padding-top: 14px;
  padding-left: 14px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.dashboard-right_cell_of_grid__1CxVy {
  padding-right: 14px;
}
.dashboard-full_row_of_grid__2WrMq {
  display: inline-block;
  width: 100%;
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 14px;
}
.dashboard-full_row_of_grid_glossary__wT6kE {
  padding-top: 0;
}
.dashboard-chart_container__3Moil {
  padding-top: 10px;
  background-color: #fff;
  border: 1px solid #e6e8eb;
}
.dashboard-refresh__3OHbE {
  position: absolute;
  display: inline-block;
  float: right;
  height: 30px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #53a8ff;
  font-size: 13px;
  text-align: center;
  color: #53a8ff;
  padding-top: 3px;
  margin-right: 18px;
  top: 20px;
  right: 0;
}
.dashboard-refresh__3OHbE:active,
.dashboard-refresh__3OHbE:focus {
  outline: none;
}
.dashboard-refresh__3OHbE:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
.dashboard-switch__G65KP {
  z-index: 1;
  top: 20px;
  left: 20px;
}
.dashboard-switch_label__3eAQt {
  white-space: nowrap;
}
.dashboard-failed_messages_pages__r7-n1 {
  width: 100%;
  text-align: center;
}
.dashboard-mesages_table__3ETDj {
  margin-top: 5px;
  width: 100%;
  text-align: center;
}
.dashboard-mesages_table_head__apukZ {
  font-weight: bold;
}
.dashboard-mesages_table_td__3hfIQ {
  padding: 5px;
  border: 1px solid #000;
}
.dashboard-mesages_table_td_capitalize__2FFsL {
  text-transform: capitalize;
}
.dashboard-mesages_table_td_sort__3r_Hi {
  position: relative;
  cursor: pointer;
}
.dashboard-mesages_table_td_sort__3r_Hi:after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-180deg);
          transform: translateY(-50%) rotate(-180deg);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='%230000008c' stroke-width='2' stroke-linecap='butt' stroke-linejoin='bevel'%3E%3Cpath d='M12 19V6M5 12l7-7 7 7'/%3E%3C/svg%3E");
}
.dashboard-mesages_table_td_sort_asc__1OyZc {
  position: relative;
}
.dashboard-mesages_table_td_sort_asc__1OyZc:after {
  -webkit-transform: translateY(-50%) rotate(0);
          transform: translateY(-50%) rotate(0);
}
.dashboard-mesages_table_td_12__2SPUj {
  width: 12%;
}
.dashboard-mesages_table_td_10__1SK8P {
  width: 10%;
}
.dashboard-mesages_table_td_5__qCbAA {
  width: 5%;
}
.dashboard-mesages_table_td_6__Rokwm {
  width: 6%;
}
.dashboard-mesages_table_td_7__7TOhZ {
  width: 7%;
}
.dashboard-textbox_input__j5A-F {
  font-weight: normal;
  padding-right: 30px;
  background: none;
  margin-right: 0;
  padding-left: 5px;
}
.dashboard-cross_input__3fLid {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 0;
}
.dashboard-message_errors_clear__2oYxn {
  margin-left: 10px;
}
.dashboard-tabs__1MVMl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  margin-top: 8px;
}
.dashboard-tab__2P1Pv {
  padding: 7px 10px 7px 10px;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  display: block;
  color: #6c6c7c;
  text-decoration: none !important;
}
.dashboard-tab_active__3NPDO {
  background: #6c6c7c;
  color: #fff;
}
.dashboard-search_field_glossary__1ygdZ {
  margin-top: 8px;
  width: 600px;
}
.dashboard-error_glossary_data__Ho3Jj {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 18px;
}
.dashboard-created_at_filter__B4QaP {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  gap: 8px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.dashboard-created_at_filter_item__3cp1R {
  width: 100%;
}
.dashboard-created_at_filter_item_first__17D0P {
  border-right: 1px solid #6c6c7c;
  padding-right: 8px;
}
.dashboard-capitalize_text__3XwCZ {
  text-transform: capitalize;
}
.dashboard-totals__b1tby {
  position: absolute;
  right: 0;
  top: 420px;
  width: 15%;
  padding: 15px 0 10px 10px;
  margin: 0 6px 0;
}
.dashboard-totals__uc__1XxKJ {
  top: 0px;
  position: relative;
  width: 50%;
  padding: 0px 0 10px 0px;
  margin: 0;
}
.dashboard-totals__b1tby .dashboard-totals__row__1SzsA {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  background: #f8f9fb;
  color: #6c6c7c;
  border: 1px solid #e6e8eb;
}
.dashboard-totals__b1tby .dashboard-totals__col__13ZHt {
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-left: 1px solid #e6e8eb;
  padding: 5px;
}
.dashboard-totals__b1tby .dashboard-totals__col_uc__1_cxX {
  min-width: 90px;
}
.dashboard-totals__b1tby .dashboard-totals__col__13ZHt:first-child {
  min-width: 90px;
  border-left: none;
}
.dashboard-totals__unassigned__ApAcB {
  background-color: #feb8ae;
  color: #fff;
  display: block;
  padding: 0 5px;
}
.dashboard-totals__new_message__37nsu {
  background-color: #76d466;
  color: #fff;
  display: block;
  padding: 0 5px;
}
.dashboard-totals__opened__355HS {
  background-color: #53a8ff;
  color: #fff;
  display: block;
  padding: 0 5px;
}
.dashboard-totals__dormant__2We5G {
  background-color: #ffdc00;
  color: #333;
  display: block;
  padding: 0 5px;
}
.dashboard-totals__inactive__Qoe4f {
  background-color: #fbe6e4;
  color: #333;
  display: block;
  padding: 0 5px;
}
