.tags-warning-component-container__3ba3C {
  display: inline-block;
  width: 350px;
  border-radius: 3px;
  background-color: #fff;
  text-align: center;
  padding: 25px 45px;
}
.tags-warning-component-text__3ixrP {
  font-family: Hind;
  font-size: 16px;
  text-align: center;
  color: #47475b;
  margin: 15px 0 20px;
}
.tags-warning-component-button__JVuRZ {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.tags-warning-component-component_selector__21RXP {
  width: 100%;
  text-align: left;
  border: 1px solid #e2e6ed;
  border-radius: 3px;
  text-align: left;
}
.tags-warning-component-component_selector__21RXP span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 190px;
  height: 20px;
  white-space: nowrap;
}
.tags-warning-component-component_selector__21RXP.tags-warning-component-error__CO905 {
  border: 1px solid #f86e5f;
}
.tags-warning-component-component_selector__21RXP.tags-warning-component-disabled__3e25h {
  opacity: 0.3;
  cursor: auto;
}
.tags-warning-component-tag_item__d3PB0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 5px 10px;
  cursor: pointer;
}
.tags-warning-component-tag_item__d3PB0 span {
  display: block;
  margin-left: 5px;
}
