.switch-activity__3_eza {
  display: inline-block;
}
.switch-activity__switcher__VMF-A {
  display: inline-block;
  position: absolute;
  border-radius: 50px;
  margin: 3px;
  width: 22px;
  height: 22px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.09);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.09);
  -webkit-transition: left 0.2s, right 0.2s;
  transition: left 0.2s, right 0.2s;
  right: calc(100% - 28px);
  left: 0;
}
.switch-activity__auto__1ILpe {
  text-align: center;
  color: #f86e5f;
  font-size: 22px;
}
.switch-activity__overlay__2Ay47 {
  position: relative;
  width: 50px;
  height: 28px;
  border-radius: 100px;
  background-color: #a9b5c9;
  cursor: pointer;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
}
.switch-activity__overlay_active__3ZrjI {
  background-color: #76d466;
}
.switch-activity__overlay_active__3ZrjI .switch-activity__switcher__VMF-A {
  left: calc(100% - 28px);
  right: 0;
}
.switch-cursor_auto__3cNm7 {
  cursor: not-allowed;
}
