.scheduled-message-edit-component-container__2Jm9f {
  border-radius: 3px;
  background-color: #fff;
}
.scheduled-message-edit-component-header__2AgsT {
  padding: 14px 20px;
  border-bottom: solid 1px #dde5ef;
}
.scheduled-message-edit-component-header__2AgsT:after {
  content: "";
  display: table;
  clear: both;
}
.scheduled-message-edit-component-header__2AgsT h2 {
  float: left;
  font-size: 18px;
  font-weight: bold;
  color: #47475b;
  margin: 0;
  padding: 0;
}
.scheduled-message-edit-component-close__3_3gJ {
  float: right;
  cursor: pointer;
  margin-top: 3px;
}
.scheduled-message-edit-component-close__3_3gJ svg * {
  fill: #53a8ff;
}
.scheduled-message-edit-component-content__3c0zc {
  padding: 20px;
}
.scheduled-message-edit-component-schedule__kZ0pb {
  position: relative;
}
.scheduled-message-edit-component-message__1txhe {
  display: block;
  width: 100%;
  height: 100px;
  padding: 15px;
  border-radius: 5px;
  border: solid 1px #a9b5c9;
  font-size: 14px;
  color: #47475b;
  resize: none;
  margin-bottom: 15px;
}
.scheduled-message-edit-component-message__1txhe:focus,
.scheduled-message-edit-component-message__1txhe:active {
  outline: none;
}
.scheduled-message-edit-component-datepicker_container__38-ia,
.scheduled-message-edit-component-timepicker_container__7SSI_ {
  display: inline-block;
  width: 140px;
}
.scheduled-message-edit-component-datepicker_container__38-ia {
  margin-right: 20px;
}
.scheduled-message-edit-component-datepicker__32GxV {
  border-radius: 4px;
  border: solid 1px #aeafbb;
}
.scheduled-message-edit-component-submit__3_yTw {
  float: right;
}
.scheduled-message-edit-component-controls__3naEk {
  margin-bottom: 30px;
}
.scheduled-message-edit-component-disabled_overlay__2P5ip {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,0.4);
}
.scheduled-message-edit-component-error__2ZgQm {
  color: #f00;
}
