.answer-manager-container__3wm3- {
  display: inline-block;
  width: 458px;
  height: 100%;
  border-left: 1px solid #a9b5c9;
}
.answer-manager-header__35N0h {
  height: 45px;
  border-bottom: 1px solid rgba(230,234,239,0.3);
  text-align: right;
  font-size: 13px;
  color: #47475b;
  padding-top: 10px;
  padding-right: 20px;
}
.answer-manager-header_button__1kqhG {
  background-color: rgba(0,0,0,0);
  border: none;
  margin-left: 40px;
}
.answer-manager-header_button__1kqhG:first-of-type {
  margin-left: 0;
}
.answer-manager-header_button__1kqhG:focus,
.answer-manager-header_button__1kqhG:active {
  outline: none;
}
.answer-manager-header_button__1kqhG span {
  display: inline-block;
  vertical-align: bottom;
}
.answer-manager-header_button__1kqhG span:last-of-type {
  margin-left: 4px;
}
.answer-manager-header_button_wrapper__2hMK4 {
  position: relative;
}
.answer-manager-header_button_delete__2XiVH:hover {
  color: #fc6453;
}
.answer-manager-header_button_delete__2XiVH:hover svg * {
  stroke: #fc6453;
}
.answer-manager-header_button_move__1wI2m:hover {
  color: #53a8ff;
}
.answer-manager-header_button_move__1wI2m:hover svg * {
  fill: #53a8ff;
}
.answer-manager-header_disabled__19Vdt .answer-manager-header_button__1kqhG {
  cursor: default;
  opacity: 0.3;
}
.answer-manager-header_disabled__19Vdt .answer-manager-header_button__1kqhG:hover {
  color: #47475b;
}
.answer-manager-header_disabled__19Vdt .answer-manager-header_button__1kqhG:hover svg * {
  stroke: #47475b;
}
.answer-manager-content__3Yk_- {
  padding: 17px 20px;
  width: 100%;
  height: calc(100% - 45px);
  overflow: auto;
}
.answer-manager-label__2y-E1 {
  display: block;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #47475b;
  opacity: 0.6;
  margin-bottom: 5px;
}
.answer-manager-input__gx3O5,
.answer-manager-textarea__2fEgk {
  display: block;
  width: 100%;
  border-radius: 3px;
  border: solid 1px #a9b5c9;
  padding: 9px 15px;
}
.answer-manager-input__gx3O5:active,
.answer-manager-textarea__2fEgk:active,
.answer-manager-input__gx3O5:focus,
.answer-manager-textarea__2fEgk:focus {
  outline: none;
}
.answer-manager-input__gx3O5 {
  margin-bottom: 25px;
}
.answer-manager-textarea__2fEgk {
  height: 215px;
  margin-bottom: 20px;
  resize: none;
}
.answer-manager-submit__Wkzfb {
  text-align: right;
}
.answer-manager-save_button__yQP6X {
  margin-left: 10px;
}
.answer-manager-warning__3Qnzd {
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 2;
  cursor: auto;
}
