.previous-conversation-tag-rect__9NApR,
.previous-conversation-tag__33yDp {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border: 1px solid #b7c1d3;
  border-radius: 4px;
  display: inline-block;
  color: #b7c1d3;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.previous-conversation-tag-rect__9NApR:before,
.previous-conversation-tag__33yDp:before {
  content: '#';
}
.previous-conversation-tag-rect__9NApR.previous-conversation-no-border__3B0Wf,
.previous-conversation-tag__33yDp.previous-conversation-no-border__3B0Wf,
.previous-conversation-tag__33yDp {
  border: none;
  padding-top: 1px;
}
.previous-conversation-root__ItnDe {
  margin: 10px;
  position: relative;
}
.previous-conversation-root_active__1jXRM {
  border: 1px solid #fdd762;
}
.previous-conversation-download_all__iZKig {
  margin-left: 10px;
}
.previous-conversation-title__m1Pqs {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  padding: 6px 7px;
  background: #f1f3f6;
  color: #6c6c7c;
  font-weight: bold;
  font-size: 13px;
  border-radius: 2px;
  white-space: nowrap;
  line-height: 19px;
}
.previous-conversation-title__ranked__3xyIf {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: #f00;
  margin: -5px;
}
.previous-conversation-title_opened__2vjzS {
  background-color: #a9b5c9;
  color: #fff;
}
.previous-conversation-title_opened__2vjzS .previous-conversation-credential_icon__2cJHc svg * {
  fill: #fff;
}
.previous-conversation-title_estimated-bad__3yPqA {
  border: 2px solid #f00;
  padding: 4px 5px;
}
.previous-conversation-title_estimated-good__1qBUg {
  border: 2px solid #32cd32;
  padding: 4px 5px;
}
.previous-conversation-title_estimate__3GJje {
  border: 2px solid #ffd700;
  padding: 4px 5px;
}
.previous-conversation-credential_icon__2cJHc {
  margin-right: 10px;
}
.previous-conversation-credential_icon__2cJHc svg {
  width: 15px;
  height: 19px;
}
.previous-conversation-credential_icon__2cJHc svg * {
  fill: #a9b5c9;
}
.previous-conversation-btn__2YMOF {
  color: #53a8ff;
  background-color: #fff;
  font-size: 13px;
  font-weight: 600;
  height: 26px;
  line-height: 26px;
  padding: 0 15px;
  border-radius: 3px;
  border: solid 2px #53a8ff;
}
.previous-conversation-tags__-CsNe {
  color: #999;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.previous-conversation-tag__33yDp {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-left: 8px;
  background: #fff;
  padding-top: 0;
  border-radius: 1px;
  color: #6e6e7e;
  font-weight: 500;
}
.previous-conversation-body__3hqaS {
  position: relative;
  padding: 3px 7px;
  width: 100%;
  border-bottom: 1px solid #d7dee9;
  border-right: 1px solid #d7dee9;
  border-left: 1px solid #d7dee9;
}
.previous-conversation-scroll_column__21gN- {
  position: relative;
  width: 50px;
  text-align: center;
  overflow: hidden;
}
.previous-conversation-scroll_item__KywsR {
  position: absolute;
  display: block;
  height: 50px;
  width: 100%;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid' width='17' height='17' viewBox='0 0 17 17'%3E %3Cdefs%3E %3Cstyle%3E .cls-1 %7B fill: %23dde5ef; fill-rule: evenodd; %7D %3C/style%3E %3C/defs%3E %3Cpath d='M8.501,0.003 C3.806,0.003 -0.000,3.807 -0.000,8.500 C-0.000,13.193 3.806,16.998 8.501,16.998 C13.196,16.998 17.002,13.193 17.002,8.500 C17.002,3.807 13.196,0.003 8.501,0.003 ZM11.910,8.958 L9.403,6.453 L9.403,14.003 L7.598,14.003 L7.598,6.453 L5.092,8.958 L3.815,7.682 L8.500,2.998 L13.186,7.682 L11.910,8.958 Z' class='cls-1'/%3E %3C/svg%3E") no-repeat center;
  cursor: pointer;
}
.previous-conversation-scroll_item__KywsR:hover {
  background-color: #f8fafc;
}
.previous-conversation-scroll_top__eZehr {
  top: 0;
}
.previous-conversation-scroll_bottom__1JE9K {
  bottom: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.previous-conversation-messages_column__OD9fK {
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}
.previous-conversation-primary_text__2VWL9 {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
}
.previous-conversation-secondary_text__1qbdr {
  font-weight: 400;
  margin-left: 4px;
}
.previous-conversation-last_user__3awVX {
  margin-left: 10px;
}
.previous-conversation-tag_full__3vlw5 {
  overflow: initial;
}
.previous-conversation-visibility_hidden__3npRd {
  visibility: hidden;
}
