.multistate-switch-switcher__2JKDy {
  display: inline-block;
  border: 1px solid #a9b5c9;
  border-radius: 100px;
  margin: 0 10px 0 10px;
}
.multistate-switch-switcher_control__1o0Dc {
  display: inline-block;
  border-radius: 12px;
  margin: 3px;
  width: 22.2px;
  height: 22.2px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.09);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.09);
  float: left;
}
.multistate-switch-switcher_overlay__1vx-0 {
  height: 28px;
  border-radius: 100px;
  background-color: #a9b5c9;
  cursor: pointer;
}
.multistate-switch-switcher_cell__DF_Pu {
  display: inline-block;
  height: 100%;
  min-width: 31px;
  border-left: 2px solid #fff;
  position: relative;
}
.multistate-switch-switcher_cell__DF_Pu:nth-child(2n + 1) .multistate-switch-switcher_cell_title__1xMkQ {
  bottom: -45px;
}
.multistate-switch-switcher_cell_title__1xMkQ {
  position: absolute;
  display: inline-block;
  padding: 2px 2px 0;
  left: 50%;
  bottom: -25px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  max-width: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
}
.multistate-switch-switcher_cell_title_full__2cTVE {
  background-color: #a9b5c9;
  overflow: initial;
  max-width: none;
  z-index: 2;
  color: #fff !important;
  border-radius: 4px;
}
.multistate-switch-switcher_cell__DF_Pu:first-child {
  border: none;
  min-width: 29px;
}
.multistate-switch-one_line_margin__3b8H6 {
  margin-bottom: 25px;
}
.multistate-switch-two_line_margin__3XeNv {
  margin-bottom: 45px;
}
.multistate-switch-title__30z0u {
  padding: 2px 2px 0;
}
