.change-avatar-container__1Yt-A {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.63);
  overflow-y: auto;
}
.change-avatar-dropzone__setqC {
  width: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 5px;
  font-size: 20px;
  font-family: Hind;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  color: #53a8ff;
  border: 3px dashed #53a8ff;
}
.change-avatar-cropper-container__10UHe {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 3px;
  background-color: #fff;
  padding: 15px;
}
.change-avatar-cropper-container__button__1cxSZ {
  width: 70px;
  border-radius: 2px;
  background-color: #fff;
  border: solid 1px #56aafc;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #53a8ff;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  vertical-align: top;
  margin-left: 10px;
  padding: 8px 0 7px;
}
.change-avatar-cropper-container__button__1cxSZ:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
.change-avatar-cropper-container__button_save__24q5q {
  color: #fff;
  background-color: #53a8ff;
}
.change-avatar-cropper-container__button__1cxSZ:focus {
  outline: none;
}
.change-avatar-cropper__23Uw5 {
  width: 300px;
  height: 300px;
  margin-bottom: 50px;
}
.change-avatar-hide__JLb7h {
  visibility: hidden;
}
