.ranking-conversation-item-conversation__32xS9 {
  background-color: #fff;
  padding: 14px;
  font-family: Hind;
  font-style: normal;
  font-stretch: normal;
  overflow: auto;
}
.ranking-conversation-item-conversation__customer-info__3x6o_ {
  margin-bottom: 15px;
}
.ranking-conversation-item-conversation__customer-info-name__3QVaN {
  font-size: 14px;
  font-weight: 600;
  color: #6c6c7c;
  margin-right: 10px;
}
.ranking-conversation-item-conversation__customer-info-service__2ixWs {
  font-size: 13px;
  font-weight: normal;
  color: #919aa2;
}
.ranking-conversation-item-conversation__customer-info-date__25si2 {
  font-size: 13px;
  font-weight: normal;
  color: #919aa2;
  float: right;
}
.ranking-conversation-item-conversation__tags__mzU2N {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
}
.ranking-conversation-item-conversation__tag__3QLdN {
  display: inline-block;
  padding: 9px 8px 7px;
  border-radius: 3px;
  border: solid 1px #a9b5c9;
  font-family: Hind;
  font-size: 13px;
  line-height: 0.77;
  color: #a9b5c9;
  margin-right: 10px;
  word-break: break-word;
  margin-bottom: 8px;
}
.ranking-conversation-item-conversation__conversation-info-tag__2pJa1 {
  font-size: 12px;
  font-weight: bold;
  line-height: 0.83;
  color: #fff;
  margin-right: 7px;
  border-radius: 3px;
  padding: 4px 10px;
}
.ranking-conversation-item-conversation__conversation-info-tag_ranked__1DKGz {
  background-color: #a9b5c9;
}
.ranking-conversation-item-conversation__conversation-info-tag_unranked__6QVVz {
  background-color: #6c6c7c;
}
.ranking-conversation-item-conversation__conversation-info-tag_nonrankable__3rs-l {
  border-radius: 3px;
  border: solid 1px #bcbebf;
  background-color: #fff;
  color: #919aa2;
}
.ranking-conversation-item-conversation__conversation-info-tag_bad__1Hpk2,
.ranking-conversation-item-conversation__conversation-info-tag_bad-tag__1XhLV {
  background-color: #f87b6e;
}
.ranking-conversation-item-conversation__conversation-info-tag_good__2v3oQ {
  background-color: #76d466;
}
.ranking-conversation-item-conversation__conversation-info-tag_comment__3CXw5 {
  background-color: #f1d518;
}
.ranking-conversation-item-conversation__conversation-info-time__oOoya {
  float: right;
  font-size: 12px;
  font-weight: normal;
  color: #47475b;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='10px' height='10px' viewBox='0 0 10 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3EE09CCDA8-99D2-4381-A623-60D1453A35E8%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='QA-dashboard_good' transform='translate(-313.000000, -239.000000)' fill='%23C9D1DE'%3E %3Cg id='good_1var' transform='translate(66.000000, 149.000000)'%3E %3Cg id='Group-13' transform='translate(247.000000, 85.000000)'%3E %3Cg id='Group-18' transform='translate(0.000000, 2.000000)'%3E %3Cpath d='M6.57698628,10.1173616 L4.61803034,8.15840563 L4.61803034,4.59246379 L5.38235182,4.59246379 L5.38235182,7.84197654 L7.11736156,9.57698628 L6.57698628,10.1173616 Z M5.00019108,3 C2.2386976,3 0,5.2386976 0,8.00019108 C0,10.7613024 2.2386976,13 5.00019108,13 C7.7613024,13 10,10.7613024 10,8.00019108 C10,5.2386976 7.7613024,3 5.00019108,3 L5.00019108,3 Z' id='Fill-1'%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  padding-left: 17px;
  background-position-y: center;
}
.ranking-conversation-item-conversation__conversation-info-message__2-264 {
  font-size: 13px;
  font-weight: normal;
  line-height: 1.08;
  color: #47475b;
  float: right;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='12px' height='10px' viewBox='0 0 12 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3E0E052E00-BD12-4124-B673-E54166966F60%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='0.600000024'%3E %3Cg id='QA-dashboard_good' transform='translate(-370.000000, -239.000000)' fill='%23A9B5C9'%3E %3Cg id='good_1var' transform='translate(66.000000, 149.000000)'%3E %3Cg id='Group-13' transform='translate(247.000000, 85.000000)'%3E %3Cg id='Group' transform='translate(57.000000, 0.000000)'%3E %3Cg id='Page-1' transform='translate(0.000000, 5.000000)'%3E %3Cpath d='M10.5006143,0 L1.31756757,0 C0.58995086,0 0,0.575694293 0,1.28572764 L0,6.66240688 C0,7.37244023 0.58995086,7.94813453 1.31756757,7.94813453 L7.00658477,7.94813453 L9.23127764,10 L9.23127764,7.94813453 L10.5006143,7.94813453 C11.228231,7.94813453 11.8181818,7.37244023 11.8181818,6.66240688 L11.8181818,1.28572764 C11.8181818,0.575694293 11.228231,0 10.5006143,0' id='Fill-1'%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-position-y: center;
  padding-left: 19px;
  margin-left: 16px;
}
