.assigned-agents-container__36Qqv {
  display: inline-block;
  margin-left: 25px;
  color: #b7c1d2;
  font-weight: 500;
  font-size: 11px;
}
.assigned-agents-list__3rNXJ {
  margin: 0;
  padding: 0;
  list-style: none;
}
.assigned-agents-unassigned__FuIDw {
  margin-left: 3px;
}
.assigned-agents-item__1NeES {
  cursor: pointer;
  display: inline-block;
  padding-right: 22px;
  position: relative;
}
.assigned-agents-item__1NeES:after {
  display: none;
  content: '×';
  font-weight: 900;
  position: absolute;
  right: 10px;
  top: 0px;
}
.assigned-agents-item__1NeES:hover {
  color: #000;
}
.assigned-agents-item__1NeES:hover:after {
  display: inline-block;
}
