.styles-button-base__hBnAb {
  border: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 2px;
}
.styles-button-base__hBnAb:focus {
  outline: none;
}
.styles-button-default__rDJ5u {
  padding: 0 14px;
  height: 32px;
  line-height: 33px;
}
.styles-button-default__rDJ5u.styles-only_icon__3-fxN {
  font-size: 18px;
  width: 36px;
}
.styles-button-default__rDJ5u .styles-badge__TdhNx {
  height: 16px;
  line-height: 16px;
}
.styles-button-small__3vys5 {
  padding: 0 13px;
  text-transform: uppercase;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.styles-button-small__3vys5.styles-only_icon__3-fxN {
  padding-top: 9px;
  font-size: 13px;
  width: 28px;
}
.styles-button-extra-small__38GXo {
  padding: 0 6px;
  text-transform: uppercase;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
}
.styles-button-primary__2iqfu {
  background-color: #188fea;
  color: #fff;
}
.styles-button-primary__2iqfu:hover {
  background-color: #0a71bf;
}
.styles-button-light__CANzm,
.styles-open_button__sqDwA {
  background-color: #fff;
  color: #0a71bf;
}
.styles-button-transparent__3-jZG {
  background-color: none;
  color: #fff;
  border: 1px solid #fff;
}
.styles-button-transparent__3-jZG:hover {
  background-color: #fff;
  color: #0a71bf;
}
.styles-button-danger__3Vqay {
  background-color: #d85151;
  color: #fff;
}
.styles-button-danger__3Vqay:hover {
  background-color: #c13030;
}
.styles-button-full__3nBdK {
  width: 100%;
  text-align: center;
}
.styles-button-secondary__3JRRm {
  background-color: #aaa;
  color: #fff;
  font-weight: bold;
}
.styles-button-secondary__3JRRm:hover {
  background-color: #0a71bf;
}
.styles-button-tertiary__3CUcq {
  color: #aaa;
  font-weight: bold;
}
.styles-button-tertiary__3CUcq:hover {
  color: #0a71bf;
}
.styles-button-dark__zhZVF {
  background-color: #0a71bf;
  color: #fff;
}
.styles-button-dark__zhZVF:hover {
  background-color: #005fa7;
}
.styles-button-only-icon__Vx7dh {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.styles-button-icon-big__1w_Sa {
  font-size: 18px;
  width: 38px;
}
.styles-button-icon__31n3s {
  margin-right: 5px;
}
.styles-button-bold__Hkv1y {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.styles-button-float-right__R3C-q {
  float: right;
}
.styles-button-with-hr__3efCx {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 0;
}
.styles-button-with-hr__3efCx:after {
  display: block;
  position: relative;
  bottom: 15px;
  content: '';
  border-bottom: 1px solid #c1cdd6;
  z-index: -1;
}
.styles-button-processing__2x5qu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
}
.styles-button-invisible__3tymh {
  visibility: hidden;
}
.styles-button-tooltip__aYLVW {
  top: 38px;
  left: 50%;
  margin-left: -75px;
  color: #fff;
  width: 150px;
  font-size: 13px;
  text-align: center;
  text-transform: initial;
  position: absolute;
  padding: 5px;
  z-index: 2;
  background-color: #3e434d;
  height: 26px;
  line-height: 16px;
  opacity: 0.8;
  border-radius: 2px;
}
.styles-button-tooltip__aYLVW:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-bottom-color: #3e434d;
  border-width: 5px;
  margin-left: -5px;
}
.styles-button-badge__3rnCy {
  padding: 0px 7px;
  min-width: 10px;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #f86e5f;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 40px;
}
.styles-root__dxpy7 {
  display: inline-block;
  margin-top: 5px;
  margin-left: 5px;
}
.styles-open_button__sqDwA {
  height: 20px;
  width: 23px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 20px;
  cursor: pointer;
}
