.index-edit_body__3AGGN {
  margin: 10px;
  padding: 17px 12px;
  border: 1px solid #e6eaef;
  border-radius: 4px;
}
.index-edit_body__3AGGN .index-edit_body_radio_block__VC1Y- {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0 17px;
}
.index-edit_body__add_link__1KvP6 {
  font-size: 14px;
  line-height: 22px;
  color: #58aafc;
  cursor: pointer;
  margin-top: 8px;
  max-width: 63px;
  font-weight: 600;
}
.index-edit_body__3AGGN .index-edit_body_link__3Ibxn {
  position: relative;
}
.index-edit_body__3AGGN .index-edit_body_link__input__1n3O9 {
  border: 1px solid #e6eaef;
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;
  padding: 4px 12px;
  outline: none;
}
.index-edit_body__3AGGN .index-edit_body_link__input__1n3O9:placeholder {
  color: #aab6c8;
}
.index-edit_body__3AGGN .index-edit_body_link__icon__39Z41 {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.00008 0.166656C4.39175 0.166656 0.666748 3.89166 0.666748 8.49999C0.666748 13.1083 4.39175 16.8333 9.00008 16.8333C13.6084 16.8333 17.3334 13.1083 17.3334 8.49999C17.3334 3.89166 13.6084 0.166656 9.00008 0.166656ZM13.1667 11.4917L11.9917 12.6667L9.00008 9.67499L6.00841 12.6667L4.83341 11.4917L7.82508 8.49999L4.83341 5.50832L6.00841 4.33332L9.00008 7.32499L11.9917 4.33332L13.1667 5.50832L10.1751 8.49999L13.1667 11.4917Z' fill='%23AAB6C8'/%3E%3C/svg%3E%0A");
  width: 17px;
  height: 17px;
  display: block;
  cursor: pointer;
  position: absolute;
  top: 9px;
  right: 2px;
  background-position: center;
  padding: 13px;
  background-repeat: no-repeat;
  background-color: #fff;
}
