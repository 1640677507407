.calendar-content__4TnP2 {
  display: inline-block;
}
.calendar-slider__2l7Yv {
  height: 30px;
  border-radius: 3px;
  background-color: #fff;
  border: solid 1px #e6eaef;
}
.calendar-slider_active__2MuKu,
.calendar-slider__2l7Yv:hover {
  border-color: #53a8ff;
}
.calendar-slider__controls__YT0mn {
  background-color: #fff;
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: #e6eaef;
  height: 100%;
  width: 32px;
  vertical-align: top;
  border-radius: 3px 0 0 3px;
}
.calendar-slider__controls__YT0mn:focus {
  outline: none;
}
.calendar-slider__controls__YT0mn:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
.calendar-slider__controls-label__3-nxs {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: #53a8ff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  margin-right: -4px;
}
.calendar-slider__controls-label_forward__2jckF {
  border-width: 1px 1px 0 0;
  margin-right: 0;
  margin-left: -4px;
}
.calendar-slider__controls_forward__2QJNi {
  float: right;
  border-width: 0 0 0 1px;
  border-radius: 0 3px 3px 0;
}
.calendar-slider__date__3n-ZU {
  display: inline-block;
  width: 254px;
  height: 100%;
  padding-top: 8px;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: #6c6c7c;
  cursor: pointer;
}
.calendar-calendar__2TWgi {
  display: inline-block;
  position: absolute;
  width: 320px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 4px 0 rgba(0,0,0,0.07);
          box-shadow: 0 0 4px 0 rgba(0,0,0,0.07);
  border: solid 1px #d3d3d3;
  margin-top: 10px;
  z-index: 1;
}
.calendar-calendar_arrow__2gPVM {
  display: inline-block;
  position: absolute;
  left: 50%;
  width: 7px;
  height: 7px;
  background-color: #fff;
  border-color: #d3d3d3;
  border-style: solid;
  border-width: 1px 0 0 1px;
  -webkit-transform: rotate(45deg) translate(-50%);
          transform: rotate(45deg) translate(-50%);
  margin-top: -1px;
}
.calendar-calendar__toggle__Ew4VJ {
  margin: 10px 10px 0 34px;
  text-align: center;
}
.calendar-calendar__toggle-range-label__2k38z {
  font-family: Hind;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  color: #484848;
  vertical-align: super;
  text-transform: capitalize;
}
.calendar-calendar__toggle-switcher__3K2ik {
  display: inline-block;
  border-radius: 12px;
  margin: 3px;
  width: 22.2px;
  height: 22.2px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.09);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.09);
}
.calendar-calendar__toggle-overlay__TKckJ {
  width: 50px;
  height: 28px;
  display: inline-block;
  border-radius: 100px;
  background-color: #a9b5c9;
  cursor: pointer;
  text-align: left;
}
.calendar-calendar__toggle-overlay_weekly__1ncUi .calendar-calendar__toggle-switcher__3K2ik {
  float: right;
}
.calendar-calendar__data__iOp9G {
  width: 100%;
  padding: 25px 15px 20px;
}
.calendar-calendar__data-controls__2VhbA {
  text-align: center;
  margin-bottom: 18px;
}
.calendar-calendar__data-button__25G8h {
  background-color: #fff;
  border: none;
}
.calendar-calendar__data-button__25G8h:focus,
.calendar-calendar__data-button__25G8h:active {
  outline: none;
}
.calendar-calendar__data-button__25G8h span {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: -7px;
  border-color: #53a8ff;
  border-style: solid;
  border-width: 0 0 3px 3px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.calendar-calendar__data-button_forward__vsCq8 span {
  border-width: 3px 3px 0 0;
  margin: 0 0 0 -7px;
}
.calendar-calendar__data-month__4Lp-1 {
  display: inline-block;
  width: 200px;
  font-family: Hind;
  font-size: 19px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: #484848;
}
.calendar-calendar__table__1Tyhs {
  text-align: center;
}
.calendar-calendar__table-head__3SFGe {
  border-bottom: solid 2px #d9d9d9;
  margin: 0;
}
.calendar-calendar__table-head-item__3MAz5 {
  display: inline-block;
  width: 40px;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1.7px;
  color: #6b6b6b;
  text-align: center;
  margin-bottom: 13px;
}
.calendar-calendar__table-body-item__1dcb1 {
  display: inline-block;
  width: 40px;
  padding: 13px 0;
  border-radius: 50%;
  text-align: center;
  font-family: Hind;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #484848;
}
.calendar-calendar__table-body-item_gray__3Ucph {
  color: #aab7c9;
}
.calendar-calendar__table-body-item_hover__1wjFn:hover {
  background-color: #53a8ff;
  border-radius: 100px;
  color: #fff;
}
.calendar-calendar__table-body-item_selected__2Cvk6 {
  background-color: #53a8ff;
  border-radius: 100px;
  color: #fff;
}
.calendar-calendar__table-body-row__HNypG {
  cursor: pointer;
  margin: 0;
}
.calendar-calendar__table-body-row_selected__14_Ya {
  background-color: #53a8ff;
  border-radius: 100px;
}
.calendar-calendar__table-body-row_selected__14_Ya span {
  color: #fff;
  font-weight: bold;
}
.calendar-calendar__table-body-row_hover__2H2_p:hover {
  background-color: #53a8ff;
  border-radius: 100px;
}
.calendar-calendar__table-body-row_hover__2H2_p:hover span {
  color: #fff;
  font-weight: bold;
}
.calendar-range_date_middle__1isDu,
.calendar-range_date_end__JasMZ,
.calendar-range_date_begin__-wgJG {
  background-color: #53a8ff;
  color: #fff;
  font-weight: bold;
  border-radius: 0;
}
.calendar-range_date_begin__-wgJG {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.calendar-range_date_end__JasMZ {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.calendar-range_date_begin_several_lines__356a7 {
  border-bottom-left-radius: 0;
  border-top-left-radius: 40px;
}
.calendar-range_date_end_several_lines__1JpXA {
  border-top-right-radius: 0;
  border-bottom-right-radius: 40px;
}
.calendar-reset_button__3ENku {
  background: #fff;
  border: 1px solid #eee;
  padding: 5px 20px;
  display: block;
  margin: -10px auto 5px;
}
.calendar-reset_button__3ENku:hover {
  background: #eee;
}
.calendar-reset_button__3ENku:active,
.calendar-reset_button__3ENku:focus {
  outline: none;
}
