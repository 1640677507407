.reminder-edit-component-button-base__3NP5I {
  border: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 2px;
}
.reminder-edit-component-button-base__3NP5I:focus {
  outline: none;
}
.reminder-edit-component-button-default__3_DPt,
.reminder-edit-component-save_reminder__3qVeU {
  padding: 0 14px;
  height: 32px;
  line-height: 33px;
}
.reminder-edit-component-button-default__3_DPt.reminder-edit-component-only_icon__238vY,
.reminder-edit-component-save_reminder__3qVeU.reminder-edit-component-only_icon__238vY {
  font-size: 18px;
  width: 36px;
}
.reminder-edit-component-button-default__3_DPt .reminder-edit-component-badge__3q6o3,
.reminder-edit-component-save_reminder__3qVeU .reminder-edit-component-badge__3q6o3 {
  height: 16px;
  line-height: 16px;
}
.reminder-edit-component-button-small__vANiH,
.reminder-edit-component-save_reminder__3qVeU {
  padding: 0 13px;
  text-transform: uppercase;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.reminder-edit-component-button-small__vANiH.reminder-edit-component-only_icon__238vY,
.reminder-edit-component-save_reminder__3qVeU.reminder-edit-component-only_icon__238vY {
  padding-top: 9px;
  font-size: 13px;
  width: 28px;
}
.reminder-edit-component-button-extra-small__Maodr {
  padding: 0 6px;
  text-transform: uppercase;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
}
.reminder-edit-component-button-primary__12lsa,
.reminder-edit-component-save_reminder__3qVeU {
  background-color: #188fea;
  color: #fff;
}
.reminder-edit-component-button-primary__12lsa:hover,
.reminder-edit-component-save_reminder__3qVeU:hover {
  background-color: #0a71bf;
}
.reminder-edit-component-button-light__1GEFT {
  background-color: #fff;
  color: #0a71bf;
}
.reminder-edit-component-button-transparent__Hn3QT {
  background-color: none;
  color: #fff;
  border: 1px solid #fff;
}
.reminder-edit-component-button-transparent__Hn3QT:hover {
  background-color: #fff;
  color: #0a71bf;
}
.reminder-edit-component-button-danger__2sbAC {
  background-color: #d85151;
  color: #fff;
}
.reminder-edit-component-button-danger__2sbAC:hover {
  background-color: #c13030;
}
.reminder-edit-component-button-full__3-16_ {
  width: 100%;
  text-align: center;
}
.reminder-edit-component-button-secondary__3AAjc {
  background-color: #aaa;
  color: #fff;
  font-weight: bold;
}
.reminder-edit-component-button-secondary__3AAjc:hover {
  background-color: #0a71bf;
}
.reminder-edit-component-button-tertiary__haQD- {
  color: #aaa;
  font-weight: bold;
}
.reminder-edit-component-button-tertiary__haQD-:hover {
  color: #0a71bf;
}
.reminder-edit-component-button-dark__QuKWn {
  background-color: #0a71bf;
  color: #fff;
}
.reminder-edit-component-button-dark__QuKWn:hover {
  background-color: #005fa7;
}
.reminder-edit-component-button-only-icon__28MkG {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.reminder-edit-component-button-icon-big__1QQhU {
  font-size: 18px;
  width: 38px;
}
.reminder-edit-component-button-icon__3tcSN {
  margin-right: 5px;
}
.reminder-edit-component-button-bold__1li16 {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.reminder-edit-component-button-float-right__159CE {
  float: right;
}
.reminder-edit-component-button-with-hr__2epve {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 0;
}
.reminder-edit-component-button-with-hr__2epve:after {
  display: block;
  position: relative;
  bottom: 15px;
  content: '';
  border-bottom: 1px solid #c1cdd6;
  z-index: -1;
}
.reminder-edit-component-button-processing__2w2hs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
}
.reminder-edit-component-button-invisible__12WTw {
  visibility: hidden;
}
.reminder-edit-component-button-tooltip__3EmoL {
  top: 38px;
  left: 50%;
  margin-left: -75px;
  color: #fff;
  width: 150px;
  font-size: 13px;
  text-align: center;
  text-transform: initial;
  position: absolute;
  padding: 5px;
  z-index: 2;
  background-color: #3e434d;
  height: 26px;
  line-height: 16px;
  opacity: 0.8;
  border-radius: 2px;
}
.reminder-edit-component-button-tooltip__3EmoL:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-bottom-color: #3e434d;
  border-width: 5px;
  margin-left: -5px;
}
.reminder-edit-component-button-badge__1whyk {
  padding: 0px 7px;
  min-width: 10px;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #f86e5f;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 40px;
}
.reminder-edit-component-reminder__3vqMC {
  margin-bottom: 30px;
}
.reminder-edit-component-container__2qlzg {
  position: relative;
}
.reminder-edit-component-textarea__1XtSC {
  width: 100%;
  min-height: 82px;
  border: solid 1px rgba(173,175,187,0.57);
  border-radius: 2px;
  padding: 10px 10px;
  margin-bottom: 20px;
  width: 593px;
  resize: vertical;
}
.reminder-edit-component-datepicker_container__-AhIt,
.reminder-edit-component-timepicker_container__3WMMo {
  display: inline-block;
  width: 140px;
}
.reminder-edit-component-datepicker_container__-AhIt {
  margin-right: 20px;
}
.reminder-edit-component-datepicker__3FgSo {
  border-radius: 4px;
  border: solid 1px #aeafbb;
}
.reminder-edit-component-save_reminder__3qVeU {
  margin: auto;
  margin-top: 15px;
  background-color: #53a8ff;
  text-transform: capitalize;
  font-weight: 600;
}
.reminder-edit-component-save_reminder_wrapper__24rVo {
  width: 100%;
  text-align: center;
}
.reminder-edit-component-preset_title__2L22m {
  display: inline-block;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: #aeafbb;
}
.reminder-edit-component-preset_option__ZoS3y {
  width: 35px;
  height: 32px;
  border-radius: 3px;
  background-color: #fff;
  border: solid 1px #aeafbb;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: #aeafbb;
  margin-right: 10px;
  vertical-align: middle;
}
.reminder-edit-component-preset_option__ZoS3y:disabled {
  background: #eee;
}
.reminder-edit-component-preset_option__ZoS3y:focus {
  outline: none;
}
.reminder-edit-component-preset_option__ZoS3y:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
.reminder-edit-component-preset_options__25kV1 {
  display: inline-block;
  margin-left: 10px;
}
.reminder-edit-component-error__1MeoM {
  color: #f00;
}
