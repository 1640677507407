.conversations-list-button-base__3AMPM {
  border: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 2px;
}
.conversations-list-button-base__3AMPM:focus {
  outline: none;
}
.conversations-list-button-default__23v0l {
  padding: 0 14px;
  height: 32px;
  line-height: 33px;
}
.conversations-list-button-default__23v0l.conversations-list-only_icon__2iOCE {
  font-size: 18px;
  width: 36px;
}
.conversations-list-button-default__23v0l .conversations-list-badge__3kHe2 {
  height: 16px;
  line-height: 16px;
}
.conversations-list-button-small__XX_pO,
.conversations-list-try_again__3zZYV {
  padding: 0 13px;
  text-transform: uppercase;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.conversations-list-button-small__XX_pO.conversations-list-only_icon__2iOCE,
.conversations-list-try_again__3zZYV.conversations-list-only_icon__2iOCE {
  padding-top: 9px;
  font-size: 13px;
  width: 28px;
}
.conversations-list-button-extra-small__2D-bA {
  padding: 0 6px;
  text-transform: uppercase;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
}
.conversations-list-button-primary__1glLH {
  background-color: #188fea;
  color: #fff;
}
.conversations-list-button-primary__1glLH:hover {
  background-color: #0a71bf;
}
.conversations-list-button-light__1CUzr {
  background-color: #fff;
  color: #0a71bf;
}
.conversations-list-button-transparent__1JczY {
  background-color: none;
  color: #fff;
  border: 1px solid #fff;
}
.conversations-list-button-transparent__1JczY:hover {
  background-color: #fff;
  color: #0a71bf;
}
.conversations-list-button-danger__USJcc,
.conversations-list-try_again__3zZYV {
  background-color: #d85151;
  color: #fff;
}
.conversations-list-button-danger__USJcc:hover,
.conversations-list-try_again__3zZYV:hover {
  background-color: #c13030;
}
.conversations-list-button-full__2InMe {
  width: 100%;
  text-align: center;
}
.conversations-list-button-secondary__1LLlX {
  background-color: #aaa;
  color: #fff;
  font-weight: bold;
}
.conversations-list-button-secondary__1LLlX:hover {
  background-color: #0a71bf;
}
.conversations-list-button-tertiary__3wk9j {
  color: #aaa;
  font-weight: bold;
}
.conversations-list-button-tertiary__3wk9j:hover {
  color: #0a71bf;
}
.conversations-list-button-dark__11zTc {
  background-color: #0a71bf;
  color: #fff;
}
.conversations-list-button-dark__11zTc:hover {
  background-color: #005fa7;
}
.conversations-list-button-only-icon__2IpnZ {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.conversations-list-button-icon-big__t-kfm {
  font-size: 18px;
  width: 38px;
}
.conversations-list-button-icon__4f8J5 {
  margin-right: 5px;
}
.conversations-list-button-bold__1w6UZ {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.conversations-list-button-float-right__1CjXI {
  float: right;
}
.conversations-list-button-with-hr__dv3dh {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 0;
}
.conversations-list-button-with-hr__dv3dh:after {
  display: block;
  position: relative;
  bottom: 15px;
  content: '';
  border-bottom: 1px solid #c1cdd6;
  z-index: -1;
}
.conversations-list-button-processing__1eSWd {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
}
.conversations-list-button-invisible__2MYns {
  visibility: hidden;
}
.conversations-list-button-tooltip__3V9ld {
  top: 38px;
  left: 50%;
  margin-left: -75px;
  color: #fff;
  width: 150px;
  font-size: 13px;
  text-align: center;
  text-transform: initial;
  position: absolute;
  padding: 5px;
  z-index: 2;
  background-color: #3e434d;
  height: 26px;
  line-height: 16px;
  opacity: 0.8;
  border-radius: 2px;
}
.conversations-list-button-tooltip__3V9ld:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-bottom-color: #3e434d;
  border-width: 5px;
  margin-left: -5px;
}
.conversations-list-button-badge__2dZe2 {
  padding: 0px 7px;
  min-width: 10px;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #f86e5f;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 40px;
}
.conversations-list-tag-rect__2eXS7,
.conversations-list-tag__21Vye {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border: 1px solid #b7c1d3;
  border-radius: 4px;
  display: inline-block;
  color: #b7c1d3;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.conversations-list-tag-rect__2eXS7:before,
.conversations-list-tag__21Vye:before {
  content: '#';
}
.conversations-list-tag-rect__2eXS7.conversations-list-no-border__13r7f,
.conversations-list-tag__21Vye.conversations-list-no-border__13r7f {
  border: none;
  padding-top: 1px;
}
.conversations-list-error-default__3RNhP,
.conversations-list-error__19LvT {
  display: block;
  margin: 6px 6px 6px 6px;
  border: 2px dashed #d85151;
  padding: 20px;
  text-decoration: none;
  position: relative;
  background-color: #fff8f8;
  color: #d85151;
}
.conversations-list-conversations_list_with_average_response_time__1UW73 {
  max-height: 412px;
  border: 1px solid #e6e8eb;
}
.conversations-list-header__1DAI4 {
  height: 45px;
  border-bottom: 2px solid #e6eaef;
  padding: 13px;
  position: relative;
  background-color: #fff;
}
.conversations-list-title__1yDFw {
  margin-left: 10px;
  color: #6c6c7c;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
}
.conversations-list-search_input__temIw {
  position: absolute;
  top: 12px;
  right: 40px;
  border: 1px solid #e6e8eb;
  width: 20%;
  height: 17px;
  display: none;
}
.conversations-list-search_button__m_xx3 {
  width: 17px;
  height: 17px;
  display: inline-block;
  position: absolute;
  cursor: pointer;
  top: 12px;
  right: 15px;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8' standalone='no'?%3E %3Csvg width='17px' height='17px' viewBox='0 0 17 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: Sketch 39.1 (31720) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3Esearch icon%3C/title%3E %3Cdesc%3ECreated with Sketch.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='4-charts' transform='translate(-661.000000, -82.000000)' fill='%2353A8FF'%3E %3Cg id='search-icon' transform='translate(661.000000, 82.000000)'%3E %3Cpath d='M11.8666667,6.93333333 C11.8666667,4.20872857 9.6579381,2 6.93333333,2 C4.20872857,2 2,4.20872857 2,6.93333333 C2,9.6579381 4.20872857,11.8666667 6.93333333,11.8666667 C9.6579381,11.8666667 11.8666667,9.6579381 11.8666667,6.93333333 Z M0,6.93333333 C0,3.10415907 3.10415907,0 6.93333333,0 C10.7625076,0 13.8666667,3.10415907 13.8666667,6.93333333 C13.8666667,10.7625076 10.7625076,13.8666667 6.93333333,13.8666667 C3.10415907,13.8666667 0,10.7625076 0,6.93333333 Z' id='Oval-2'%3E%3C/path%3E %3Cpolygon id='Path-6' points='14.7974627 16.2116763 16.2116763 14.7974627 12.4404401 11.0262266 11.0262266 12.4404401'%3E%3C/polygon%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
}
.conversations-list-scroll__3yQmr {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px 10px 10px 10px;
  background-color: #fff;
  max-height: 365px;
}
.conversations-list-error__19LvT {
  text-align: center;
}
.conversations-list-no_results__cZ6gE {
  padding: 20px;
}
.conversations-list-row__2NgWc {
  display: block;
  width: 100%;
}
.conversations-list-item_container__335de {
  display: inline-block;
  border-bottom: 1px solid #e6eaef;
  padding: 10px 0px 10px 10px;
  background-color: #fff;
  text-decoration: none;
  position: relative;
  line-height: 22px;
  height: 44px;
  width: calc(100% - 10px);
  vertical-align: middle;
}
.conversations-list-item_container__335de:hover .conversations-list-awaiting_time__1Ccu8 {
  display: none;
}
.conversations-list-item_container__335de:hover .conversations-list-sent_button__1qVb3 {
  display: inline-block;
}
.conversations-list-marker_div__1tLIt {
  vertical-align: middle;
  width: 10px;
  line-height: 20px;
  height: 20px;
  display: inline-block;
}
.conversations-list-status_marker__1tYwr {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 5px;
}
.conversations-list-status_marker__1tYwr.conversations-list-marker_status_new__3yy2V {
  background: #76d466;
}
.conversations-list-status_marker__1tYwr.conversations-list-marker_status_opened__2Bnq9 {
  background: #53a8ff;
}
.conversations-list-status_marker__1tYwr.conversations-list-marker_status_closed__AjcgQ {
  background: #999;
}
.conversations-list-status_marker__1tYwr.conversations-list-marker_status_dormant__3rB_h {
  background: #ff0;
}
.conversations-list-full_width_block__2j9Oi {
  width: calc(100% - 60px);
  display: inline-block;
}
.conversations-list-credential_value__2pns_ {
  vertical-align: middle;
  color: #6c6c7c;
  font-size: 13px;
  font-weight: 600;
  width: 30%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
  height: 20px;
  display: inline-block;
}
.conversations-list-tag_conversation__1FkVu {
  margin-right: 10px;
  font-size: 12px;
  line-height: 12px;
  color: #919aa2;
  font-weight: 500;
  border: none !important;
}
.conversations-list-tags_container__3pMMB {
  vertical-align: middle;
  color: #b7c1d2;
  font-size: 12px;
  font-weight: 500;
  width: 35%;
  display: inline-block;
  position: relative;
}
.conversations-list-tags__1QJ7a {
  white-space: nowrap;
  overflow: hidden;
}
.conversations-list-tag__21Vye {
  margin-right: 10px;
  margin-top: 5px;
  vertical-align: top;
  background-color: #fcfcfd;
}
.conversations-list-white_gradient__2iyvf {
  background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(80%, #fff));
  background-image: linear-gradient(to right, transparent 0%, #fff 80%);
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: 13px;
  padding-left: 50px;
}
.conversations-list-agents__1vnOk {
  vertical-align: middle;
  font-size: 12px;
  color: #a9b5c9;
  width: 35%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.conversations-list-agent__1gR8K {
  padding-right: 6px;
}
.conversations-list-agent__1gR8K:before {
  content: '@';
}
.conversations-list-awaiting_time_container__11TyH {
  vertical-align: middle;
  font-weight: 900;
  font-size: 13px;
  color: #f87b6e;
  width: 60px;
  display: inline-block;
  text-align: center;
  position: relative;
}
.conversations-list-awaiting_time__1Ccu8 {
  font-weight: 600;
  font-size: 13px;
  color: #f87b6e;
}
.conversations-list-sent_button__1qVb3 {
  width: 31px;
  height: 22px;
  display: none;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8' standalone='no'?%3E %3Csvg width='31px' height='22px' viewBox='0 0 31 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: Sketch 39.1 (31720) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3EGroup%3C/title%3E %3Cdesc%3ECreated with Sketch.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='4-charts' transform='translate(-644.000000, -397.000000)' fill='%2353A8FF'%3E %3Cg id='Group' transform='translate(644.000000, 397.000000)'%3E %3Cpath d='M1,18.9986131 C1,20.1044223 1.89547742,21 3.0005775,21 L27.9994225,21 C29.0990871,21 30,20.1006164 30,18.9986131 L30,3.00138694 C30,1.89557768 29.1045226,1 27.9994225,1 L3.0005775,1 C1.90091293,1 1,1.89938362 1,3.00138694 L1,18.9986131 Z M3.0005775,0 L27.9994225,0 C29.6565957,0 31,1.34308127 31,3.00138694 L31,18.9986131 C31,20.6562333 29.6480353,22 27.9994225,22 L3.0005775,22 C1.34340431,22 0,20.6569187 0,18.9986131 L0,3.00138694 C0,1.3437667 1.35196465,0 3.0005775,0 Z' id='Rectangle-4'%3E%3C/path%3E %3Cpath d='M23.1417129,10.7978672 L17.2833246,7 L17.2833246,8.87268972 C13.6215508,9.56440394 10.5671357,11.9739689 9,15.2383351 C10.9979106,13.6921945 13.5548163,12.7635353 16.3415435,12.7635353 C16.6590947,12.7635353 16.9725219,12.7789068 17.2833246,12.8025263 L17.2833246,14.9436536 L23.1417129,10.7978672 Z' id='Fill-1'%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
}
