.scheduled-messages-manager-container__271YG {
  border-radius: 3px;
  background-color: #fff;
}
.scheduled-messages-manager-header__2X6NB {
  padding: 14px 20px;
  border-bottom: solid 1px #dde5ef;
}
.scheduled-messages-manager-header__2X6NB:after {
  content: "";
  display: table;
  clear: both;
}
.scheduled-messages-manager-header__2X6NB h2 {
  float: left;
  font-size: 18px;
  font-weight: bold;
  color: #47475b;
  margin: 0;
  padding: 0;
}
.scheduled-messages-manager-close__1wnPC {
  float: right;
  cursor: pointer;
  margin-top: 3px;
}
.scheduled-messages-manager-close__1wnPC svg * {
  fill: #53a8ff;
}
.scheduled-messages-manager-content__2Z5vc {
  padding: 20px;
}
.scheduled-messages-manager-new_schedule__31db9 {
  position: relative;
}
.scheduled-messages-manager-new_message__2w-eN {
  display: block;
  width: 100%;
  height: 100px;
  padding: 15px;
  border-radius: 5px;
  border: solid 1px #a9b5c9;
  font-size: 14px;
  color: #47475b;
  resize: none;
  margin-bottom: 15px;
}
.scheduled-messages-manager-new_message__2w-eN:focus,
.scheduled-messages-manager-new_message__2w-eN:active {
  outline: none;
}
.scheduled-messages-manager-datepicker_container__2bwQ7,
.scheduled-messages-manager-timepicker_container__3pTCv {
  display: inline-block;
  width: 140px;
}
.scheduled-messages-manager-datepicker_container__2bwQ7 {
  margin-right: 20px;
}
.scheduled-messages-manager-datepicker__1DpfH {
  border-radius: 4px;
  border: solid 1px #aeafbb;
}
.scheduled-messages-manager-submit__1xh3L {
  float: right;
}
.scheduled-messages-manager-controls__1N6O9 {
  margin-bottom: 30px;
}
.scheduled-messages-manager-disabled_overlay__SI5bj {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,0.4);
}
.scheduled-messages-manager-divider__1ju4H {
  border-radius: 3px;
  background-color: #edf6ff;
  font-size: 14px;
  color: #47475b;
  text-align: center;
  padding: 9px 0;
  margin-bottom: 15px;
}
.scheduled-messages-manager-divider__1ju4H strong {
  font-weight: 600;
}
.scheduled-messages-manager-messages__1gc6p {
  list-style: none;
  margin: 0;
  padding: 0;
}
.scheduled-messages-manager-message_item__275mo {
  padding-bottom: 20px;
  border-bottom: 2px dotted #dde5ef;
  margin-bottom: 20px;
}
.scheduled-messages-manager-message_item__275mo:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.scheduled-messages-manager-error__3fEt5 {
  color: #f00;
}
