.scheduled-item-header-header__2q5I5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #323d49;
  font-size: 14px;
  line-height: 24px;
}
.scheduled-item-header-string_divider__3ews5 {
  display: inline-block;
  margin: 3px 10px;
  height: 4px;
  width: 4px;
  background-color: #c2c2cc;
  border-radius: 50%;
  padding: 0;
}
.scheduled-item-header-time_gray__3jU4- {
  margin-left: 4px;
  color: #9090a6;
}
.scheduled-item-header-operator_image__3Ax02 {
  margin-right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  vertical-align: bottom;
}
.scheduled-item-header-operator_info__2cjzM {
  display: inline-block;
}
.scheduled-item-header-activities__1iQKs {
  margin-left: 20px;
}
