.confirm-dialog-overlay__3Cmik {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 19;
  background: rgba(0,0,0,0.2);
}
.confirm-dialog-container__1sN0X {
  width: 550px;
  height: 160px;
  position: fixed;
  z-index: 20;
  top: 50%;
  left: 50%;
  margin-top: -80px;
  margin-left: -275px;
  border: 1px solid #ddd;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0 0 20px 3px rgba(0,0,0,0.2);
          box-shadow: 0 0 20px 3px rgba(0,0,0,0.2);
}
.confirm-dialog-header__2JKPu {
  background-color: #eee;
  border-bottom: 1px solid #ddd;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 40px;
}
.confirm-dialog-body__eAaLq {
  background-color: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 80px;
  padding: 20px;
  text-align: justify;
}
.confirm-dialog-footer__3gFOy {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-height: 40px;
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: baseline;
      -ms-flex-pack: baseline;
          justify-content: baseline;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  overflow: hidden;
  background-color: #fff;
}
.confirm-dialog-close__mokkH {
  float: right;
  cursor: pointer;
  margin: 8px 8px 0 0;
  padding: 5px;
  border-radius: 3px;
  background-color: #fff;
}
.confirm-dialog-button__1CGl2 {
  height: auto;
  min-width: 100px;
}
.confirm-dialog-button__1CGl2 + .confirm-dialog-button__1CGl2 {
  margin-right: 10px;
}
