.index-report_body__302nI {
  padding: 0 16px 8px 16px;
}
.index-report_body--is_log__39GMW:not(:last-child) {
  border-bottom: 1px solid #e6eaef;
}
.index-report_body--changed__1NK-1 {
  color: #eb5757 !important;
  font-weight: 700 !important;
}
.index-report_body__302nI .index-report_body_log_title__152qw {
  margin-top: 12px;
  margin-bottom: 8px;
}
.index-report_body__302nI .index-report_body_log_title__type_tag__1-Asy {
  height: 19px;
  border-radius: 100px;
  padding: 2px 8px 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 100px;
  max-width: 100px;
  margin-bottom: 8px;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: 58px;
  font-weight: 600;
}
.index-report_body__302nI .index-report_body_log_title__type_tag--edit__1VSoI {
  background: #f2994a;
  color: #fff;
}
.index-report_body__302nI .index-report_body_log_title__type_tag--create__N0Zkm {
  background: #6c6d7c;
  color: #fff;
}
.index-report_body__302nI .index-report_body_log_title__author__24e7R {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #6c6d7c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0 8px;
  overflow: hidden;
}
.index-report_body__302nI .index-report_body_log_title__author__24e7R > * {
  white-space: nowrap;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.index-report_body__302nI .index-report_body_log_title__author__point__2uoGd {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #6c6d7c;
}
.index-report_body__302nI .index-report_body_log_title__author__name__57geS {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 108px;
}
.index-report_body__302nI .index-report_body_head_block__3U-RJ {
  padding: 20px 0 9px 0;
}
.index-report_body__302nI .index-report_body_head_block__3U-RJ .index-report_body_head_block_item__2JHec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  line-height: 16px;
}
.index-report_body__302nI .index-report_body_head_block__3U-RJ .index-report_body_head_block_item__2JHec:not(:last-child) {
  margin-bottom: 12px;
}
.index-report_body__302nI .index-report_body_head_block__3U-RJ .index-report_body_head_block_item__title__1dCs5 {
  color: #6c6d7c;
  min-width: 60px;
}
.index-report_body__302nI .index-report_body_head_block__3U-RJ .index-report_body_head_block_item__value__3HCO- {
  color: #2c2d40;
  font-weight: 700;
}
.index-report_body__302nI .index-report_body_ranking_rule__3hLnU {
  margin: 24px 0 8px 0;
}
.index-report_body__302nI .index-report_body_ranking_rule__priority__aUnUs {
  height: 19px;
  border-radius: 100px;
  padding: 2px 8px 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 100px;
  max-width: 100px;
  margin-bottom: 8px;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.index-report_body__302nI .index-report_body_ranking_rule__priority--high__3lxaF {
  color: #fff;
  background: #d87a7a;
}
.index-report_body__302nI .index-report_body_ranking_rule__priority--medium__2r6lL {
  color: #47475b;
  background: rgba(255,199,59,0.71);
}
.index-report_body__302nI .index-report_body_ranking_rule__priority--low__3RsaB {
  color: #fff;
  background: rgba(0,203,132,0.72);
}
.index-report_body__302nI .index-report_body_ranking_rule__rule__i5VCz {
  font-size: 14px;
  max-height: 70px;
  overflow: hidden;
  -webkit-transition: max-height 0.3s;
  transition: max-height 0.3s;
}
.index-report_body__302nI .index-report_body_ranking_rule__rule--full__11PVV {
  max-height: calc(100% + 20px);
}
.index-report_body__302nI .index-report_body_ranking_rule__show_m_l__3lYp7 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.index-report_body__302nI .index-report_body_ranking_rule__show_m_l__3lYp7:hover {
  opacity: 0.5;
}
.index-report_body__comment__2315N {
  font-size: 14px;
  line-height: 20px;
  max-height: 80px;
  overflow: hidden;
  -webkit-transition: max-height 0.3s;
  transition: max-height 0.3s;
  word-break: break-word;
}
.index-report_body__comment--full__2wWPs {
  max-height: calc(100% + 20px);
}
.index-report_body__link__1_Eaq {
  margin-bottom: 8px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
.index-report_body__label__354bm {
  color: #6c6d7c;
  font-size: 14px;
}
