.custom-multi-select-icon__1SQ9F {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: #fff;
  border: solid 1px #e6eaef;
  vertical-align: middle;
  margin-right: 10px;
}
.custom-multi-select-icon_active__3ZJ1u {
  border: 0;
  background: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3E220E13FF-1AA8-4390-9878-12EE97A02E45%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='QA-dashboard_bad-mark' transform='translate(-96.000000, -214.000000)'%3E %3Cg id='list' transform='translate(83.000000, 84.000000)'%3E %3Cg id='Group-32'%3E %3Cg id='Group-31'%3E %3Cg transform='translate(0.000000, 119.000000)'%3E %3Cg id='Group-28' transform='translate(13.000000, 11.000000)'%3E %3Crect id='Rectangle-18' fill='%2356AAFC' x='0' y='0' width='18' height='18' rx='2'%3E%3C/rect%3E %3Cpolyline id='Path-4' stroke='%23FFFFFF' stroke-width='2' points='4.71428571 8.33116883 8.06493506 11.6818182 13.9285714 5.81818182'%3E%3C/polyline%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E") center no-repeat;
}
