.hash-tag-list-container__3zz-I {
  position: relative;
  background-color: #fff;
  min-height: 44px;
}
.hash-tag-list-list__2ZxH6 {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  background-color: #fff;
  max-height: 300px;
  overflow-y: auto;
}
.hash-tag-list-list_item__1ocfj {
  position: relative;
  cursor: pointer;
  border-bottom: solid 1px #b9bcc1;
}
.hash-tag-list-list_item__1ocfj:last-child {
  border-bottom: none;
}
.hash-tag-list-list_item__1ocfj:hover {
  background-color: rgba(241,243,246,0.5);
}
.hash-tag-list-list_item_group__302Re {
  margin-left: 15px;
}
