.agent-list-item-agent__1KjlY {
  border: 1px solid #e6eaef;
  background-color: #fff;
}
.agent-list-item-agent__1KjlY:hover {
  background-color: #edf6ff;
}
.agent-list-item-agent__1KjlY:first-child {
  border-radius: 3px 3px 0 0;
}
.agent-list-item-agent__1KjlY:last-child {
  border-radius: 0 0 3px 3px;
}
.agent-list-item-agent__image__3iKeZ {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin: 7px 15px 7px 12px;
  background-color: #dde5ef;
}
.agent-list-item-agent__label__3OLiW {
  display: inline-block;
  vertical-align: top;
  margin: 14px 0 15px;
  font-family: Hind;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #47475b;
}
.agent-list-item-agent__label_secondary__39HBm {
  font-size: 14px;
  color: #6c6c7c;
}
.agent-list-item-agent__status__1V6KO {
  color: #f87b6e;
}
.agent-list-item-agent__status-point__1I3H8 {
  display: inline-block;
  width: 7px;
  height: 7px;
  background-color: #f87b6e;
  border-radius: 5px;
  margin: 17px 6px 0 0;
}
.agent-list-item-agent__status-point_online__2Cgj8 {
  background-color: #76d466;
}
.agent-list-item-agent__status-point_online__2Cgj8 + .agent-list-item-agent__status__1V6KO {
  color: #76d466;
}
.agent-list-item-agent__status-point_break__1CWlL {
  background-color: #feaa06;
}
.agent-list-item-agent__status-point_break__1CWlL + .agent-list-item-agent__status__1V6KO {
  color: #feaa06;
}
.agent-list-item-agent__link__2fmOI {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.agent-list-item-agent__row__33uXR {
  white-space: nowrap;
}
.agent-list-item-agent__row_name__MqUS9 {
  width: 400px;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.agent-list-item-agent__row_role__1WurM {
  width: 195px;
}
.agent-list-item-agent__row_status__1K_ZV {
  width: 165px;
}
