.change-category-modal-container__3YyDo {
  border-radius: 4px;
  background-color: #fff;
  width: 320px;
}
.change-category-modal-header__2Kd8X {
  padding: 15px 20px;
  border-bottom: 1px solid #dde5ef;
}
.change-category-modal-title__mSWzo {
  margin: 0;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  color: #47475b;
}
.change-category-modal-close__3_wmu {
  float: right;
  cursor: pointer;
  margin-top: 4px;
}
.change-category-modal-close__3_wmu svg * {
  fill: #53a8ff;
}
.change-category-modal-list__1Sn1C {
  list-style: none;
  padding: 15px 20px;
  margin: 0;
  overflow: auto;
  max-height: 350px;
}
.change-category-modal-item__1FXCV {
  display: block;
  font-size: 16px;
  line-height: 2.31;
  color: #47475b;
  cursor: pointer;
  padding: 0 15px;
  border-radius: 4px;
}
.change-category-modal-item__1FXCV:hover {
  border-radius: 4px;
  background-color: #e6eaef;
}
.change-category-modal-item_active__Db5j_ {
  color: #fff;
  background-color: #53a8ff !important;
}
.change-category-modal-item_sub__UoChx {
  margin-left: 30px;
}
.change-category-modal-check__1I-5e {
  float: right;
  margin-top: 8px;
}
