.index-qa_info_menu__2KrWg {
  top: 9px;
  right: 8px;
  position: absolute;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 10.5C4.9 10.5 4 11.4 4 12.5C4 13.6 4.9 14.5 6 14.5C7.1 14.5 8 13.6 8 12.5C8 11.4 7.1 10.5 6 10.5ZM18 10.5C16.9 10.5 16 11.4 16 12.5C16 13.6 16.9 14.5 18 14.5C19.1 14.5 20 13.6 20 12.5C20 11.4 19.1 10.5 18 10.5ZM12 10.5C10.9 10.5 10 11.4 10 12.5C10 13.6 10.9 14.5 12 14.5C13.1 14.5 14 13.6 14 12.5C14 11.4 13.1 10.5 12 10.5Z' fill='white'/%3E%3C/svg%3E%0A");
}
.index-qa_info_menu__2KrWg .index-qa_info_menu_menu__2A8xB {
  position: absolute;
  right: 0;
  top: 100%;
  background: #fff;
  border: 1px solid #e6eaef;
  -webkit-filter: drop-shadow(0px 4px 5px rgba(0,0,0,0.05));
          filter: drop-shadow(0px 4px 5px rgba(0,0,0,0.05));
  border-radius: 4px;
  min-width: 111px;
  z-index: 1;
}
.index-qa_info_menu__2KrWg .index-qa_info_menu_menu__2A8xB .index-qa_info_menu_item__31XIG {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0 12px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  padding: 8px 12px;
  font-style: normal;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  color: #000;
  white-space: nowrap;
}
.index-qa_info_menu__2KrWg .index-qa_info_menu_menu__2A8xB .index-qa_info_menu_item__31XIG:hover {
  background-color: #f8f8f8;
}
.index-qa_info_menu__2KrWg .index-qa_info_menu_menu__2A8xB .index-qa_info_menu_item__icon__lDekr {
  width: 20px;
  height: 20px;
  display: block;
  background-repeat: no-repeat;
}
.index-qa_info_menu__2KrWg .index-qa_info_menu_menu__2A8xB .index-qa_info_menu_item__icon--uncollapseAll__SYw7Y {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 4.85833L12.6417 7.5L13.8167 6.325L10 2.5L6.17505 6.325L7.35838 7.5L10 4.85833ZM10 15.1417L7.35838 12.5L6.18338 13.675L10 17.5L13.825 13.675L12.6417 12.5L10 15.1417Z' fill='%23595A6B'/%3E%3C/svg%3E%0A");
}
.index-qa_info_menu__2KrWg .index-qa_info_menu_menu__2A8xB .index-qa_info_menu_item__icon--collapseAll__3XCRU {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.17505 15.4917L7.35838 16.6667L10 14.025L12.6417 16.6667L13.8167 15.4917L10 11.6667L6.17505 15.4917ZM13.825 4.50837L12.6417 3.33337L10 5.97504L7.35838 3.33337L6.17505 4.50837L10 8.33337L13.825 4.50837Z' fill='%23595A6B'/%3E%3C/svg%3E%0A");
}
.index-qa_info_menu__2KrWg .index-qa_info_menu_menu__2A8xB .index-qa_info_menu_item__icon--newTab__-AcIN {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.8333 15.8333H4.16667V4.16667H10V2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333V15.8333ZM11.6667 2.5V4.16667H14.6583L6.46667 12.3583L7.64167 13.5333L15.8333 5.34167V8.33333H17.5V2.5H11.6667Z' fill='%23595A6B'/%3E%3C/svg%3E%0A");
}
.index-qa_info_menu__2KrWg .index-qa_info_menu_menu__2A8xB .index-qa_info_menu_item__icon--openModal__2R2vJ {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.8333 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.7583 2.5 15.8333 2.5ZM15.8333 15.8333H4.16667V5.83333H15.8333V15.8333ZM10 8.75C11.5333 8.75 12.9 9.55 13.6167 10.8333C12.9 12.1167 11.5333 12.9167 10 12.9167C8.46667 12.9167 7.1 12.1167 6.38333 10.8333C7.1 9.55 8.46667 8.75 10 8.75ZM10 7.5C7.725 7.5 5.78333 8.88333 5 10.8333C5.78333 12.7833 7.725 14.1667 10 14.1667C12.275 14.1667 14.2167 12.7833 15 10.8333C14.2167 8.88333 12.275 7.5 10 7.5ZM10 12.0833C9.30833 12.0833 8.75 11.525 8.75 10.8333C8.75 10.1417 9.30833 9.58333 10 9.58333C10.6917 9.58333 11.25 10.1417 11.25 10.8333C11.25 11.525 10.6917 12.0833 10 12.0833Z' fill='%23595A6B'/%3E%3C/svg%3E%0A");
}
