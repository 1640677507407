.ranking-statistic-statistic__3WZSe {
  display: inline-block;
  padding: 8px 0;
  margin: 0;
  text-align: center;
  border: solid 1px #e6eaef;
  width: 100%;
}
.ranking-statistic-statistic__item__3Rx1E {
  display: inline-block;
  width: calc(33% - 20px);
  font-family: Hind;
  font-style: normal;
  font-stretch: normal;
}
.ranking-statistic-statistic__item-data__3yW8T {
  font-size: 18px;
  font-weight: bold;
  color: #6c6c7c;
  margin-bottom: 5px;
}
.ranking-statistic-statistic__item-label__db--1 {
  font-size: 12px;
  font-weight: normal;
  color: #919aa2;
  margin-bottom: 3px;
}
.ranking-statistic-statistic__slash__31iU9 {
  display: inline-block;
  width: 28px;
  height: 1px;
  margin: 14px 0 13px 0;
  background-color: #e7e7e7;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
