.scheduled-messages-container__2c3Fc {
  outline: none;
  position: absolute;
  left: 50px;
  right: 10px;
  bottom: 100%;
  width: calc(100% - 65px);
}
.scheduled-messages-icon__3gRPG {
  vertical-align: sub;
}
.scheduled-messages-icon__3gRPG svg * {
  stroke: #47475b;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
.scheduled-messages-header__se5Qq {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  width: calc(100% - 55px);
}
.scheduled-messages-header_container__3bgWy {
  position: relative;
}
.scheduled-messages-header_item__NPRvd {
  display: inline-block;
  vertical-align: bottom;
  padding: 7px 16px 0 16px;
  margin-right: 10px;
  border-radius: 5px 5px 0 0;
  background-color: #edf6ff;
  font-size: 13px;
  color: #47475b;
  cursor: pointer;
  -webkit-transition: padding-bottom 0.2s linear 0s;
  transition: padding-bottom 0.2s linear 0s;
}
.scheduled-messages-header_item__NPRvd:hover {
  padding-bottom: 3px;
}
.scheduled-messages-header_item_active__3_0wc {
  background-color: #54a8ff;
  color: #fff;
}
.scheduled-messages-header_item_active__3_0wc svg * {
  stroke: #fff;
}
.scheduled-messages-header_item_title__3qVlf {
  vertical-align: super;
}
.scheduled-messages-message__3ETIS {
  padding: 0;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0;
  border-radius: 0 4px 0 0;
  background-color: #f8fafc;
  border: solid 1px #e6eaef;
  clear: both;
  font-size: 15px;
  line-height: 1.4;
  color: #47475b;
  height: 0;
  overflow: auto;
  -webkit-transition: height 0.4s, padding 0.4s;
  transition: height 0.4s, padding 0.4s;
  word-break: break-all;
}
.scheduled-messages-message_opened__V_e9F {
  height: 150px;
  padding: 15px;
}
.scheduled-messages-hide__2Csps {
  position: absolute;
  right: 0;
  bottom: 8;
  font-size: 15px;
  font-weight: bold;
  color: #53a8ff;
  cursor: pointer;
  padding: 3px 4px 1px;
  border-radius: 20px;
  background-color: #fff;
}
.scheduled-messages-hide_text__t635Y {
  margin-left: 5px;
  vertical-align: top;
}
