.customer-list-container__1Ei5r {
  top: 95px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 388px;
}
