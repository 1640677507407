.channels-header__3lw8b {
  position: relative;
  height: 57px;
  background-color: #e6eaef;
  overflow: visible;
  z-index: 1;
}
.channels-title__3MgXp {
  font-size: 20px;
  color: #fff;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  padding-left: 20px;
}
.channels-buttons__Jdh1G {
  padding: 14px 10px 0;
}
