.customerio-events-component-pause_all_button__1o1ot {
  border: 1px solid #f00;
  background: #fff;
  position: absolute;
  top: 7px;
  right: 20px;
  padding: 10px;
  height: 36px;
  border-radius: 3px;
  color: #333;
  font-size: 15px;
}
