.info-modal-container__2pdw9 {
  display: inline-block;
  width: 350px;
  border-radius: 3px;
  background-color: #fff;
  text-align: center;
  padding: 25px 45px;
}
.info-modal-text__1u4ec {
  font-family: Hind;
  font-size: 16px;
  text-align: center;
  color: #47475b;
  margin: 15px 0 20px;
}
.info-modal-button__1Xi7W {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.info-modal-button__1Xi7W + .info-modal-button__1Xi7W {
  margin-left: 10px;
}
