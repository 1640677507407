.loader-container__3Wfx5 {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  bottom: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.loader-loader__3NI94 {
  width: 32px;
  height: 32px;
}
.loader-load_more_container__3B0uY {
  padding-top: 17px;
  padding-bottom: 17px;
  text-align: center;
}
.loader-load_more__3XL7V {
  display: block;
  margin: 6px 6px 6px 6px;
  border: 1px solid #dee3ea;
  color: #003e6d;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  background: #fff;
  text-transform: uppercase;
}
