.sla-tracking-table-container__3IbCq {
  color: #47475b;
  font-size: 13px;
  margin-top: 15px;
}
.sla-tracking-table-headers__1Dudc {
  display: inline-block;
  width: 200px;
  margin-right: 10px;
}
.sla-tracking-table-header_item__11cas {
  margin-bottom: 10px;
}
.sla-tracking-table-header_item_title__3O2qy,
.sla-tracking-table-header_item_type__8P70U {
  border: 1px solid #e6eaef;
  padding: 5px;
  background-color: #fff;
  height: 25px;
}
.sla-tracking-table-header_item_title__3O2qy {
  font-weight: bold;
}
.sla-tracking-table-statistic__gOMk3 {
  display: inline-block;
  width: calc(100% - 210px);
  vertical-align: top;
  white-space: nowrap;
  overflow: auto;
}
.sla-tracking-table-statistic_item__2gcgv {
  display: inline-block;
  width: 80px;
}
.sla-tracking-table-statistic_item_group__1dKYh {
  margin-bottom: 10px;
}
.sla-tracking-table-statistic_item_field__3OtHk {
  text-align: center;
  height: 25px;
  background-color: #fff;
  border: 1px solid #e6eaef;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sla-tracking-table-hover_row__1coIQ {
  background-color: #e8f3f1;
}
