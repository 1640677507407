.conversations-list-button-base__29YRz {
  border: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 2px;
}
.conversations-list-button-base__29YRz:focus {
  outline: none;
}
.conversations-list-button-default__3J5mB {
  padding: 0 14px;
  height: 32px;
  line-height: 33px;
}
.conversations-list-button-default__3J5mB.conversations-list-only_icon__myJm_ {
  font-size: 18px;
  width: 36px;
}
.conversations-list-button-default__3J5mB .conversations-list-badge__3gAVq {
  height: 16px;
  line-height: 16px;
}
.conversations-list-button-small__1vIFx,
.conversations-list-try_again__1JD43 {
  padding: 0 13px;
  text-transform: uppercase;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.conversations-list-button-small__1vIFx.conversations-list-only_icon__myJm_,
.conversations-list-try_again__1JD43.conversations-list-only_icon__myJm_ {
  padding-top: 9px;
  font-size: 13px;
  width: 28px;
}
.conversations-list-button-extra-small__1KVph {
  padding: 0 6px;
  text-transform: uppercase;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
}
.conversations-list-button-primary__1LJXu {
  background-color: #188fea;
  color: #fff;
}
.conversations-list-button-primary__1LJXu:hover {
  background-color: #0a71bf;
}
.conversations-list-button-light__2NRpE {
  background-color: #fff;
  color: #0a71bf;
}
.conversations-list-button-transparent__2Px0p {
  background-color: none;
  color: #fff;
  border: 1px solid #fff;
}
.conversations-list-button-transparent__2Px0p:hover {
  background-color: #fff;
  color: #0a71bf;
}
.conversations-list-button-danger__1Jbfb,
.conversations-list-try_again__1JD43 {
  background-color: #d85151;
  color: #fff;
}
.conversations-list-button-danger__1Jbfb:hover,
.conversations-list-try_again__1JD43:hover {
  background-color: #c13030;
}
.conversations-list-button-full__2YVbE {
  width: 100%;
  text-align: center;
}
.conversations-list-button-secondary__2ONCt {
  background-color: #aaa;
  color: #fff;
  font-weight: bold;
}
.conversations-list-button-secondary__2ONCt:hover {
  background-color: #0a71bf;
}
.conversations-list-button-tertiary__3QDtY {
  color: #aaa;
  font-weight: bold;
}
.conversations-list-button-tertiary__3QDtY:hover {
  color: #0a71bf;
}
.conversations-list-button-dark__2yTpQ {
  background-color: #0a71bf;
  color: #fff;
}
.conversations-list-button-dark__2yTpQ:hover {
  background-color: #005fa7;
}
.conversations-list-button-only-icon__2HYyO {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.conversations-list-button-icon-big__1s2sH {
  font-size: 18px;
  width: 38px;
}
.conversations-list-button-icon__3na3U {
  margin-right: 5px;
}
.conversations-list-button-bold__2Yqyj {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.conversations-list-button-float-right__1s2xc {
  float: right;
}
.conversations-list-button-with-hr__IHLsc {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 0;
}
.conversations-list-button-with-hr__IHLsc:after {
  display: block;
  position: relative;
  bottom: 15px;
  content: '';
  border-bottom: 1px solid #c1cdd6;
  z-index: -1;
}
.conversations-list-button-processing__1Z17r {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
}
.conversations-list-button-invisible__3VIvz {
  visibility: hidden;
}
.conversations-list-button-tooltip__2EqpR {
  top: 38px;
  left: 50%;
  margin-left: -75px;
  color: #fff;
  width: 150px;
  font-size: 13px;
  text-align: center;
  text-transform: initial;
  position: absolute;
  padding: 5px;
  z-index: 2;
  background-color: #3e434d;
  height: 26px;
  line-height: 16px;
  opacity: 0.8;
  border-radius: 2px;
}
.conversations-list-button-tooltip__2EqpR:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-bottom-color: #3e434d;
  border-width: 5px;
  margin-left: -5px;
}
.conversations-list-button-badge__164WV {
  padding: 0px 7px;
  min-width: 10px;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #f86e5f;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 40px;
}
.conversations-list-error-default__2w4Q0,
.conversations-list-error__6ScV0 {
  display: block;
  margin: 6px 6px 6px 6px;
  border: 2px dashed #d85151;
  padding: 20px;
  text-decoration: none;
  position: relative;
  background-color: #fff8f8;
  color: #d85151;
}
.conversations-list-scroll__1QtY8 {
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.conversations-list-error__6ScV0 {
  text-align: center;
}
.conversations-list-no_results__2mTAK {
  padding: 20px;
}
.conversations-list-take_new__1Lvaq {
  padding: 10px 0;
  text-align: center;
  border-bottom: 2px solid #e6eaef;
}
