.common-styles-container__2LdN- {
  padding: 20px;
  overflow: auto;
  height: calc(100% - 67px);
}
.common-styles-error__ChYxf {
  color: #f00;
  text-align: center;
  display: block;
  padding: 5px 0;
  font-size: 16px;
}
