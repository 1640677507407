.styles-modal__3Csv1 {
  background-color: #fff7e0;
  width: 200px;
  bottom: 943px;
  padding: 17px;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  bottom: 10px;
  right: 10px;
  position: fixed;
  z-index: 1;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  text-align: left;
}
.styles-title__1p0hv {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 10px;
}
.styles-text__juZab {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}
.styles-close__1qon9 {
  width: 14px;
  height: 14px;
  position: absolute;
  display: inline-block;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzEwMV8yMjA3KSI+CjxwYXRoIGQ9Ik0xNS44MzM0IDUuMzQxNjNMMTQuNjU4NCA0LjE2NjYzTDEwIDguODI0OTZMNS4zNDE2OSA0LjE2NjYzTDQuMTY2NjkgNS4zNDE2M0w4LjgyNTAyIDkuOTk5OTZMNC4xNjY2OSAxNC42NTgzTDUuMzQxNjkgMTUuODMzM0wxMCAxMS4xNzVMMTQuNjU4NCAxNS44MzMzTDE1LjgzMzQgMTQuNjU4M0wxMS4xNzUgOS45OTk5NkwxNS44MzM0IDUuMzQxNjNaIiBmaWxsPSIjMkMyRDQwIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMTAxXzIyMDciPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==");
  background-position: center;
  right: 20px;
  top: 20px;
  cursor: pointer;
  opacity: 0.4;
}
.styles-close__1qon9:hover {
  opacity: 0.6;
}
