.modal-overlay__2VU7p {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0,0,0,0.55);
  overflow: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 10;
}
.modal-modal__3nVDR {
  width: 618px;
  border-radius: 3px;
  background-color: #fff;
  margin: auto;
  z-index: 1;
  position: relative;
  overflow: initial;
}
