.settings-container__3DlP1 {
  font-family: 'Hind', sans-serif;
  color: #47475b;
  padding: 20px;
  overflow: auto;
  height: 100%;
}
.settings-title__2TUSz {
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}
.settings-input__1qE80 {
  width: 100%;
  padding: 8px 6px 7px;
  border-radius: 3px;
  border: 1px solid #ccc;
  margin-right: 15px;
}
.settings-input__1qE80.settings-error__2-AKE {
  border: 1px solid #f00;
}
.settings-input_ip__1T3fi {
  width: 80%;
  padding: 8px 6px 7px;
  border-radius: 3px;
  border: 1px solid #ccc;
  margin-right: 15px;
}
.settings-input_container_wrap__2PCuS {
  display: block;
}
.settings-input_container__2KvuO {
  margin: 0 20px 20px 0;
  width: 500px;
  display: inline-block;
}
.settings-checkbox__1R1DA {
  vertical-align: middle;
  margin-left: 3px;
}
.settings-error__2-AKE {
  color: #f87b6e;
}
.settings-chat_body__10Rkt {
  border: 1px solid #e6eaef;
}
.settings-chat_body__10Rkt .settings-chat_row__2ksld {
  border-bottom: 1px solid #e6eaef;
  padding: 13px 10px 10px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.settings-chat_body__10Rkt .settings-chat_row__2ksld.settings-bg_row__3F2lc {
  background-color: #f2f5f8;
  font-weight: bold;
}
.settings-chat_body__10Rkt .settings-chat_row__2ksld.settings-last-child__1NSka {
  border-bottom: none;
}
.settings-chat_body__10Rkt .settings-switch__PX7zs {
  background-color: #aab6c8;
}
.settings-chat_body__10Rkt .settings-switch__PX7zs.settings-active__1zVFU {
  background-color: #1e8af6;
}
