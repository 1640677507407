.accordion-title__3nqlt {
  font-size: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  line-height: 24px;
  margin: 15px 0 0;
  cursor: pointer;
  display: inline-block;
}
.accordion-title_text__3qecc {
  color: #53a8ff;
}
.accordion-total__jXV5e {
  color: #6c6c7c;
}
.accordion-switch_icon__1MzPT {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='11' viewBox='0 0 6 11'%3E %3Cpath fill='%2353A8FF' fill-rule='evenodd' d='M.5 10.5V.5l5 5z'/%3E %3C/svg%3E");
  -webkit-transition: 0.2sec;
  transition: 0.2sec;
}
.accordion-switch_icon_open__g8Uxp {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.accordion-scheduled__2TzK_ {
  height: 0;
  overflow: hidden;
}
.accordion-scheduled_open__1bYg7 {
  height: auto;
  overflow: visible;
  position: relative;
}
