.reply-templates-page-overlay__3vwAd {
  position: absolute;
  background-color: #f8fafc;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}
.reply-templates-page-container__aok2U {
  margin: 20px;
  border: 1px solid #a9b5c9;
  border-radius: 5px;
  background-color: #fff;
  padding: 15px;
  height: calc(100% - 40px);
  min-width: 1225px;
}
.reply-templates-page-title__3kWSg {
  margin: 0;
  margin-bottom: 15px;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.3px;
  color: #47475b;
}
.reply-templates-page-tool__2a2xR {
  display: inline-block;
  border: 1px solid #a9b5c9;
  border-radius: 4px;
  overflow: hidden;
  height: calc(100% - 42px);
}
.reply-templates-page-link__1CrX3 {
  float: right;
  text-decoration: none;
  margin-right: 15px;
  margin-bottom: 15px;
  display: inline-block;
}
.reply-templates-page-button__3Zs0K {
  display: inline-block;
  border-radius: 2px;
  background-color: #fff;
  border: solid 1px #56aafc;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #53a8ff;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  vertical-align: top;
  padding: 8px 8px 7px;
}
.reply-templates-page-button__3Zs0K:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
.reply-templates-page-button__3Zs0K:focus,
.reply-templates-page-button__3Zs0K:active {
  outline: none;
}
