.tag-container__2OCax {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 7px 0;
}
.tag-container__2OCax .tag-label__yt05t {
  margin: 0 10px;
}
.tag-container__2OCax.tag-disabled__1qmyg {
  cursor: auto;
}
.tag-container__2OCax.tag-disabled__1qmyg .tag-label__yt05t {
  opacity: 0.5;
}
.tag-tag_target_loading__3MFNV {
  width: 20px;
  height: 20px;
}
