.answers-section-container__alZF- {
  display: inline-block;
  height: 100%;
}
.answers-section-answers__33b9y {
  width: 330px;
  height: 100%;
  border-left: 1px solid #a9b5c9;
}
.answers-section-order__1DU-k {
  float: right;
  margin-right: 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}
.answers-section-order__1DU-k span {
  color: #53a8ff;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.answers-section-order__1DU-k i {
  border-color: #53a8ff transparent transparent transparent;
}
.answers-section-order_options__JAfl8 {
  right: 10;
}
.answers-section-pagination__14EU2 {
  text-align: center;
}
.answers-section-search__3Nx5X {
  margin: 0;
  padding: 0;
  border: 1px solid #e2e6ed;
  float: left;
  margin-bottom: 10px;
}
