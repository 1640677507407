.agent-statistic-tab__2a9Ot {
  display: inline-block;
  border: 1px solid #56aafc;
  padding: 10px 0;
  cursor: pointer;
  text-decoration: none;
  border-radius: 3px 3px 0 0;
  margin-right: -1px;
  text-align: center;
  margin-bottom: -1px;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #47475b;
  width: 122.5px;
  height: 36px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f0f8ff));
  background-image: linear-gradient(to bottom, #fff, #f0f8ff);
}
.agent-statistic-tab__2a9Ot:hover {
  background-color: #56aafc;
  background-image: none;
  color: #fff;
}
.agent-statistic-tab_active__1-jrp {
  background-color: #56aafc;
  background-image: none;
  color: #fff;
}
.agent-statistic-tabs__3TM-T {
  min-width: 900px;
}
.agent-statistic-tabs__container__2Lpy8 {
  width: 100%;
  min-width: 550px;
}
.agent-statistic-tabs__devider__3yrBV {
  width: 100%;
  height: 1px;
  background-color: #56aafc;
  margin-bottom: 10px;
}
.agent-statistic-tab__content__3fBMb {
  display: inline-block;
  overflow: auto;
  width: 100%;
  min-width: 900px;
  min-height: 40%;
}
.agent-statistic-export__17puL {
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #53a8ff;
  display: inline-block;
  cursor: pointer;
  float: right;
  padding-top: 10px;
  padding-left: 25px;
  text-decoration: none;
  background: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='17px' height='16px' viewBox='0 0 17 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3E1D12285D-CABD-4EF3-AC24-36A8C1C21C25%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E %3Cg id='Operator_edit_email' transform='translate(-1169.000000, -299.000000)' stroke='%2356AAFC' stroke-width='0.75'%3E %3Cg id='Page-1' transform='translate(1170.000000, 300.000000)'%3E %3Cpolyline id='Stroke-1' points='10.9281094 6.19338667 10.9281094 3.80678667 7.20154688 0.0967866667 0.234515625 0.0967866667 0.234515625 13.7234533 10.9281094 13.7234533 10.9281094 11.4791867'%3E%3C/polyline%3E %3Cpolygon id='Stroke-3' points='7.20159375 3.80664667 7.20159375 0.0966466667 10.9281563 3.80664667'%3E%3C/polygon%3E %3Cpath d='M7.89473684,8.55555556 L15,8.55555556' id='Stroke-5'%3E%3C/path%3E %3Cpolyline id='Stroke-7' points='13.4210526 7 15 8.6081452 13.5239745 10.1111111'%3E%3C/polyline%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E") 0 8px no-repeat;
}
