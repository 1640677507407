.ranking-conversations-container__HgefN {
  min-width: 388px;
}
.ranking-conversations-conversations__2SRcd {
  position: relative;
  border-style: solid;
  border-width: 0 1px 0 1px;
  border-color: #e6eaef;
  height: calc(100% - 149px);
  overflow: auto;
}
.ranking-conversations-conversations__list__60lXp {
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 100%;
}
.ranking-conversations-conversations__link__2rqlC {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #e6eaef;
  padding-right: 5px;
  background-color: #fff;
}
.ranking-conversations-conversations__link_active__9410A {
  background-color: #53a8ff;
}
.ranking-conversations-conversations__pagination__2RweM {
  text-align: center;
}
