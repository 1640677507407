.customer-card-credentials-form-button-base__3hvDR {
  border: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 2px;
}
.customer-card-credentials-form-button-base__3hvDR:focus {
  outline: none;
}
.customer-card-credentials-form-button-default__2IpvM,
.customer-card-credentials-form-button_save__3xVof {
  padding: 0 14px;
  height: 32px;
  line-height: 33px;
}
.customer-card-credentials-form-button-default__2IpvM.customer-card-credentials-form-only_icon__12-ci,
.customer-card-credentials-form-button_save__3xVof.customer-card-credentials-form-only_icon__12-ci {
  font-size: 18px;
  width: 36px;
}
.customer-card-credentials-form-button-default__2IpvM .customer-card-credentials-form-badge__2Vkis,
.customer-card-credentials-form-button_save__3xVof .customer-card-credentials-form-badge__2Vkis {
  height: 16px;
  line-height: 16px;
}
.customer-card-credentials-form-button-small__3DwGk,
.customer-card-credentials-form-button_save__3xVof {
  padding: 0 13px;
  text-transform: uppercase;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.customer-card-credentials-form-button-small__3DwGk.customer-card-credentials-form-only_icon__12-ci,
.customer-card-credentials-form-button_save__3xVof.customer-card-credentials-form-only_icon__12-ci {
  padding-top: 9px;
  font-size: 13px;
  width: 28px;
}
.customer-card-credentials-form-button-extra-small__2QNXe {
  padding: 0 6px;
  text-transform: uppercase;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
}
.customer-card-credentials-form-button-primary__V0b-F,
.customer-card-credentials-form-button_save__3xVof {
  background-color: #188fea;
  color: #fff;
}
.customer-card-credentials-form-button-primary__V0b-F:hover,
.customer-card-credentials-form-button_save__3xVof:hover {
  background-color: #0a71bf;
}
.customer-card-credentials-form-button-light__1JA3X {
  background-color: #fff;
  color: #0a71bf;
}
.customer-card-credentials-form-button-transparent__2_OGk {
  background-color: none;
  color: #fff;
  border: 1px solid #fff;
}
.customer-card-credentials-form-button-transparent__2_OGk:hover {
  background-color: #fff;
  color: #0a71bf;
}
.customer-card-credentials-form-button-danger__pJdjc {
  background-color: #d85151;
  color: #fff;
}
.customer-card-credentials-form-button-danger__pJdjc:hover {
  background-color: #c13030;
}
.customer-card-credentials-form-button-full__1AZpC {
  width: 100%;
  text-align: center;
}
.customer-card-credentials-form-button-secondary__1iTPX {
  background-color: #aaa;
  color: #fff;
  font-weight: bold;
}
.customer-card-credentials-form-button-secondary__1iTPX:hover {
  background-color: #0a71bf;
}
.customer-card-credentials-form-button-tertiary__1MvOT {
  color: #aaa;
  font-weight: bold;
}
.customer-card-credentials-form-button-tertiary__1MvOT:hover {
  color: #0a71bf;
}
.customer-card-credentials-form-button-dark__mJ-k9 {
  background-color: #0a71bf;
  color: #fff;
}
.customer-card-credentials-form-button-dark__mJ-k9:hover {
  background-color: #005fa7;
}
.customer-card-credentials-form-button-only-icon__3CWyz {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.customer-card-credentials-form-button-icon-big__3Dk-2 {
  font-size: 18px;
  width: 38px;
}
.customer-card-credentials-form-button-icon__x2BLi {
  margin-right: 5px;
}
.customer-card-credentials-form-button-bold__2ikCC {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}
.customer-card-credentials-form-button-float-right__1kl30 {
  float: right;
}
.customer-card-credentials-form-button-with-hr__2y9lJ {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 0;
}
.customer-card-credentials-form-button-with-hr__2y9lJ:after {
  display: block;
  position: relative;
  bottom: 15px;
  content: '';
  border-bottom: 1px solid #c1cdd6;
  z-index: -1;
}
.customer-card-credentials-form-button-processing__33jo3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
}
.customer-card-credentials-form-button-invisible__3tAhM {
  visibility: hidden;
}
.customer-card-credentials-form-button-tooltip__2kjYJ {
  top: 38px;
  left: 50%;
  margin-left: -75px;
  color: #fff;
  width: 150px;
  font-size: 13px;
  text-align: center;
  text-transform: initial;
  position: absolute;
  padding: 5px;
  z-index: 2;
  background-color: #3e434d;
  height: 26px;
  line-height: 16px;
  opacity: 0.8;
  border-radius: 2px;
}
.customer-card-credentials-form-button-tooltip__2kjYJ:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  position: absolute;
  pointer-events: none;
  border-bottom-color: #3e434d;
  border-width: 5px;
  margin-left: -5px;
}
.customer-card-credentials-form-button-badge__MjsJX {
  padding: 0px 7px;
  min-width: 10px;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #f86e5f;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 40px;
}
.customer-card-credentials-form-button_new_container__3zXoE {
  position: absolute;
  text-align: right;
  top: 0;
  right: 0;
}
.customer-card-credentials-form-button_new__-n9Dc {
  width: 16px;
  height: 16px;
  background: #e5f2ff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E %3Cg fill='none' fill-rule='evenodd'%3E %3Crect width='18' height='18' fill='%2353A8FF' rx='2'/%3E %3Cpath stroke='%23FFF' d='M9 4.5v8.1M4.925 8.575h8.15'/%3E %3C/g%3E %3C/svg%3E") no-repeat center;
  cursor: pointer;
}
.customer-card-credentials-form-button_save__3xVof {
  font-size: 8px;
  margin-right: 10px;
}
.customer-card-credentials-form-new_type_list___6QP7 {
  position: absolute;
  right: 0;
  top: 27px;
  background: #fff;
  z-index: 2;
  overflow: hidden;
  -webkit-box-shadow: 0 0 5px #e0e2e4;
          box-shadow: 0 0 5px #e0e2e4;
  border-radius: 2px;
  padding: 5px 7px;
}
.customer-card-credentials-form-new_type_list_item__1Efuy {
  padding: 3px 5px;
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
  color: #57697b;
}
.customer-card-credentials-form-new_type_list_item__1Efuy:hover {
  background: #e5f2ff;
  border-radius: 2px;
}
.customer-card-credentials-form-type_list_type_icon__1U1kx {
  margin-right: 10px;
  font-size: 14px;
  padding-top: 3px;
}
