.autocomplete-input__26d1j {
  display: block;
  width: 226px;
  padding: 7px 10px;
  color: #47475b;
  font-size: 14px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #e6eaef;
}
.autocomplete-suggestions__dczDU {
  font-size: 14px;
  color: #47475b;
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 226px;
}
.autocomplete-suggestions__dczDU li {
  padding: 0.5rem;
}
.autocomplete-suggestion_active__3ETbz,
.autocomplete-suggestions__dczDU li:hover {
  background-color: rgba(83,168,255,0.2);
  color: #000;
  cursor: pointer;
  font-weight: 400;
}
