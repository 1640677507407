.tab-options-container__3kM0Q {
  background-color: rgba(230,234,239,0.25);
  -webkit-box-shadow: inset 0 -1px 0 0 #e6eaef, inset 0 1px 0 0 #e6eaef;
          box-shadow: inset 0 -1px 0 0 #e6eaef, inset 0 1px 0 0 #e6eaef;
  padding: 12px 15px;
  font-family: Hind;
  font-size: 14px;
  text-align: left;
  color: #53a8ff;
}
.tab-options-all__iozRW {
  max-width: 50%;
  overflow: hidden;
  cursor: pointer;
}
.tab-options-new__2QBr_ {
  float: right;
  max-width: 50%;
  cursor: pointer;
}
.tab-options-icon__3IOgi {
  margin-right: 8px;
  vertical-align: text-bottom;
}
.tab-options-dropdown__1JBZI {
  width: 225px;
  border-radius: 4px;
  border: solid 1px #a9b5c9;
  background-color: #fff;
  margin-bottom: 10px;
  position: absolute;
  right: 10px;
  top: 38px;
  z-index: 1;
}
.tab-options-dropdown__1JBZI .tab-options-dropdown_item__1k30d {
  border-bottom: solid 1px rgba(169,181,201,0.5);
  padding: 10px 8px 10px 40px;
  position: relative;
}
.tab-options-dropdown__1JBZI .tab-options-dropdown_item__1k30d img {
  position: absolute;
  left: 10px;
  width: 16px;
  height: 16px;
}
.tab-options-dropdown__1JBZI .tab-options-dropdown_item__1k30d:last-child {
  border-bottom: none;
}
