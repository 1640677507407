.inline-textarea-input-unclickable-container__2iGIY {
  width: 100%;
  color: #57697b;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.inline-textarea-input-unclickable-input__5YBqt {
  width: 100%;
  height: 200px;
  border: none;
  background: #fff0be;
}
