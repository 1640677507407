.agent-list-container__L-059 {
  min-width: 970px;
  background-color: #fff;
  height: 100%;
}
.agent-list-agents__2T2GN {
  padding: 0 17px 17px;
  background-color: #fff;
  height: calc(100% - 100px);
}
.agent-list-agents__container__3fuh2 {
  width: 1200px;
}
.agent-list-agents__head__pJZRj {
  font-family: Hind;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  color: #919aa2;
  background-color: #fff;
  margin-left: 17px;
}
.agent-list-agents__head__pJZRj thead {
  overflow: auto;
}
.agent-list-agents__head__pJZRj thead tr:first-child th {
  position: sticky;
  top: 0;
}
.agent-list-agents__head-item__s4C_R {
  padding: 10px 5px;
  width: 75px;
  color: #000;
  border-right: 1px solid #e6eaef;
}
.agent-list-agents__head_type_1__1_j37 {
  background-color: #acc7ed;
}
.agent-list-agents__head_type_2__1OsTq {
  background-color: #db3b26;
  color: #fff;
}
.agent-list-agents__head_type_3__uaJaP {
  background-color: #c9c9c9;
}
.agent-list-agents__head_type_4__A7cUi {
  background-color: #caf7ad;
}
.agent-list-agents__head_type_5__1aop_ {
  background-color: #45a2f8;
}
.agent-list-agents__head_type_6__FylOu {
  background-color: #f6e159;
}
.agent-list-agents__head_type_7__2ANdP {
  background-color: #f3ad9c;
}
.agent-list-agents__head_type_8__34lwv {
  background-color: #6fcf97;
}
.agent-list-agents__head_center__1ohl7 {
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.agent-list-agents__head_min__1Vkbf {
  width: 90px;
}
.agent-list-agents__head_name__5eG2d {
  width: 250px;
}
.agent-list-agents__head_role__2FlV_ {
  width: 75px;
}
.agent-list-agents__head_counter__bQltx {
  width: 75px;
}
.agent-list-agents__head_location__lojyG {
  width: 200px;
}
.agent-list-agents__head_status__3x0ve {
  width: 80px;
}
.agent-list-agents__head_rc__3lpfT {
  width: 100px;
}
.agent-list-agents__pages__3veBp {
  width: 100%;
  text-align: center;
}
.agent-list-btn_update__1pr5Z {
  background-color: #188fea;
  color: #fff;
  border: none;
  padding: 0 14px;
  height: 32px;
  line-height: 33px;
  border-radius: 2px;
  margin-left: 10px;
}
