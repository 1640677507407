.styles-tabs__3rJTr {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.styles-tabs__item__19Zjg {
  width: 50%;
  padding: 8px 0;
  font-size: 14px;
  line-height: 22px;
  color: #6c6d7c;
  text-align: center;
  border-bottom: 2px solid #e5e5e5;
  cursor: pointer;
}
.styles-tabs__item--selected__1xkiy {
  font-weight: 600;
  color: #000;
  border-bottom-color: #58aafc;
}
