.csv-export-container__sba8- {
  padding: 30px;
}
.csv-export-link__1lI64 {
  text-decoration: none;
  margin-right: 15px;
  margin-bottom: 15px;
  display: inline-block;
}
.csv-export-button__1vJMy {
  display: inline-block;
  border-radius: 2px;
  background-color: #fff;
  border: solid 1px #56aafc;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #53a8ff;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  vertical-align: top;
  padding: 8px 8px 7px;
}
.csv-export-button__1vJMy:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
.csv-export-button__1vJMy:focus,
.csv-export-button__1vJMy:active {
  outline: none;
}
.csv-export-message__2VRFY {
  width: 100%;
  font-family: Hind;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  line-height: 30px;
  color: #dde5ef;
}
.csv-export-error__16zE4 {
  color: #f87b6e;
}
