.assigner-preview-container__3AbFr {
  display: block;
  background-color: #fff;
  padding: 11px 10px 14px 20px;
  color: #57697b;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  font-family: Hind;
  font-size: 15px;
  font-weight: 500;
  border-width: 0 0 1px 0;
  border-color: #e7ebf0;
  border-style: solid;
}
.assigner-preview-container__3AbFr:hover {
  border-color: a9b5c9;
  border-width: 1px 0 1px 0;
  margin-top: -1px;
}
.assigner-preview-container__3AbFr:hover .assigner-preview-label__-tF1a {
  color: #2c2c40;
}
.assigner-preview-container__3AbFr:hover .assigner-preview-stats__2Wt5K {
  opacity: 1;
}
.assigner-preview-highlighted__zEDjQ {
  background-color: #fff7f1;
}
.assigner-preview-selected__236it {
  background-color: #eef7ff;
}
.assigner-preview-label__-tF1a {
  display: inline-block;
  vertical-align: top;
}
.assigner-preview-value__1mcJA {
  display: inline-block;
  word-break: break-all;
  margin-right: 10px;
  margin-bottom: 5px;
  max-width: 145px;
}
.assigner-preview-avatar_container___hzGC {
  display: inline-block;
  position: relative;
  margin-right: 13px;
}
.assigner-preview-avatar__2SbzS {
  height: 35px;
  width: 35px;
  border-radius: 50px;
  background-color: #dde5ef;
}
.assigner-preview-indicator__3UlHf {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  border: solid #fff 2px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.assigner-preview-indicator_online__UO1B0 {
  background: #0c0;
  border-radius: 5px;
  border: solid #fff 2px;
}
.assigner-preview-indicator_dormant__RsgC1 {
  background: #cc0;
  border-radius: 5px;
  border: solid #fff 2px;
}
.assigner-preview-indicator_offline__3vY3b {
  background: #aaa;
  border-radius: 5px;
  border: solid #fff 2px;
}
.assigner-preview-newbie_sign__nLzZc {
  display: inline-block;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 0.6px;
  color: #fff;
  border-radius: 2px;
  background-color: #919aa2;
  text-transform: uppercase;
  padding: 3px 3px 2px;
  vertical-align: top;
}
.assigner-preview-recomended_sign__1tWGl {
  display: inline-block;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 0.6px;
  color: #fff;
  border-radius: 2px;
  background-color: #ff7200;
  text-transform: uppercase;
  padding: 3px 3px 2px;
  vertical-align: top;
  margin-right: 7px;
}
.assigner-preview-stats__2Wt5K {
  font-size: 13px;
  font-weight: 500;
  color: #6c6c7c;
  opacity: 0.7;
}
.assigner-preview-stat__1HfuS {
  display: inline-block;
  width: 40px;
  margin-right: 2px;
}
.assigner-preview-stat__1HfuS:last-child {
  width: auto;
}
.assigner-preview-stat_icon__3e_vl {
  margin-right: 3px;
}
.assigner-preview-highlight_line__3AifG,
.assigner-preview-selected_line__2lHDZ {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
}
.assigner-preview-highlight_line__3AifG {
  background-color: #ff7200;
}
.assigner-preview-selected_line__2lHDZ {
  background-color: #188fea;
}
