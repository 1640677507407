.info-block-container__3qJOM {
  margin-bottom: 2px;
}
.info-block-title_group__caviV {
  padding-top: 11px;
  padding-right: 15px;
}
.info-block-title_icon__3Y4Jd {
  display: inline-block;
  margin-left: 16px;
  margin-right: 12px;
  vertical-align: bottom;
}
.info-block-new_window__3noI- {
  margin-top: 2px;
  float: right;
}
.info-block-new_window__3noI-:hover svg * {
  stroke: #75b7fb;
}
.info-block-title_container__3CGtP {
  height: 40px;
  background-color: #edf6ff;
  font-family: Hind;
  font-size: 14px;
  text-align: left;
  color: #323d49;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.info-block-title_container_active__3eBWe {
  background-color: #6c6c7c;
  color: #fff;
}
.info-block-title_container_active__3eBWe .info-block-title_icon__3Y4Jd svg * {
  fill: #fff !important;
  opacity: 0.7;
}
.info-block-title_container_active__3eBWe .info-block-new_window__3noI- svg * {
  stroke: #fff;
}
.info-block-children_container__tCH_j {
  background-color: #fff;
  position: relative;
}
.info-block-text__3xtz4 {
  display: inline-block;
  padding-bottom: 1px;
  padding-right: 5px;
}
.info-block-text_additional__3L5XK {
  font-weight: normal;
}
.info-block-payments_balance__T1j-x {
  font-weight: bold;
}
