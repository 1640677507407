.agent-search-container__1j8-Q {
  position: relative;
  padding: 12px 26px 11px 17px;
  background-color: #fff;
  border-bottom: 1px solid #e6eaef;
  min-width: 900px;
}
.agent-search-textbox__1Et3f {
  display: inline-block;
  width: 400px;
  border: 1px solid #e6eaef;
  border-radius: 3px;
  margin-right: 10px;
}
.agent-search-textbox_input__3Gmsk {
  color: #47475b;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  height: 30px !important;
}
.agent-search-new-button__2AYdG {
  display: inline-block;
  float: right;
  right: 26px;
  top: 12px;
  width: 135px;
  height: 30px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #53a8ff;
  text-decoration: none;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: #53a8ff;
  padding-top: 8px;
}
.agent-search-new-button__2AYdG:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
.agent-search-select__1DY2u {
  display: inline-block;
  border: solid 1px #e6eaef;
  padding: 8px;
  margin-right: 10px;
  width: 117px;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #a9b5c9;
  border-radius: 3px;
}
.agent-search-select__options__3jTQ3 {
  width: 117px;
  margin-left: -9px;
  margin-top: 4px;
}
