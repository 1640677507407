.ranking-status-container__1KlHd {
  margin: 10px;
}
.ranking-status-quality-block__tab__3YkaP {
  width: 50%;
  padding: 8px 0 10px;
}
.ranking-status-quality-block__tab_icon__R99dN {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-position: center;
  margin: 0 6px -3px 0;
}
.ranking-status-quality-block__tab_positive__ViR52 {
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='17px' height='17px' viewBox='0 0 17 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3E42BE2D41-76A1-4E7D-91FB-E3B637E9FC8F%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='QA-dashboard_bad-mark' transform='translate(-1138.000000, -233.000000)'%3E %3Cg id='smile' transform='translate(1139.000000, 234.000000)'%3E %3Cpath d='M14.7321429,7.5 C14.7321429,11.4942857 11.4942857,14.7321429 7.5,14.7321429 C3.50571429,14.7321429 0.267857143,11.4942857 0.267857143,7.5 C0.267857143,3.50571429 3.50571429,0.267857143 7.5,0.267857143 C11.4942857,0.267857143 14.7321429,3.50571429 14.7321429,7.5 Z' id='Stroke-1' stroke='%2353A8FF'%3E%3C/path%3E %3Cpath d='M5.95558929,4.5975 C5.95558929,5.21785714 5.45255357,5.72089286 4.83219643,5.72089286 C4.21183929,5.72089286 3.70933929,5.21785714 3.70933929,4.5975 C3.70933929,3.97714286 4.21183929,3.47410714 4.83219643,3.47410714 C5.45255357,3.47410714 5.95558929,3.97714286 5.95558929,4.5975' id='Fill-3' fill='%2353A8FF'%3E%3C/path%3E %3Cpath d='M11.2907679,4.5975 C11.2907679,5.21785714 10.7877321,5.72089286 10.167375,5.72089286 C9.54701786,5.72089286 9.04451786,5.21785714 9.04451786,4.5975 C9.04451786,3.97714286 9.54701786,3.47410714 10.167375,3.47410714 C10.7877321,3.47410714 11.2907679,3.97714286 11.2907679,4.5975' id='Fill-5' fill='%2353A8FF'%3E%3C/path%3E %3Cpath d='M4.06692857,9.73205357 C4.97978571,10.2827679 6.18032143,10.6197321 7.49978571,10.6197321 C8.81978571,10.6197321 10.0203214,10.2827679 10.9331786,9.73205357' id='Stroke-7' stroke='%2353A8FF'%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
}
.ranking-status-quality-block__tab_negative__rFDv- {
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3E9F564A45-533B-4FD6-9838-D38840D241C9%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='QA-dashboard_bad-mark' transform='translate(-1258.000000, -233.000000)'%3E %3Cg id='Page-1' transform='translate(1259.000000, 234.000000)'%3E %3Cpath d='M14.2035184,7.23088208 C14.2035184,11.0818433 11.0818433,14.2035184 7.23088208,14.2035184 C3.37992088,14.2035184 0.258245789,11.0818433 0.258245789,7.23088208 C0.258245789,3.37992088 3.37992088,0.258245789 7.23088208,0.258245789 C11.0818433,0.258245789 14.2035184,3.37992088 14.2035184,7.23088208 Z' id='Stroke-1' stroke='%2353a8ff'%3E%3C/path%3E %3Cpath d='M5.74188852,4.43253072 C5.74188852,5.03062796 5.25690292,5.51561356 4.65880568,5.51561356 C4.06070843,5.51561356 3.57623933,5.03062796 3.57623933,4.43253072 C3.57623933,3.83443347 4.06070843,3.34944788 4.65880568,3.34944788 C5.25690292,3.34944788 5.74188852,3.83443347 5.74188852,4.43253072' id='Fill-3' fill='%2353a8ff'%3E%3C/path%3E %3Cpath d='M10.8856281,4.43253072 C10.8856281,5.03062796 10.4006425,5.51561356 9.8025453,5.51561356 C9.20444805,5.51561356 8.71997895,5.03062796 8.71997895,4.43253072 C8.71997895,3.83443347 9.20444805,3.34944788 9.8025453,3.34944788 C10.4006425,3.34944788 10.8856281,3.83443347 10.8856281,4.43253072' id='Fill-5' fill='%2353a8ff'%3E%3C/path%3E %3Cpath d='M10.5407667,10.238929 C9.66066506,9.70797569 8.50320744,9.38310249 7.23108868,9.38310249 C5.95845343,9.38310249 4.80099581,9.70797569 3.92089416,10.238929' id='Stroke-7' stroke='%2353a8ff'%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
}
.ranking-status-tab__z4SBT {
  display: inline-block;
  border: solid 1px #a9b5c9;
  cursor: pointer;
  padding: 10px 0;
  text-align: center;
  color: #52a7fd;
  margin-right: -1px;
}
.ranking-status-tab__z4SBT:first-child {
  border-radius: 3px 0 0 3px;
}
.ranking-status-tab__z4SBT:last-child {
  border-radius: 0 3px 3px 0;
}
.ranking-status-tab_active__pIPAX {
  background-color: #53a8ff;
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
  color: #fff;
}
.ranking-status-tab_active__pIPAX .ranking-status-quality-block__tab_positive__ViR52 {
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='17px' height='17px' viewBox='0 0 17 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3E42BE2D41-76A1-4E7D-91FB-E3B637E9FC8F%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='QA-dashboard_bad-mark' transform='translate(-1138.000000, -233.000000)'%3E %3Cg id='smile' transform='translate(1139.000000, 234.000000)'%3E %3Cpath d='M14.7321429,7.5 C14.7321429,11.4942857 11.4942857,14.7321429 7.5,14.7321429 C3.50571429,14.7321429 0.267857143,11.4942857 0.267857143,7.5 C0.267857143,3.50571429 3.50571429,0.267857143 7.5,0.267857143 C11.4942857,0.267857143 14.7321429,3.50571429 14.7321429,7.5 Z' id='Stroke-1' stroke='%23FFFFFF'%3E%3C/path%3E %3Cpath d='M5.95558929,4.5975 C5.95558929,5.21785714 5.45255357,5.72089286 4.83219643,5.72089286 C4.21183929,5.72089286 3.70933929,5.21785714 3.70933929,4.5975 C3.70933929,3.97714286 4.21183929,3.47410714 4.83219643,3.47410714 C5.45255357,3.47410714 5.95558929,3.97714286 5.95558929,4.5975' id='Fill-3' fill='%23FFFFFF'%3E%3C/path%3E %3Cpath d='M11.2907679,4.5975 C11.2907679,5.21785714 10.7877321,5.72089286 10.167375,5.72089286 C9.54701786,5.72089286 9.04451786,5.21785714 9.04451786,4.5975 C9.04451786,3.97714286 9.54701786,3.47410714 10.167375,3.47410714 C10.7877321,3.47410714 11.2907679,3.97714286 11.2907679,4.5975' id='Fill-5' fill='%23FFFFFF'%3E%3C/path%3E %3Cpath d='M4.06692857,9.73205357 C4.97978571,10.2827679 6.18032143,10.6197321 7.49978571,10.6197321 C8.81978571,10.6197321 10.0203214,10.2827679 10.9331786,9.73205357' id='Stroke-7' stroke='%23FFFFFF'%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
}
.ranking-status-tab_active__pIPAX .ranking-status-quality-block__tab_negative__rFDv- {
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3E9F564A45-533B-4FD6-9838-D38840D241C9%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='QA-dashboard_bad-mark' transform='translate(-1258.000000, -233.000000)'%3E %3Cg id='Page-1' transform='translate(1259.000000, 234.000000)'%3E %3Cpath d='M14.2035184,7.23088208 C14.2035184,11.0818433 11.0818433,14.2035184 7.23088208,14.2035184 C3.37992088,14.2035184 0.258245789,11.0818433 0.258245789,7.23088208 C0.258245789,3.37992088 3.37992088,0.258245789 7.23088208,0.258245789 C11.0818433,0.258245789 14.2035184,3.37992088 14.2035184,7.23088208 Z' id='Stroke-1' stroke='%23FFFFFF'%3E%3C/path%3E %3Cpath d='M5.74188852,4.43253072 C5.74188852,5.03062796 5.25690292,5.51561356 4.65880568,5.51561356 C4.06070843,5.51561356 3.57623933,5.03062796 3.57623933,4.43253072 C3.57623933,3.83443347 4.06070843,3.34944788 4.65880568,3.34944788 C5.25690292,3.34944788 5.74188852,3.83443347 5.74188852,4.43253072' id='Fill-3' fill='%23FFFFFF'%3E%3C/path%3E %3Cpath d='M10.8856281,4.43253072 C10.8856281,5.03062796 10.4006425,5.51561356 9.8025453,5.51561356 C9.20444805,5.51561356 8.71997895,5.03062796 8.71997895,4.43253072 C8.71997895,3.83443347 9.20444805,3.34944788 9.8025453,3.34944788 C10.4006425,3.34944788 10.8856281,3.83443347 10.8856281,4.43253072' id='Fill-5' fill='%23FFFFFF'%3E%3C/path%3E %3Cpath d='M10.5407667,10.238929 C9.66066506,9.70797569 8.50320744,9.38310249 7.23108868,9.38310249 C5.95845343,9.38310249 4.80099581,9.70797569 3.92089416,10.238929' id='Stroke-7' stroke='%23FFFFFF'%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
}
.ranking-status-block__2rB8R {
  margin-bottom: 20px;
}
.ranking-status-block__title__JH2Wb {
  margin: 0 0 10px;
  font-weight: bold;
  font-family: Hind;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  color: #47475b;
}
.ranking-status-block__title_thin__2tbET {
  font-weight: normal;
}
.ranking-status-status__tab__16eBj {
  width: 33%;
}
.ranking-status-comment__textarea__3rF-Q {
  width: 100%;
  border-radius: 3px 3px 0 0;
  padding: 8px 10px;
  resize: none;
  font-size: 14px;
  color: #47475b;
  font-family: Hind;
}
.ranking-status-comment__textarea__3rF-Q:focus {
  outline: none;
}
.ranking-status-comment_resize_border__15Mld {
  height: 4px;
  width: 100%;
  background: #47475b;
  cursor: row-resize;
  border-radius: 0 0 3px 3px;
}
.ranking-status-buttons-block__3v_n8 {
  text-align: right;
}
.ranking-status-buttons-block__submit__26oWg {
  width: 90px;
  height: 30px;
  border-radius: 3px;
  background-color: #53a8ff;
  border: 1px solid #53a8ff;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: #fff;
}
.ranking-status-buttons-block__submit_disabled__3JshE {
  color: #d4d4d4;
  border-color: #d4d4d4;
  background-color: #fff;
}
.ranking-status-buttons-block__cancel__3cGlc {
  width: 90px;
  height: 30px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #53a8ff;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: #53a8ff;
  margin-right: 7px;
}
.ranking-status-buttons-block__submit__26oWg:focus,
.ranking-status-buttons-block__cancel__3cGlc:focus {
  outline: none;
}
.ranking-status-buttons-block__submit__26oWg:active,
.ranking-status-buttons-block__cancel__3cGlc:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
.ranking-status-bad-tag__1sdZU {
  display: block;
  width: 100%;
  padding: 9px 10px 9px 11px;
  border: 1px solid #a9b5c9;
  color: #47475b;
  border-radius: 3px;
  margin-bottom: 8px;
  cursor: pointer;
}
.ranking-status-bad-tag__check__3C6O9 {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 50px;
  border: 1px solid #c6cacf;
  margin-right: 10px;
  margin-bottom: -3px;
}
.ranking-status-bad-tag_active__3H8e3 {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
  background-color: #53a8ff;
  color: #fff;
}
.ranking-status-bad-tag_active__3H8e3 .ranking-status-bad-tag__check__3C6O9 {
  border: 5px solid #fff;
}
.ranking-status-note__1jl5m {
  position: absolute;
  border: 1px solid #53a8ff;
  padding: 5px;
  color: #52a7fd;
  background-color: #fff;
  width: 200px;
  top: 474px;
  right: 17px;
  line-height: 20px;
  display: none;
}
.ranking-status-note_visible__3rTjE {
  display: block;
}
.ranking-status-error__3Rwu3,
.ranking-status-success__2X9rH {
  display: block;
  width: 100%;
  text-align: center;
  line-height: 20px;
}
.ranking-status-error__3Rwu3 {
  color: #f00;
}
.ranking-status-success__2X9rH {
  color: #008000;
}
